import 'firebase/auth';
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';

export const enum LoginProvider {
	GOOGLE = 'google',
	FACEBOOK = 'facebook',
	APPLE = 'apple',
	JUSTWATCH = 'justwatch',
}

export const LoginProviderByFirebaseProviderId: Record<string, string> = {
	[GoogleAuthProvider.PROVIDER_ID]: LoginProvider.GOOGLE,
	[EmailAuthProvider.PROVIDER_ID]: LoginProvider.JUSTWATCH,
	[FacebookAuthProvider.PROVIDER_ID]: LoginProvider.FACEBOOK,
	['apple.com']: LoginProvider.APPLE,
	[LoginProvider.GOOGLE]: GoogleAuthProvider.PROVIDER_ID,
	[LoginProvider.JUSTWATCH]: EmailAuthProvider.PROVIDER_ID,
	[LoginProvider.FACEBOOK]: FacebookAuthProvider.PROVIDER_ID,
	[LoginProvider.APPLE]: 'apple.com',
};

export interface LoginProviderAccount {
	email?: string;
	login_provider: LoginProvider;
	providerId: string;
}

export enum AccountModes {
	MAIN = 'MAIN',
	MOBILE_MAIN = 'MOBILE_MAIN',
	SIGN_IN = 'SIGN_IN',
	SIGN_UP = 'SIGN_UP',
	COUNTRY = 'COUNTRY',
	LANGUAGE = 'LANGUAGE',
	PROVIDERS = 'PROVIDERS',
	FORGOT_PASSWORD = 'FORGOT_PASSWORD',
	LOGGING_IN = 'LOGGING_IN',
	SIGNING_UP = 'SIGNING_UP',
	LOGIN_MANAGER = 'LOGIN_MANAGER',
	REGISTRATION_OPTIONS = 'REGISTRATION_OPTIONS',
}
