export enum TabType {
	MY_LISTS = 'custom_lists',
	WATCHLIST = 'watchlist',
	SEENLIST = 'seenlist',
	LIKELIST = 'likelist',
	FOLLOWING = 'following',
	BROWSE = 'browse',
	CONTINUE_WATCHING = 'continue_watching',
	HAVENT_STARTED = 'havent_started',
	CAUGHT_UP = 'caught_up',
}

export const innerTabMigrationMap = {
	watchlist: TabType.WATCHLIST,
	seenlist: TabType.SEENLIST,
	likelist: TabType.LIKELIST,
	dislikelist: TabType.WATCHLIST,
	'my-lists': TabType.MY_LISTS,
	followed: TabType.FOLLOWING,
};
