import { MonetizationType } from '@/@types/graphql-types';

export type ProviderType = 'all' | 'my_services' | 'free' | 'buy_rent' | 'subscription';
export type ProviderTypeWithMonetizationTypes = Exclude<ProviderType, 'all' | 'my_services'>;

export const ProviderTypeToMonetizationTypes: Record<ProviderTypeWithMonetizationTypes, MonetizationType[]> = {
	buy_rent: [MonetizationType.Buy, MonetizationType.Rent],
	free: [MonetizationType.Free, MonetizationType.Ads],
	subscription: [MonetizationType.Flatrate, MonetizationType.FlatrateAndBuy],
};
