let _vm: any = null;

// setting the vm of the vue instance
export function setVm(vm: any) {
	_vm = vm;
}

// getting the vm of the vue instance
export function getVm() {
	return _vm;
}
