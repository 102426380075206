import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';
import { ClickoutUrl } from '@/helpers/clickout-helper';
import { useExperiment } from '@/helpers/composables/useExperiment';
import { useUserAgent } from '@/helpers/composables/useUserAgent';
import { getVm } from '@/helpers/vm-helper';

// ?preferred_variant=AMAZON_OS_FREE_TRIAL_EXP_2:control
// ?preferred_variant=AMAZON_OS_FREE_TRIAL_EXP_2:variant_1
// ?preferred_variant=AMAZON_OS_FREE_TRIAL_EXP_2:variant_2

// ?remove_preferred_experiment=AMAZON_OS_FREE_TRIAL_EXP_2

export const AmazonOSFreeTrialExp2Name = 'AMAZON_OS_FREE_TRIAL_EXP_2' as const;

export type AmazonOSFreeTrialExp2Variants = ToVariants<typeof AmazonOSFreeTrialExp2Variants>;

export const AmazonOSFreeTrialExp2Variants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
} as const;

const { deviceType, deviceOs } = useUserAgent();

function trackingTag(activeVariant: AmazonOSFreeTrialExp2Variants) {
	if (activeVariant == null) return '';

	const route = getVm().$route;

	const device = deviceType.value === 'desktop' ? 'des' : 'mob';

	const appleDeviceOs = deviceOs.value === 'ios' ? 'ios' : deviceOs.value === 'macos' ? 'mac' : null;

	// prettier-ignore
	const page =
			route.name == null ? '' :
            route.name === 'app.detail.movies' ? 'movie' :
            route.name === 'app.detail.show' ? 'show' :
            route.name === 'app.detail.show.season' ? 'season' :
            '';

	// prettier-ignore
	const variant =
			activeVariant === 'control'
				? 'c'
				: activeVariant === 'variant_1'
				? 'v1'
				: '';

	if (page === '' || variant === '') return '';

	return `just${appleDeviceOs ?? device}${page}us${variant}-20`;
}

function addTrackingTag(offerUrl: string, providerId: number, activeVariant: AmazonOSFreeTrialExp2Variants) {
	const tagName = providerId === 9 ? 'tag' : providerId === 350 ? 'ct' : '';

	return addStringUrlTag(offerUrl, tagName, trackingTag(activeVariant));
}

function addStringUrlTag(url: string, tag: string, value: string) {
	const u = new URL(url);
	u.searchParams.set(tag, value);

	return u.toString();
}

export function tagProviderFreeTrialUrl(url: ClickoutUrl, providerId: number) {
	const { activeVariant } = useExperiment({
		name: AmazonOSFreeTrialExp2Name,
		variants: AmazonOSFreeTrialExp2Variants,
	});

	if (
		activeVariant.value === null ||
		(url.providerId !== 9 && url.providerId !== 350) ||
		trackingTag(activeVariant.value) === ''
	)
		return url;

	let offerUrl = url.get('r');
	if (offerUrl === null) return url;

	offerUrl = addTrackingTag(offerUrl, providerId, activeVariant.value);

	return url.set('r', offerUrl);
}

export const amazonPlanOffer = {
	title: 'prime video monthly trial',
	retailPrice: '$8.99',
	isTrial: true,
	durationDays: 30,
	retailPriceValue: 8.99,
	children: [],
	__typename: 'PackagePlanOffer',
};

export const applePlanOffer = {
	title: 'monthly trial',
	retailPrice: '$9.99',
	isTrial: true,
	durationDays: 7,
	retailPriceValue: 9.99,
	children: [],
	__typename: 'PackagePlanOffer',
};

export default generate(AmazonOSFreeTrialExp2Name, AmazonOSFreeTrialExp2Variants);
