import { ActionTree, GetterTree, MutationTree, Module as VuexModule } from 'vuex';
import { DiscoveryModuleTemplate } from '@/@types/graphql-types';

// STATE
const state = () => ({
	discoverySessionId: '' as string,
	clientsideTemplates: [] as { template: DiscoveryModuleTemplate; index: number }[],
	activeModuleIndex: null as number | null,
	interactedModuleIndex: null as number | null, // this is mainly used for more-like-this
});

type State = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<State, any> = {
	clientsideTemplates(state) {
		return state.clientsideTemplates;
	},

	discoverySessionId(state) {
		return state.discoverySessionId;
	},
	activeModuleIndex(state): number | null {
		return state.activeModuleIndex;
	},
};

// ACTIONS
const actions: ActionTree<State, any> = {
	setActiveModuleIndex({ commit }, activeModuleIndex: number) {
		commit('SET_ACTIVE_MODULE_INDEX', activeModuleIndex);
	},

	setDiscoverySessionId({ commit }, discoverySessionId: string) {
		commit('SET_DISCOVERY_SESSION_ID', discoverySessionId);
	},

	setInteractedModuleIndex({ commit }, interactedModuleIndex: number) {
		commit('SET_INTERACTED_MODULE_INDEX', interactedModuleIndex);
	},

	async addMoreLikeThis(
		{ commit, state },
		{
			id,
		}: {
			id: string;
		}
	) {
		const isTemplateAlreadyAdded = state.clientsideTemplates.find(
			clientsideTemplate => clientsideTemplate.template.anchor === id
		);

		if (isTemplateAlreadyAdded) {
			return;
		}

		commit('ADD_CLIENTSIDE_TEMPLATE', {
			template: {
				technicalName: 'TITLES_BECAUSE_YOU_LOVED_TITLE',
				anchor: id,
			},
			index: state.interactedModuleIndex! + 1,
		});
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {
	SET_DISCOVERY_SESSION_ID(state, discoverySessionId: string) {
		state.discoverySessionId = discoverySessionId;
	},
	SET_ACTIVE_MODULE_INDEX(state, activeModuleIndex: number | null) {
		state.activeModuleIndex = activeModuleIndex;
	},
	SET_INTERACTED_MODULE_INDEX(state, moduleIndex: number | null) {
		state.interactedModuleIndex = moduleIndex;
	},
	ADD_CLIENTSIDE_TEMPLATE(state, { template: DiscoveryModuleTemplate, index: number }) {
		state.clientsideTemplates = state.clientsideTemplates.concat({
			template: DiscoveryModuleTemplate,
			index: number,
		});
	},
	RESET_CLIENTSIDE_TEMPLATES(state) {
		state.clientsideTemplates = [];
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as VuexModule<State, any>;
