import { DataProcessingService } from '@/helpers/usercentrics-helper';
import { SnowplowContext } from './snowplow-contexts';

export abstract class TrackingProvider {
	abstract initialize(): Promise<void>;
	abstract trackEvent(
		category: string,
		properties: TrackingProviderPropertiesInterface,
		contexts: SnowplowContext[]
	): Promise<void>;
	abstract trackPage({ path, title }: { path?: string; title?: string }): Promise<void>;
	abstract dataProcessingService: DataProcessingService | null;
	abstract refreshPermanentAudiences(permanent_audiences: string[]): void;
	abstract refreshPermanentAudiencesSubgenres(permanent_audiences: string[]): void;
}

export interface TrackingProviderPropertiesInterface {
	category?: string;
	action?: string;
	label?: string;
	property?: string;
	value?: number;
	path?: string;
	nonInteraction?: boolean;
	useLastRoute?: boolean;
}
