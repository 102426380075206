const DEBUG_STORAGE_KEY = 'jw-debug';

// This storage is meant to be used for sentry initialization.
// We can't use local forage because it's async and also initialization happens after sentry.
// So relying on good old local storage directly.
class DebugStorage {
	getState(): boolean {
		return localStorage.getItem(DEBUG_STORAGE_KEY) === 'true';
	}
	setState(debugEnabled: boolean): void {
		if (!debugEnabled) {
			localStorage.removeItem(DEBUG_STORAGE_KEY);
			return;
		}
		localStorage.setItem(DEBUG_STORAGE_KEY, 'true');
	}
}

export const debugStorage = new DebugStorage();
