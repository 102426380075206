import type VueI18n from 'vue-i18n';
import type { DateTimeFormats } from 'vue-i18n';

import { getAxiosCached } from '@/helpers/axios-helper';
import { getLanguageFromBrowser } from '@/helpers/locale-helper';
import { DEFAULT_LANGUAGE_FALLBACK } from '@/i18n';

/**
 * set translatables from local json as fallback when translatables cannot be downloaded
 *
 * @param {string} lang
 *
 * @returns {boolean}
 */
const verifyTranslations = async (i18n: VueI18n): Promise<boolean> => {
	if (i18n && Object.keys(i18n.messages).length === 0) {
		const lang = getLanguageFromBrowser() || i18n.locale || DEFAULT_LANGUAGE_FALLBACK;
		try {
			return await setTranslatables(i18n, lang);
		} catch (error) {
			return setTranslatables(i18n, DEFAULT_LANGUAGE_FALLBACK);
		}
	}
	return false;
};

/**
 * get local json for `lang` translatable
 * tranform json and set it to i18n
 *
 * @param lang
 *
 * @returns {Promise<boolean>}
 */
const setTranslatables = async (i18n: VueI18n, lang: string): Promise<boolean> => {
	const jsonUrl = `/${global.ASSETS_DIR}/translatables/${lang}.json`;
	try {
		const json = await (await getAxiosCached()).get<TranslationResponse>(jsonUrl);
		const translatables = json.data;

		if (!translatables) {
			throw new Error(`Broken local translatable for ${lang}`);
		}
		const transformed = transformTranslations(translatables);
		i18n.locale = lang;
		await i18n.setLocaleMessage(lang, transformed);
		verifyTranslations(i18n);
	} catch (error) {
		throw new Error(`${lang} ${error}`);
	}
	return true;
};

// @todo replace with a more dynamic approach
const dateTimeFormats: DateTimeFormats = {
	'en-US': {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric',
		},
	},
	'de-DE': {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric',
		},
	},
} as DateTimeFormats;

// transforms the format of our API into the format expected by vue-i18n
const transformTranslations = (response: TranslationResponse): Record<string, string> => {
	// additional translations not in the api
	return response.items.reduce((previousValue, item) => {
		let trans = item.translation;
		// @TODO: We better fix these translations on the server side as this hack may have bad side effects.
		trans = trans.replace(/{{([a-zA-Z-_]+)}}/g, '{$1}');
		// @TODO: find a better way to remove the inline styles
		if (['WEBAPP_SPECIALS'].indexOf(item.technical_name) === -1) {
			// only remove style tags if they're not specials
			trans = trans.replace(/<style>.+<\/style>/g, '');
		}
		// @todo double check make doc where <style> is userd in cms translatable
		// @TODO: some translations also contain html icons
		trans = trans.replace(/\s?<span.+<\/span>/g, '');
		previousValue[item.technical_name] = trans;
		return previousValue;
	}, {} as Record<string, string>);
};

export { dateTimeFormats, transformTranslations };
