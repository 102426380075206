import { DataProcessingService } from '@/helpers/usercentrics-helper';
import { SnowplowContext } from './snowplow-contexts';
import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';

declare global {
	interface Window {
		twq: Function;
	}
}

export class TrackingProviderTwitterPixel extends TrackingProvider {
	dataProcessingService = DataProcessingService.TWITTER_ANALYTICS;

	constructor(private config: { pixelId: string; doNotTrack: boolean }) {
		super();
	}

	initialize() {
		return new Promise<void>((resolve, reject) => {
			if (!this.config.doNotTrack) {
				let s: any, u: any, a: any;
				window.twq ||
					((s = window.twq =
						function () {
							s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
						}),
					(s.version = '1.1'),
					(s.queue = []),
					(u = document.createElement('script')),
					(u.async = !0),
					(u.src = 'https://static.ads-twitter.com/uwt.js'),
					(a = document.getElementsByTagName('script')[0]),
					a.parentNode.insertBefore(u, a));
				// Insert Twitter Pixel ID and Standard Event data below
				window.twq('init', this.config.pixelId);
			}
			resolve();
		});
	}

	async trackPage({ path, title }: { path?: string; title?: string }) {
		if (!this.config.doNotTrack) {
			window.twq('track', 'PageView');
		}
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {}
	refreshPermanentAudiencesSubgenres(permanent_audiences: string[] = []) {}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {}
}
