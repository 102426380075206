import { RouteConfig } from 'vue-router/types/router';
import { WebLocale } from '@/enums/web-locale';
import { innerTabMigrationMap, TabType } from '@/interfaces/inner-tabs';

const watchlistLocaleToSlugMap: { [key in WebLocale]: string } = {
	ad: 'llista',
	ae: 'watchlist',
	ag: 'watchlist',
	al: 'lista-e-shikimit',
	az: 'baxış-siyahısı',
	ao: 'watchlist',
	ar: 'watchlist',
	at: 'WatchList',
	au: 'watchlist',
	ba: 'lista-za-gledanje',
	bb: 'watchlist',
	be: 'watchlist',
	bf: 'watchlist',
	bg: 'watchlist',
	bh: 'watchlist',
	bm: 'watchlist',
	bo: 'watchlist',
	br: 'watchlist',
	bs: 'watchlist',
	by: 'спіс-для-прагляду',
	bz: 'watchlist',
	ca: 'watchlist',
	cd: 'watchlist',
	ch: 'WatchList',
	ci: 'watchlist',
	cl: 'watchlist',
	cm: 'watchlist',
	co: 'watchlist',
	cr: 'watchlist',
	cu: 'watchlist',
	cv: 'watchlist',
	cy: 'watchlist',
	cz: 'watchlist',
	de: 'WatchList',
	dk: 'watchlist',
	do: 'watchlist',
	dz: 'watchlist',
	ec: 'watchlist',
	ee: 'watchlist',
	eg: 'watchlist',
	es: 'watchlist',
	fi: 'omalista',
	fj: 'watchlist',
	fr: 'watchlist',
	gf: 'watchlist',
	gg: 'watchlist',
	gh: 'watchlist',
	gi: 'watchlist',
	gq: 'watchlist',
	gr: 'watchlist',
	gt: 'watchlist',
	gy: 'watchlist',
	hk: '觀看片單',
	hn: 'watchlist',
	hr: 'watchlist',
	hu: 'watchlist',
	id: 'watchlist',
	ie: 'watchlist',
	il: 'watchlist',
	in: 'watchlist',
	iq: 'watchlist',
	is: 'watchlist',
	it: 'watchlist',
	jm: 'watchlist',
	jo: 'watchlist',
	jp: '視聴リスト',
	ke: 'watchlist',
	kr: '시청목록',
	kw: 'watchlist',
	lb: 'watchlist',
	lc: 'watchlist',
	li: 'WatchList',
	lt: 'watchlist',
	lu: 'watchlist',
	lv: 'watchlist',
	ly: 'watchlist',
	ma: 'watchlist',
	mc: 'watchlist',
	md: 'watchlist',
	me: 'spisak-za-gledanje',
	mg: 'watchlist',
	mk: 'листа-на-гледање',
	ml: 'watchlist',
	mt: 'lista-ta-viżjoni',
	mu: 'watchlist',
	mw: 'watchlist',
	mx: 'watchlist',
	my: 'watchlist',
	mz: 'watchlist',
	ne: 'watchlist',
	ng: 'watchlist',
	ni: 'watchlist',
	nl: 'watchlist',
	no: 'watchlist',
	nz: 'watchlist',
	om: 'watchlist',
	pa: 'watchlist',
	pe: 'watchlist',
	pf: 'watchlist',
	pg: 'watchlist',
	ph: 'watchlist',
	pk: 'watchlist',
	pl: 'watchlist',
	ps: 'watchlist',
	pt: 'watchlist',
	py: 'watchlist',
	qa: 'watchlist',
	ro: 'watchlist',
	rs: 'spisak-za-gledanje',
	ru: 'Избранное',
	sa: 'watchlist',
	sc: 'watchlist',
	se: 'watchlist',
	sg: 'watchlist',
	sk: 'watchlist',
	si: 'seznam',
	sm: 'watchlist',
	sn: 'watchlist',
	sv: 'watchlist',
	tc: 'watchlist',
	td: 'watchlist',
	th: 'watchlist',
	tn: 'watchlist',
	tr: 'watchlist',
	tt: 'watchlist',
	tw: '觀看清單',
	tz: 'orodha-ya-kutazama',
	ua: 'список-перегляду',
	ug: 'watchlist',
	uk: 'watchlist',
	us: 'watchlist',
	uy: 'watchlist',
	va: 'watchlist',
	ve: 'watchlist',
	xk: 'lista-e-shikimit',
	ye: 'watchlist',
	za: 'watchlist',
	zm: 'watchlist',
	zw: 'watchlist',
};

const getNewInnerTab = (innerTab: string) => {
	const isExpectedInnerTab = Object.keys(innerTabMigrationMap).includes(innerTab);
	return isExpectedInnerTab ? innerTabMigrationMap[innerTab as keyof typeof innerTabMigrationMap] : null;
};

export function makeRedirectRoutes(webLocale: WebLocale): RouteConfig[] {
	return [
		/* Old shared list route:
           /shared?id={listId}
        */
		{
			name: 'app.old.shared',
			path: '/shared',
			redirect: to => {
				return {
					path: `${webLocale}/lists/public-lists`,
					query: { list_id: to.query.id, inner_tab: 'browse' },
				};
			},
		},
		/* Old watchlist routes:
           {webLocale}/{watchlistSlug}?inner_tab=watchlist
           {webLocale}/{watchlistSlug}?inner_tab=seenlist
           {webLocale}/{watchlistSlug}?inner_tab=likelist
           {webLocale}/{watchlistSlug}?inner_tab=dislikelist
        */
		{
			name: 'app.old.watchlist',
			path: `/${webLocale}/${watchlistLocaleToSlugMap[webLocale]}`,
			redirect: to => {
				const innerTab = (to.query.inner_tab || '') as string;
				const newInnerTab = getNewInnerTab(innerTab);

				return {
					path: `${webLocale}/lists/my-lists`,
					query: { ...to.query, inner_tab: newInnerTab || undefined },
				};
			},
		},
		/* Old lists routes:
		   {webLocale}/lists?tab=my-lists
		   {webLocale}/lists?tab=followed
		*/
		{
			name: 'app.old.lists',
			path: `/${webLocale}/lists`,
			redirect: to => {
				const tab = (to.query.tab || '') as string;
				const newInnerTab = getNewInnerTab(tab);
				const listsTab = newInnerTab === TabType.MY_LISTS ? 'my-lists' : 'public-lists';

				return {
					path: `${webLocale}/lists/${listsTab}`,
					query: { ...to.query, ...(newInnerTab ? { inner_tab: newInnerTab } : {}), tab: undefined },
				};
			},
		},
	];
}
