type ValuesOf<T> = T[keyof T];

export enum UserAccountEvents {
	LOGIN = 'account_login',
	SIGNUP = 'account_signup',
	DELETE_CLICKED = 'delete_clicked',
	DELETE_CONFIRMED = 'delete_confirmed',
	DELETE_CANCELLED = 'delete_cancelled',
	LOGOUT_CLICKED = 'logout_clicked',
	LOGOUT_CONFIRMED = 'logout_confirmed',
	LOGOUT_CANCELLED = 'logout_cancelled',
	LOGIN_ERROR = 'account_login_error',
	SIGNUP_ERROR = 'account_signup_error',
	RESET_PASSWORD = 'account_reset_password',
	PASSWORD_CHANGED = 'account_password_changed',
	COUNTRY_SELECTED = 'country_selected',
	LANGUAGE_SELECTED = 'language_selected',
	PROVIDER_TOGGLED = 'provider_toggled',
	LOGIN_CLICKED = 'account_sign_in_clicked',
	SIGNUP_CLICKED = 'account_create_clicked',
	CHANGE_EMAIL_CLICKED = 'account_change_email_clicked',
	CHANGE_PASSWORD_CLICKED = 'account_change_password_clicked',
	CHANGE_EMAIL_CONFIRMED = 'account_change_email_confirmed',
	CHANGE_PASSWORD_CONFIRMED = 'account_change_password_confirmed',
	RESEND_VERIFICATION_EMAIL_CLICKED = 'account_resend_verification_email_clicked',
	RESEND_VERIFICATION_EMAIL_CONFIRMED = 'account_resend_verification_email_confirmed',
	RESEND_VERIFICATION_EMAIL_ERROR = 'account_resend_verification_email_error',
	FORGOT_PASSWORD_CLICKED = 'account_forgot_password_clicked',
	COUNTRY_CLICKED = 'country_clicked',
	LANGUAGE_CLICKED = 'language_clicked',
	PROVIDER_CLICKED = 'provider_clicked',
	GO_PRO_CLICKED = 'go_pro_clicked',
}

export enum FilterHintEvents {
	SHOWN = 'shown',
	CLICKED = 'clicked',
	LOADED = 'loaded',
	NO_MORE_TITLES = 'no_more_titles',
	NO_TITLES = 'no_titles',
}

export enum SeenListEvents {
	ADD = 'seenlist_add',
	REMOVE = 'seenlist_remove',
}

export type ImpressionTrackingEvents = ValuesOf<typeof ImpressionTrackingEvents>;
export const ImpressionTrackingEvents = {
	SIMILAR_TITLES: 'similartitles',
	MODULE: 'module',
	AD: 'ad',
	OFFER: 'offer',
	SPONSORED_RECOMMENDATIONS: 'sponsored_recommendations',
	TITLE: 'title',
} as const;

export enum ShareEvents {
	CATEGORY = 'share_content',
	CLICKED = 'click',
	SHARED = 'share',
}

export enum FilterMenuEvents {
	PROVIDERS_TOGGLE = 'filter_toggled',
}

export enum ActionSheetEvents {
	OPENED = 'opened',
	CLOSED = 'closed',
}
