import LocalForage from 'localforage';
import Vue from 'vue';
import Vuex, { ModuleTree } from 'vuex';

import { Context } from '@/@types/ssr';

import { CONTEXT_CLASSES } from '@/helpers/tracking/providers';
import { PersistStateCookiesPlugin, PersistStateLocalStoragePlugin } from '@/persist-state.plugin';

import { CONSTANT_INIT_FREEZE_ITEMS } from '@/stores/constant.store';
import { LANGUAGE_INIT_FREEZE_ITEMS } from '@/stores/language.store';

Vue.use(Vuex);

/**
 * Require All Store modules
 */

function camelCase(str: string): string {
	return str.replace(/-([a-z])/g, function (g) {
		return g[1].toUpperCase();
	});
}

export type FreezingItems<T> = Record<string, (state: T) => void>;

// when objects are frozen before they are put into vuex or vue component data,
// it will skip the defineReactive step that will create dependencies and subscribers that keep track of dependencies and notifies them upon change.
// we don't need this, if we don't expect properties to change.
// e.g. properties like full_path, name, offers of a single title will not change. we only need to keep track of the different items.
// so we keep the items reactive, but the single item can be frozen.
//
// defineReactive: (https://github.com/vuejs/vue/blob/0603ff695d2f41286239298210113cbe2b209e28/src/core/observer/index.js#L135)
const freezeItemsByStore: Record<string, FreezingItems<any>> = {
	constant: CONSTANT_INIT_FREEZE_ITEMS,
	language: LANGUAGE_INIT_FREEZE_ITEMS,
};

// used for LocalForage's config, so stores are contained in a namespace à la jw/user.
export const STORE_NAMESPACE = 'jw';

export default async function (context: Partial<Context>): Promise<any> {
	const { ssrData, req } = context;
	const isSeoUser = ssrData?.isSeoUser ?? true;
	const requestCookies = req?.cookies;

	const requireStore = require.context('./stores', false, /\.store\.ts$/);
	const stores: ModuleTree<any> = {};
	let savedState: any = {};
	let baseState: any = {}; // either taken from server (__DATA__.state) or initial state from the stores

	requireStore.keys().forEach(fileName => {
		const moduleName = camelCase(fileName.replace(/(\.\/|\.store\.ts)/g, ''));
		stores[moduleName] = requireStore(fileName).default;
	});

	if (FEATURES.Braze) {
		stores['braze'] = (await import('@/features/braze/braze.store')).default;
	}

	// Storage migration
	// check if anything needs to be migrated from the old localStorage
	if (process.client) {
		const { needsLocalStorageMigration } = await import('@/migrate-local-storage');
		// from old structure (localStorage) to new structure (localStorage)
		if (await needsLocalStorageMigration()) {
			const { migrateLocalStorage } = await import('@/migrate-local-storage');
			await migrateLocalStorage();
		}

		// cookie migration, just delete the old ones, persist-cookie plugin will save the new ones correctly
		// don't do it yet
		// if (Cookies.get('jw/user')) {
		// 	Cookies.remove('jw/user');
		// }

		// if (Cookies.get('jw/filter')) {
		// 	Cookies.remove('jw/filter');
		// }

		// client: set savedState from localStorage
		savedState = await LocalForage.getItems();
		baseState = (window as any).__DATA__ ? (window as any).__DATA__.state : {};
	} else if (!isSeoUser && requestCookies) {
		// server: set savedState from cookies
		const _userCookie = requestCookies[`${STORE_NAMESPACE}_user`] || requestCookies[`${STORE_NAMESPACE}/user`];

		if (_userCookie) {
			try {
				savedState.user = JSON.parse(_userCookie);

				// Force access_token cookie handover
				if (requestCookies['access_token']) {
					savedState.user.accessToken = requestCookies['access_token'];
				}
			} catch (err) {
				console.error(`[Store.ts] createStore error parsing ${STORE_NAMESPACE}_user`, _userCookie);
			}
		}

		const _filterCookie =
			requestCookies[`${STORE_NAMESPACE}_filter`] || requestCookies[`${STORE_NAMESPACE}/filter`];
		if (_filterCookie) {
			try {
				savedState.filter = JSON.parse(_filterCookie);
			} catch (err) {
				console.error(`[Store.ts] createStore error parsing ${STORE_NAMESPACE}_filter`, _filterCookie);
			}
		}
	}

	// merging initialState and saved state
	const modules = Object.keys(stores).reduce((root, storeKey) => {
		// if there is server state, use that as the default/base state, otherwise default store state
		const state = baseState[storeKey] ? baseState[storeKey] : stores[storeKey].state();

		if (process.client) {
			if (storeKey === 'user') {
				// special treatment user: it's possible that localStorage.user.settings is outdated
				// and the server has the latest user settings anyway, so we always take that for now
				// @todo: this will have to go when we have a more solid solution about state juggling between client <-> server
				// the server always fetches the user's latest settings
				savedState.user = {
					...savedState.user,
					settings: baseState.user?.settings,
				};
			} else if (storeKey === 'filter' && baseState.filter) {
				savedState.filter = baseState.filter;
			} else if (
				storeKey === 'tracking' &&
				state.queues &&
				Object.keys(state.queues).some(key => (state.queues[key] || []).length > 0)
			) {
				// special treatment tracking: that might need to instantiate SnowplowContext classes
				// unserialize tracking contexts to SnowplowContext objects
				state.queues = Object.keys(state.queues).reduce((root, key) => {
					root[key] = (state.queues[key] || []).map((item: any) => {
						return {
							...item,
							contexts: item.contexts.map(
								(serializedContext: { __name: keyof typeof CONTEXT_CLASSES }) => {
									// unserialize the stringified context, that comes from the server in __DATA__
									return Object.create(
										CONTEXT_CLASSES[serializedContext.__name].prototype,
										Object.getOwnPropertyDescriptors(serializedContext)
									);
								}
							),
						};
					});
					return root;
				}, state.queues);
			}
		}

		// other stores being merged with savedState
		const stateItem = {
			...state,
			...(savedState[storeKey] || {}),
		};

		if (freezeItemsByStore[storeKey]) {
			Object.keys(freezeItemsByStore[storeKey])
				// we throw the final merged state into each defined case that needs specific items to be frozen
				.forEach(id => freezeItemsByStore[storeKey][id](stateItem));
		}

		root[storeKey] = {
			...stores[storeKey],
			state: (() => stateItem)(),
		};

		// #VUEX-GETTER-DEBUG
		// if (process.server) {
		// 	const { performance } = require('perf_hooks');

		// 	Object.keys(root[storeKey].getters).forEach(getterName => {
		// 		const fn = root[storeKey].getters[getterName];
		// 		root[storeKey].getters[getterName] = function() {
		// 			if (!accesses[`${storeKey}/${getterName}`]) {
		// 				accesses[`${storeKey}/${getterName}`] = {
		// 					calls: 0,
		// 					totalTime: 0,
		// 				};
		// 			}
		// 			const startTime = performance.now();
		// 			const ret = fn.apply(this, arguments);
		// 			const endTime = performance.now();
		// 			accesses[`${storeKey}/${getterName}`].totalTime += endTime - startTime;
		// 			accesses[`${storeKey}/${getterName}`].calls++;
		// 			return ret;
		// 		};
		// 	});
		// }

		return root;
	}, {} as any);

	let plugins: Array<any> = [];
	if (process.client) {
		const stores = ['user', 'filter'];
		if (FEATURES.Braze) {
			stores.push('braze');
		}
		plugins = [PersistStateLocalStoragePlugin(stores)];

		plugins.push(PersistStateCookiesPlugin(['user']));
	}

	return new Vuex.Store<any>({
		modules,
		strict: process.env.NODE_ENV === 'development',
		plugins,
	});
}
