export enum Region {
	AFRICA = 'Africa',
	ASIA = 'Asia',
	CARIBBEAN = 'Caribbean',
	CENTRAL_AMERICA = 'Central America',
	EUROPE = 'Europe',
	MIDDLE_EAST = 'Middle East',
	NORTH_AMERICA = 'North America',
	PACIFIC = 'Pacific',
	SOUTH_AMERICA = 'South America',
}

export enum Country {
	AD = 'Andorra',
	AE = 'United Arab Emirates',
	AG = 'Antigua and Barbuda',
	AL = 'Albania',
	AO = 'Angola',
	AR = 'Argentina',
	AT = 'Austria',
	AU = 'Australia',
	AZ = 'Azerbaijan',
	BA = 'Bosnia and Herzegovina',
	BB = 'Barbados',
	BE = 'Belgium',
	BF = 'Burkina Faso',
	BG = 'Bulgaria',
	BH = 'Bahrain',
	BM = 'Bermuda',
	BO = 'Bolivia',
	BR = 'Brazil',
	BS = 'Bahamas',
	BY = 'Belarus',
	BZ = 'Belize',
	CA = 'Canada',
	CD = 'DR Congo',
	CH = 'Switzerland',
	CI = 'Ivory Coast',
	CL = 'Chile',
	CM = 'Cameroon',
	CO = 'Colombia',
	CR = 'Costa Rica',
	CU = 'Cuba',
	CV = 'Cape Verde',
	CY = 'Cyprus',
	CZ = 'Czech Republic',
	DE = 'Germany',
	DK = 'Denmark',
	DO = 'Dominican Republic',
	DZ = 'Algeria',
	EC = 'Ecuador',
	EE = 'Estonia',
	EG = 'Egypt',
	ES = 'Spain',
	FI = 'Finland',
	FJ = 'Fiji',
	FR = 'France',
	GF = 'French Guiana',
	GG = 'Guernsey',
	GH = 'Ghana',
	GI = 'Gibraltar',
	GQ = 'Equatorial Guinea',
	GR = 'Greece',
	GT = 'Guatemala',
	GY = 'Guyana',
	HK = 'Hong Kong',
	HN = 'Honduras',
	HR = 'Croatia',
	HU = 'Hungary',
	ID = 'Indonesia',
	IE = 'Ireland',
	IL = 'Israel',
	IN = 'India',
	IQ = 'Iraq',
	IS = 'Iceland',
	IT = 'Italy',
	JM = 'Jamaica',
	JO = 'Jordan',
	JP = 'Japan',
	KR = 'South Korea',
	KE = 'Kenya',
	KW = 'Kuwait',
	LC = 'Saint Lucia',
	LB = 'Lebanon',
	LI = 'Liechtenstein',
	LT = 'Lithuania',
	LU = 'Luxembourg',
	LV = 'Latvia',
	LY = 'Libya',
	MA = 'Morocco',
	MC = 'Monaco',
	MD = 'Moldova',
	ME = 'Montenegro',
	MG = 'Madagascar',
	MK = 'Macedonia',
	ML = 'Mali',
	MT = 'Malta',
	MU = 'Mauritius',
	MW = 'Malawi',
	MX = 'Mexico',
	MY = 'Malaysia',
	MZ = 'Mozambique',
	NE = 'Niger',
	NG = 'Nigeria',
	NI = 'Nicaragua',
	NL = 'Netherlands',
	NO = 'Norway',
	NZ = 'New Zealand',
	OM = 'Oman',
	PA = 'Panama',
	PE = 'Peru',
	PF = 'French Polynesia',
	PG = 'Papua New Guinea',
	PH = 'Philippines',
	PL = 'Poland',
	PS = 'Palestine',
	PT = 'Portugal',
	PY = 'Paraguay',
	QA = 'Qatar',
	RO = 'Romania',
	RS = 'Serbia',
	RU = 'Russia',
	SA = 'Saudi Arabia',
	SE = 'Sweden',
	SC = 'Seychelles',
	SG = 'Singapore',
	SK = 'Slovakia',
	SI = 'Slovenia',
	SM = 'San Marino',
	SN = 'Senegal',
	SV = 'El Salvador',
	TC = 'Turks and Caicos Islands',
	TD = 'Chad',
	TH = 'Thailand',
	TN = 'Tunisia',
	TR = 'Turkey',
	TT = 'Trinidad and Tobago',
	TW = 'Taiwan',
	TZ = 'Tanzania',
	UA = 'Ukraine',
	UG = 'Uganda',
	UK = 'United Kingdom',
	US = 'USA',
	UY = 'Uruguay',
	VA = 'Vatican City',
	VE = 'Venezuela',
	XK = 'Kosovo',
	YE = 'Yemen',
	ZA = 'South Africa',
	PK = 'Pakistan',
	ZM = 'Zambia',
	ZW = 'Zimbabwe',
}

export interface LocaleDetails {
	country: Country;
	region: Region;
	new?: boolean;
}
