import { ExternalIds, Scoring } from '@/@types/graphql-types';

export enum ScoringProvider {
	JUSTWATCH = 'justwatch',
	IMDB = 'imdb',
	IMDB_VOTES = 'imdb:votes',
	TMDB = 'tmdb',
	ROTTEN_TOMATOES = 'rottentomatoes',
}
export type ScoringProviderType = ScoringProvider.IMDB | ScoringProvider.TMDB;

type ScoringKey = 'imdbScore' | 'tmdbScore' | 'tomatoMeter';

export interface FormattedScoring {
	name: string;
	provider: ScoringProvider;
	queryName: ScoringKey; // name of query parameter to be mapped from
	append: string;
	decimals: number;
	image: string;
	step: number;
	min: number;
	max: number;
	value: number;
	score?: string;
	link?: string;
	filterDisplay?: boolean;
}

export const SCORINGS: FormattedScoring[] = [
	{
		name: 'JustWatch Rating',
		provider: ScoringProvider.TMDB,
		queryName: 'tmdbScore' as ScoringKey,
		append: '%',
		decimals: 0,
		step: 1,
		min: 0,
		max: 100,
		value: 0,
		image: `/${ASSETS_DIR}/img/jw-icon.png`,
		filterDisplay: false,
	},
	{
		name: 'IMDB',
		provider: ScoringProvider.IMDB,
		queryName: 'imdbScore' as ScoringKey,
		append: '',
		decimals: 1,
		step: 0.1,
		min: 0,
		max: 10,
		value: 0,
		image: `/${ASSETS_DIR}/img/imdb-logo.png`,
		filterDisplay: true,
	},
	{
		name: 'IMDb Votes',
		provider: ScoringProvider.IMDB_VOTES,
		queryName: 'imdbVotes' as ScoringKey,
		append: '',
		decimals: 0,
		step: 1,
		min: 0,
		max: 1000000,
		value: 0,
		image: `/${ASSETS_DIR}/img/imdb-logo.png`,
		filterDisplay: false,
	},
	{
		name: 'ROTTEN TOMATOES',
		provider: ScoringProvider.ROTTEN_TOMATOES,
		queryName: 'tomatoMeter' as ScoringKey,
		append: '%',
		decimals: 0,
		step: 1,
		min: 0,
		max: 100,
		value: 0,
		image: `/${ASSETS_DIR}/img/rotten-tomatoes-fresh.png`,
		filterDisplay: true,
	},
];

const JUSTWATCH_SCORING = [
	{
		queryName: 'tmdbScore' as ScoringKey,
		max: 10,
		weight: 1,
	},
	{
		queryName: 'imdbScore' as ScoringKey,
		max: 10,
		weight: 1,
	},
];

export function calculateJustwatchRating(ratings: Scoring): number | null {
	const popularity = ratings.tmdbPopularity || 0;
	let totalScore = 0;
	let weight = 0;

	for (const jwScoring of JUSTWATCH_SCORING) {
		const providerRating = ratings[jwScoring.queryName] || 0;

		weight += jwScoring.weight;
		totalScore += (providerRating / jwScoring.max) * jwScoring.weight;
	}

	return clamp(Math.floor((totalScore / weight) * 100 + (Math.log(250 * popularity + 1) - 1)), 1, 100);
}

export function getTotalScoreVotes(ratings: Scoring): number {
	const totalVotes = ratings.imdbVotes;

	return totalVotes || 0;
}

export function getVotesLabel(votes: number) {
	let suffix = 'k';
	let votesToDisplay = votes / 1000;

	if (votesToDisplay < 1) {
		return null;
	}

	if (votesToDisplay >= 1000) {
		votesToDisplay = votesToDisplay / 1000;
		suffix = 'm';
	}

	return Math.floor(votesToDisplay) + suffix;
}

function clamp(number: number, min: number, max: number): number | null {
	if (isFinite(number)) {
		return Math.min(Math.max(number, min), max);
	} else {
		return null;
	}
}

export function getScorings(
	scoring: Scoring,
	externalIds: ExternalIds,
	excluded: ScoringProvider[] = [],
	includeLinks = false
): FormattedScoring[] {
	const filteredScorings = [];
	for (const scoringSkeleton of SCORINGS) {
		if (!excluded.includes(scoringSkeleton.provider)) {
			const value = { ...scoringSkeleton };

			if (scoringSkeleton.provider === ScoringProvider.IMDB) {
				value.score = scoring[scoringSkeleton.queryName]?.toFixed(scoringSkeleton.decimals);

				// insert a IMDB link out if there is a 'imdb:id'
				if (includeLinks && externalIds.imdbId) {
					value.link = `https://www.imdb.com/title/${externalIds.imdbId}?ref_=ref_ext_justwatch`;
				}
			}

			if (scoringSkeleton.provider === ScoringProvider.ROTTEN_TOMATOES) {
				const tomatoMeterScore = scoring?.tomatoMeter?.toString();
				value.score = tomatoMeterScore ?? undefined;
			}

			if (scoringSkeleton.provider === ScoringProvider.TMDB) {
				const calculatedScore = calculateJustwatchRating(scoring);
				if (calculatedScore !== null && calculatedScore >= 0) {
					value.score = calculatedScore.toString();
				}
			}

			filteredScorings.push(value);
		}
	}

	return filteredScorings;
}

export function getRottenTomatoesScoreIcon(score: number, certifiedFresh: boolean) {
	let icon = `/${ASSETS_DIR}/img/rotten-tomatoes-empty.png`;

	if (score < 60) {
		icon = `/${ASSETS_DIR}/img/rotten-tomatoes-rotten.png`;
	} else {
		icon = `/${ASSETS_DIR}/img/rotten-tomatoes-fresh.png`;
	}

	if (certifiedFresh) icon = `/${ASSETS_DIR}/img/rotten-tomatoes-certified-fresh.png`;

	return icon;
}
