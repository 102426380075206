import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArrowLeft,
	faArrowRight,
	faBan,
	faBars,
	faBell,
	faBookmark,
	faCalendar,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronCircleRight,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faClipboard,
	faClock,
	faCompass,
	faCrown,
	faDesktop,
	faEllipsisV,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faExpand,
	faExternalLinkAlt,
	faEnvelope,
	faFilm,
	faFilter,
	faFire,
	faFootballBall,
	faFrown,
	faFrownOpen,
	faFutbol,
	faGlobeAmericas,
	faGlobeEurope,
	faGrinHearts,
	faHeart,
	faHome,
	faInfoCircle,
	faLanguage,
	faList,
	faMapMarkerAlt,
	faMapPin,
	faMeh,
	faMinusCircle,
	faPen,
	faPencilAlt,
	faPercentage,
	faPlay,
	faPlayCircle,
	faPlus,
	faQuestionCircle,
	faRedo,
	faRedoAlt,
	faSearch,
	faShareAlt,
	faSmile,
	faSortAlphaDownAlt,
	faStream,
	faTableCells,
	faTh,
	faThList,
	faThumbsDown,
	faThumbsUp,
	faTicket,
	faTimes,
	faTrash,
	faTv,
	faUser,
	faUserCog,
	faUserFriends,
	faUserPlus,
	faVideo,
	faFileImport,
	faFolderPlus,
	faFlag,
	faStop,
	faLink,
	faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { faHeart as faHeartRegular, faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';

//JustWatch Custom icons
const jwCheckIcon = {
	prefix: 'fas',
	iconName: 'jw-check-icon',
	icon: [
		512, //width
		512, //height
		[], //ligatures
		'e001',
		'm84.13438,223.22084l80.55344,101.52719l306.28647,-295.20374c22.42605,-21.20181 52.68433,6.49166 34.4073,31.18971l-305.30115,407.94665c-22.80379,25.82752 -50.9279,26.29884 -72.13266,-3.65322l-122.44958,-183.92576c-23.13121,-42.56132 49.00758,-93.28321 78.63618,-57.88082z', //iconPath(s)
	],
};

const jwCrownIcon = {
	prefix: 'fas',
	iconName: 'jw-crown-icon',
	icon: [
		512, //width
		512, //height
		[], //ligatures
		'e001',
		'M59.541 120.267l93.856 82.583L230.97 56.853c4.744-5.83 11.237-16.399 25.447-16.399 14.21 0 23.084 10.467 27.7 16.4l71.296 145.972 96.89-83.767a24.666 24.666 0 0140.527 22.2l-36.894 254.488a.856.856 0 01-.847.734H57.148a.856.856 0 01-.847-.731L18.84 142.418a24.666 24.666 0 0140.7-22.15zm-.719 300.88h393.683c.27 0 .49.22.49.49v24.177c0 13.623-11.044 24.666-24.667 24.666H82.999c-13.623 0-24.666-11.043-24.666-24.666v-24.177c0-.27.219-.49.49-.49z', //iconPath(s)
	],
};

// A place to keep Custom icons for FontAwesome.
library.add(
	jwCheckIcon as IconDefinition,
	jwCrownIcon as IconDefinition,
	faAndroid,
	faApple,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArrowLeft,
	faArrowRight,
	faBan,
	faBars,
	faBell,
	faBookmark,
	faBookmarkRegular,
	faCalendar,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronCircleRight,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faClipboard,
	faClock,
	faCompass,
	faCrown,
	faDesktop,
	faEllipsisV,
	faExclamationCircle,
	faExclamationTriangle,
	faExpand,
	faExternalLinkAlt,
	faEnvelope,
	faFilm,
	faFilter,
	faFire,
	faFrown,
	faFrownOpen,
	faGlobeAmericas,
	faGlobeEurope,
	faGrinHearts,
	faHeart,
	faHeartRegular,
	faHome,
	faInfoCircle,
	faLanguage,
	faList,
	faMapMarkerAlt,
	faMapPin,
	faMeh,
	faMinusCircle,
	faPen,
	faPercentage,
	faPlay,
	faPlayCircle,
	faPlus,
	faQuestionCircle,
	faRedo,
	faRedoAlt,
	faSearch,
	faShareAlt,
	faSmile,
	faStream,
	faStop,
	faSortAlphaDownAlt,
	faTableCells,
	faTh,
	faThList,
	faThumbsDown,
	faThumbsUp,
	faTicket,
	faTimes,
	faTrash,
	faTv,
	faUser,
	faUserCog,
	faUserFriends,
	faUserPlus,
	faVideo,
	faExclamation,
	faPencilAlt,
	faFootballBall,
	faFutbol,
	faCaretDown,
	faFileImport,
	faFolderPlus,
	faFlag,
	faLink,
	faDownload
);

export default FontAwesomeIcon;
