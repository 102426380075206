import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';
import { CollectionType } from '@/enums/collection-type';
import { TitleOrder } from '@/enums/title-order';
import {
	SortingItem,
	sortingItemsByCollectionType,
	defaultSortingItemSelectedKeys,
} from '@/helpers/list-sorting-helper';
import { FilterCollection } from '@/stores/filter.store';

// STATE
const state = () => ({});

type State = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<State, any> = {
	defaultSelectedKey: (_state, _getters, _rootState, rootGetters): TitleOrder => {
		const collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;
		return defaultSortingItemSelectedKeys[collectionType];
	},

	selectedItem: (_state, getters, _rootState, rootGetters) => {
		const filters = rootGetters['filter/currentFilters'] as FilterCollection;
		const selectedKey = filters.sort_by || getters.defaultSelectedKey;
		return (getters.sortingItems as SortingItem[])?.find(item => item.key === selectedKey);
	},

	sortingItems: (_state, _getters, _rootState, rootGetters) => {
		const collectionType = rootGetters['routing/activeCollectionType'] as CollectionType;
		return sortingItemsByCollectionType[collectionType];
	},
};

// ACTIONS
const actions: ActionTree<State, any> = {
	setSorting: ({ dispatch, getters }, { sortBy, sortAsc }: { sortBy: string; sortAsc: boolean }) => {
		dispatch(
			'filter/setSorting',
			{
				sortBy: sortBy === getters.defaultSelectedKey ? null : sortBy,
				sortAsc: sortBy === getters.defaultSelectedKey ? null : sortAsc,
			},
			{ root: true }
		);
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
