import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetConstantsQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
}>;

export type GetConstantsQuery = {
	__typename?: 'Query';
	organizations: Array<{ __typename?: 'AgeCertification'; organization: string }>;
	movies: Array<{ __typename?: 'AgeCertification'; technicalName: string; id: number }>;
	shows: Array<{ __typename?: 'AgeCertification'; technicalName: string; id: number }>;
	genres: Array<{ __typename?: 'Genre'; id: string; shortName: string; translation: string; slug: string }>;
};

export const GetConstantsDocument = gql`
	query GetConstants($country: Country!, $language: Language!) {
		organizations: ageCertifications(country: $country) {
			organization
		}
		movies: ageCertifications(country: $country, objectType: MOVIE) {
			id: ageCertificationId
			technicalName
		}
		shows: ageCertifications(country: $country, objectType: SHOW) {
			id: ageCertificationId
			technicalName
		}
		genres {
			id
			shortName
			translation(language: $language)
			slug(language: $language)
		}
	}
`;
