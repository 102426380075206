import { Component, Mixins } from 'vue-property-decorator';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { ExperimentTrackEventOptions } from '@/components/experiments/Core/types';
import { SnowplowExperimentContext, TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';

import BaseExperiment from '@/components/experiments/Core/BaseExperiment.vue';
import { getVm } from '@/helpers/vm-helper';

export type ToVariants<T> = T[keyof T];
type WithControl<Variants> = 'control' | Variants;

/** Generate a basic experiment class. Should cover the majority of experiments. */
export function generate<Variants extends string, TrackingOptions extends ExperimentTrackEventOptions<Variants>>(
	/** Name and ID of the experiment */
	name: string,

	/** Object listing all the variants in this experiment. The CONTROL variant is required. */
	variants: { CONTROL?: 'control' } & { [key: string]: Variants },

	/**
	 * Callback to modify the experiment trigger conditions.
	 * Passes the component instance to access stores and whatnot.
	 */
	shouldTrigger: (instance: Vue) => boolean = () => true,

	/**
	 * Callback to check if the experiment is ready to be activated.
	 * Passes the component instance to access stores and whatnot.
	 */
	isExperimentReady: (instance: Vue) => boolean = () => true
) {
	return Component({ name })(
		class extends Mixins(BaseExperiment) {
			name = name;
			variants = Object.values(variants).map(name => ({ name, weight: 1 }));

			static getExperimentContext({
				version = '1',
				variant,
			}: {
				version?: string;
				variant: WithControl<Variants>;
			}) {
				return new SnowplowExperimentContext(name, version, variant);
			}

			static trackEvent({
				version = '1',
				action,
				label,
				variant,
				contexts = [],
				value,
				property,
			}: TrackingOptions) {
				const activeVariant =
					variant ?? getVm().$store.getters['experiment/activeVariantsWithControlGroup'][name] ?? null;

				// not in experiment
				if (activeVariant == null) return;

				const properties: TrackingProviderPropertiesInterface = { action, label, value, property };
				if (action === 'impression') properties.nonInteraction = true;

				TrackingHelper.trackEvent('onpage_test', properties, [
					...contexts,
					this.getExperimentContext({ version, variant: activeVariant }),
				]);
			}

			isExperimentReadyToBeActivated() {
				return isExperimentReady(this);
			}

			async onReady() {
				if (shouldTrigger(this)) this.trigger();
			}
		}
	);
}
