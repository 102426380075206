export enum WebLocale {
	ad = 'ad',
	ag = 'ag',
	ae = 'ae',
	al = 'al',
	az = 'az',
	ao = 'ao',
	ar = 'ar',
	at = 'at',
	au = 'au',
	ba = 'ba',
	bb = 'bb',
	be = 'be',
	bf = 'bf',
	bg = 'bg',
	bh = 'bh',
	bm = 'bm',
	bo = 'bo',
	br = 'br',
	bs = 'bs',
	by = 'by',
	bz = 'bz',
	ca = 'ca',
	cd = 'cd',
	ch = 'ch',
	ci = 'ci',
	cl = 'cl',
	cm = 'cm',
	co = 'co',
	cr = 'cr',
	cu = 'cu',
	cv = 'cv',
	cy = 'cy',
	cz = 'cz',
	de = 'de',
	dk = 'dk',
	do = 'do',
	dz = 'dz',
	ec = 'ec',
	ee = 'ee',
	eg = 'eg',
	es = 'es',
	fi = 'fi',
	fj = 'fj',
	fr = 'fr',
	gf = 'gf',
	gg = 'gg',
	gh = 'gh',
	gi = 'gi',
	gq = 'gq',
	gr = 'gr',
	gt = 'gt',
	gy = 'gy',
	hk = 'hk',
	hn = 'hn',
	hr = 'hr',
	hu = 'hu',
	id = 'id',
	ie = 'ie',
	il = 'il',
	in = 'in',
	iq = 'iq',
	is = 'is',
	it = 'it',
	jm = 'jm',
	jo = 'jo',
	jp = 'jp',
	ke = 'ke',
	kr = 'kr',
	kw = 'kw',
	lb = 'lb',
	lc = 'lc',
	li = 'li',
	lt = 'lt',
	lu = 'lu',
	lv = 'lv',
	ly = 'ly',
	ma = 'ma',
	mc = 'mc',
	md = 'md',
	me = 'me',
	mg = 'mg',
	mk = 'mk',
	ml = 'ml',
	mt = 'mt',
	mu = 'mu',
	mw = 'mw',
	mx = 'mx',
	my = 'my',
	mz = 'mz',
	ne = 'ne',
	ng = 'ng',
	ni = 'ni',
	nl = 'nl',
	no = 'no',
	nz = 'nz',
	om = 'om',
	pa = 'pa',
	pe = 'pe',
	pf = 'pf',
	pg = 'pg',
	ph = 'ph',
	pk = 'pk',
	pl = 'pl',
	ps = 'ps',
	pt = 'pt',
	py = 'py',
	qa = 'qa',
	ro = 'ro',
	rs = 'rs',
	ru = 'ru',
	sa = 'sa',
	sc = 'sc',
	se = 'se',
	sg = 'sg',
	sk = 'sk',
	si = 'si',
	sm = 'sm',
	sn = 'sn',
	sv = 'sv',
	tc = 'tc',
	td = 'td',
	th = 'th',
	tn = 'tn',
	tr = 'tr',
	tt = 'tt',
	tw = 'tw',
	tz = 'tz',
	ua = 'ua',
	ug = 'ug',
	uk = 'uk',
	us = 'us',
	uy = 'uy',
	va = 'va',
	ve = 've',
	xk = 'xk',
	ye = 'ye',
	za = 'za',
	zm = 'zm',
	zw = 'zw',
}
