import { SnowplowCMPContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from './tracking/tracking-helper';
import { UCConfigInterface } from '@/constants/user-centrics';

declare global {
	interface Window {
		UC_UI: {
			showFirstLayer: () => void;
			showSecondLayer: () => void;
			acceptAllConsents: () => Promise<void> | null;
			getServicesBaseInfo: () => BaseService[];
			clearStorage: () => Promise<void>;
			restartCMP: () => Promise<void>;
			acceptServices: (serviceTemplateId: string[], consentType?: 'explicit' | 'implicit') => Promise<void>;
			rejectServices: (serviceTemplateId: string[], consentType?: 'explicit' | 'implicit') => Promise<void>;
		};
		UC_UI_SUPPRESS_CMP_DISPLAY: boolean;
	}
}

const UC_SCRIPT_ID = 'usercentrics-cmp';

interface BaseService {
	name: string;
	id: string;
	categorySlug: string;
	consent: {
		status: boolean;
		history: {
			type: 'explicit' | 'implicit';
		}[];
	};
}

export interface DataProcessingServiceConsent {
	name: string;
	id: string;
	category: string;
	status: boolean;
	hasAnswered: boolean;
}

export enum DataProcessingService {
	DOUBLECLICK_AD = 'DOUBLECLICK_AD',
	DOUBLECLICK_FLOODLIGHT = 'DOUBLECLICK_FLOODLIGHT',
	FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
	FACEBOOK_SOCIAL_PLUGINS = 'FACEBOOK_SOCIAL_PLUGINS',
	FIREBASE_AUTH = 'FIREBASE_AUTH',
	GOOGLE_ADS = 'GOOGLE_ADS',
	GOOGLE_ADSENSE = 'GOOGLE_ADSENSE',
	GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
	GOOGLE_FONTS = 'GOOGLE_FONTS',
	GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
	GSTATIC = 'GSTATIC',
	JUSTWATCH = 'JUSTWATCH',
	PINTEREST = 'PINTEREST',
	RECAPTCHA = 'RECAPTCHA',
	SENTRY = 'SENTRY',
	SNAPCHAT_PIXEL = 'SNAPCHAT_PIXEL',
	SPOTX = 'SPOTX',
	TIKTOK = 'TIKTOK',
	TWITTER_ANALYTICS = 'TWITTER_ANALYTICS',
	USERCENTRICS_CMP = 'USERCENTRICS_CMP',
	YOUTUBE_VIDEOS = 'YOUTUBE_VIDEOS',
	ZENDESK = 'ZENDESK',
}

export const LocalDataProcessingServices: Record<DataProcessingService, { name: string; id: string }> = {
	[DataProcessingService.DOUBLECLICK_AD]: { name: 'DoubleClick Ad', id: '9V8bg4D63' },
	[DataProcessingService.DOUBLECLICK_FLOODLIGHT]: { name: 'DoubleClick Floodlight', id: 'ByzZ5EsOsZX' },
	[DataProcessingService.FACEBOOK_PIXEL]: { name: 'Facebook Pixel', id: 'ko1w5PpFl' },
	[DataProcessingService.FACEBOOK_SOCIAL_PLUGINS]: { name: 'Facebook Social Plugins', id: 'XYQZBUojc' },
	[DataProcessingService.FIREBASE_AUTH]: { name: 'Firebase Authentication', id: 'smOQ7x8wu' },
	[DataProcessingService.GOOGLE_ADS]: { name: 'Google Ads', id: 'S1_9Vsuj-Q' },
	[DataProcessingService.GOOGLE_ADSENSE]: { name: 'Google AdServices', id: 'rJ99c4oOo-X' },
	[DataProcessingService.GOOGLE_ANALYTICS]: { name: 'Google Analytics', id: 'HkocEodjb7' },
	[DataProcessingService.GOOGLE_FONTS]: { name: 'Google Fonts', id: 'HkPBYFofN' },
	[DataProcessingService.GOOGLE_TAG_MANAGER]: { name: 'Google Tag Manager', id: 'BJ59EidsWQ' },
	[DataProcessingService.GSTATIC]: { name: 'gstatic.com', id: 'rJJjcVouoZ7' },
	[DataProcessingService.JUSTWATCH]: { name: 'JustWatch', id: 'qGy9kBiI8' },
	[DataProcessingService.PINTEREST]: { name: 'Pinterest', id: '9Q2qrmB3' },
	[DataProcessingService.RECAPTCHA]: { name: 'reCAPTCHA', id: 'Hko_qNsui-Q' },
	[DataProcessingService.SENTRY]: { name: 'Sentry', id: 'rH1vNPCFR' },
	[DataProcessingService.SNAPCHAT_PIXEL]: { name: 'Snapchat Pixel', id: '5b32c5b6-8e7f-4495-93ab-0061758f9a18' },
	[DataProcessingService.SPOTX]: { name: 'SpotX', id: 'ByLolcNs_i-m' },
	[DataProcessingService.TIKTOK]: { name: 'TikTok', id: 'Z0TcXjY0P' },
	[DataProcessingService.TWITTER_ANALYTICS]: { name: 'Twitter Analytics', id: 'S1hmcVouiZm' },
	[DataProcessingService.USERCENTRICS_CMP]: { name: 'Usercentrics Consent Management Platform', id: 'H1Vl5NidjWX' },
	[DataProcessingService.YOUTUBE_VIDEOS]: { name: 'YouTube Video', id: 'BJz7qNsdj-7' },
	[DataProcessingService.ZENDESK]: { name: 'Zendesk', id: '54s8nFgf' },
};

export type UC_UI_EVENT_DETAIL =
	| { type: 'CMP_SHOWN'; source: 'FIRST_LAYER' }
	| { type: 'MORE_INFORMATION_LINK'; source: 'FIRST_LAYER' }
	| { type: 'ACCEPT_ALL'; source: 'FIRST_LAYER' | 'SECOND_LAYER' | 'UC_UI_API' }
	| { type: 'SAVE'; source: 'FIRST_LAYER' | 'SECOND_LAYER' | 'UC_UI_API' }
	| { type: 'CCPA_TOGGLES_ON'; source: 'FIRST_LAYER' | 'SECOND_LAYER' }
	| { type: 'CCPA_TOGGLES_OFF'; source: 'FIRST_LAYER' | 'SECOND_LAYER' };

export const UsercentricsHelper = {
	uiScriptLoaded: false,

	initialize(UCConfig: UCConfigInterface): Promise<DataProcessingServiceConsent[]> {
		return new Promise(async (resolve, _reject) => {
			if (isUsercentricsEnabled() && !this.uiScriptLoaded) {
				// @todo check to UC_UI.restartCMP() when bestEffortId changes
				this.uiScriptLoaded = await loadUI(UCConfig);

				resolve(getServicesConsents());
			}

			resolve([]);
		});
	},

	/**
	 * whenever a Usercentrics UI event happens, we sync the service consents from UC with our local state through the callback.
	 */
	registerAndTrackUsercentricEvents(saveCallback: (dpsConsents: DataProcessingServiceConsent[]) => void) {
		window.addEventListener('UC_UI_CMP_EVENT', evt => {
			const detail = (<CustomEvent>evt).detail as UC_UI_EVENT_DETAIL;
			const getConsentedServices = (consents: DataProcessingServiceConsent[]) => {
				return JSON.stringify(
					consents.filter(consent => consent.status).map(consent => ({ name: consent.name, id: consent.id }))
				);
			};
			let consents;
			switch (detail.type) {
				case 'CMP_SHOWN':
					TrackingHelper.trackEvent('cmp_consent', {
						action: 'seen',
						label: 'first_layer',
						nonInteraction: true,
					});
					break;
				case 'MORE_INFORMATION_LINK':
					TrackingHelper.trackEvent('cmp_consent', {
						action: 'seen',
						label: 'second_layer',
						nonInteraction: true,
					});
					break;
				case 'ACCEPT_ALL':
					// save consents from 3rd party modal
					consents = getServicesConsents();
					saveCallback(consents);
					// track save event
					// JSONed array name and id of all consents given
					const cmpContext = new SnowplowCMPContext(getConsentedServices(consents));

					TrackingHelper.trackEvent(
						'cmp_consent',
						{
							action: 'all_saved',
							// UC_UI_API means that it's been triggered through window.UC_UI like within the consent banner
							label: detail.source === 'UC_UI_API' ? 'banner' : detail.source.toLowerCase(),
							nonInteraction: true,
							// amount of consents given
							value: consents.filter(consent => consent.status).length,
						},
						[cmpContext]
					);
					break;

				// track single consents
				case 'SAVE':
					// save consents from 3rd party modal
					consents = getServicesConsents();
					saveCallback(consents);
					// track save event
					TrackingHelper.trackEvent('cmp_consent', {
						action: 'saved',
						label: detail.source.toLowerCase(),
						nonInteraction: true,
						// amount of consents given
						value: consents.filter(consent => consent.status).length,
						// JSONed array name and id of all consents given
						property: getConsentedServices(consents),
					});
					break;

				// CCPA toggle consents
				case 'CCPA_TOGGLES_ON':
				case 'CCPA_TOGGLES_OFF':
					consents = getServicesConsents();
					saveCallback(consents);
					break;
			}
		});
	},

	async consentToAllServices(): Promise<void> {
		return window.UC_UI.acceptAllConsents() ?? Promise.resolve();
	},
};

function getServicesConsents(): DataProcessingServiceConsent[] {
	return (
		window.UC_UI.getServicesBaseInfo()
			.map(service => ({
				name: service.name,
				id: service.id,
				category: service.categorySlug,
				status: service.consent.status,
				// whether user has explicitly consented OR rejected the service
				hasAnswered: service.consent.history.some((item: any) => item.type === 'explicit'),
			}))
			// ensure that the ordering is deterministic alphabetically
			.sort((serviceA, serviceB) => serviceA.name.localeCompare(serviceB.name))
	);
}

function isUsercentricsEnabled() {
	return process.client;
}

function loadUI({ configId, isTCFEnabled }: UCConfigInterface): Promise<boolean> {
	return new Promise((resolve, reject) => {
		try {
			window.UC_UI_SUPPRESS_CMP_DISPLAY = false;
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.id = UC_SCRIPT_ID;
			script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';

			script.setAttribute('data-settings-id', configId);

			if (isTCFEnabled) {
				script.setAttribute('data-tcf-enabled', 'true');
			}

			window.addEventListener('UC_UI_INITIALIZED', () => resolve(true));

			document.head.appendChild(script);
		} catch {
			reject(false);
		}
	});
}
