import { COUNTRY_EXCEPTIONS } from '@/constants/web-locales.constant';

import { WebLocale } from '@/enums/web-locale';

import { getAxiosCached } from '@/helpers/axios-helper';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

export type LocaleResponse = {
	country: string;
	country_names: Record<string, string>;
	currency: string;
	currency_name: string;
	exposed_url_part: WebLocale;
	full_locale: string;
	i18n_state: 'active' | 'staging';
	is_standard_locale: boolean;
	iso_3166_2: string;
	timezone: string; // e.g. Europe/Berlin
	timezone_offset: number; // e.g. 120
	timezone_abbreviation: string; // e.g. CEST
	title_urls_enabled: boolean;
};

export type Locale = LocaleResponse & { webLocale: WebLocale };

/** Fallback US locale if one can't be found for any reason. */
export const FallbackLocale: Locale = {
	exposed_url_part: WebLocale.us,
	is_standard_locale: true,
	full_locale: 'en_US',
	timezone: 'America/New_York',
	timezone_offset: -240,
	timezone_abbreviation: 'EDT',
	title_urls_enabled: true,
	i18n_state: 'active',
	iso_3166_2: 'US',
	country: 'United States',
	currency: 'USD',
	currency_name: 'US Dollar',
	country_names: {
		de: 'Vereinigte Staaten von Amerika',
		es: 'Estados Unidos',
		fr: 'États-Unis',
		hr: 'Sjedinjene Američke Države',
		it: "Stati Uniti D'America",
		ja: 'アメリカ合衆国',
		nl: 'Verenigde Staten',
		ru: 'Соединённые Штаты Америки',
	},
	webLocale: WebLocale.us,
};

export async function getLocales(): Promise<Locale[]> {
	let availableLocales: Locale[] = [];

	try {
		const response = await (
			await getAxiosCached()
		).get(`${JW_CONFIG.API}/locales/state`, {
			params: { platform: 'web' },
		});

		const localesList = response.data;
		if (localesList && localesList.length) {
			availableLocales = localesList.map((data: any) => {
				const webLocale =
					data.exposed_url_part in COUNTRY_EXCEPTIONS
						? COUNTRY_EXCEPTIONS[data.exposed_url_part]
						: (data.exposed_url_part as WebLocale);

				return {
					...data,
					webLocale,
				};
			});
		}
	} catch (error: any) {
		captureMessageForSentry('Main Init Issue:', { error, where: '[locale-heleper.ts] getLocales()' }, 'error');
	}

	return availableLocales;
}

export async function getActiveLocales(): Promise<Locale[]> {
	const locales: Locale[] = await getLocales();
	const activeLocales = locales.filter(webLocale => webLocale.i18n_state === 'active');

	if (activeLocales.length) {
		return activeLocales;
	}

	// fallback to local list
	const defaultLocalesJson = await import('@/default-locales.json').then(module => module.default);
	return defaultLocalesJson as unknown as Locale[];
}

export function getLanguage(localeCode: string): string {
	if (localeCode.length < 2) {
		throw new Error(`[getLanguage] localeCode: ${localeCode} is too short`);
	}

	return localeCode.substr(0, 2);
}

/**
 * get webLocale from browser when available
 *
 * @returns {string|null} webLocale
 */
export function getLanguageFromBrowser(): string | null {
	const browserLangLocale = navigator?.language;

	if (!browserLangLocale) {
		return null;
	}
	return getLanguage(browserLangLocale);
}
