import { computed, onMounted, ref, watch } from 'vue';
import { useExperimentStore, useReadyStore, useRoutingStore, useUserStore } from './useStores';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { SnowplowExperimentContext, TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';

import type { ToValue as ToVariants } from '@/helpers/types';
import type { ExperimentTrackEventOptions } from '@/components/experiments/Core/types';
import type { ExperimentData } from '@/helpers/experiment-helper';

type VariantsDefinition<T extends string> = { CONTROL: 'control' } & { [key: string]: T };

interface UseExperimentOptions<T extends string = string> {
	/** Name and ID of the experiment */
	name: string;

	/** Object listing all the variants in this experiment. The CONTROL variant is required. */
	variants: VariantsDefinition<T>;

	/** Callback to modify the experiment trigger conditions. */
	shouldTrigger?: () => boolean;

	/** Callback to check if the experiment is ready to be activated. */
	isExperimentReady?: () => boolean;

	/**
	 * The route names ($route.name, activeRoute.name, etc) from which the experiment can be triggered.
	 * An empty array means **every page** is valid.
	 */
	readyOn?: string[];
}

let resolveExperimentsLoaded: <T>(val: T) => void;
const awaitExperimentsLoaded = new Promise(res => (resolveExperimentsLoaded = res));

/** Assigns the type for the variable saving the `useExperiment` return. */
export type FromExperiment<Config extends UseExperimentOptions> = ReturnType<typeof useExperiment<Config>> | null;

/**
 * Accepts an experiment definition and handles the registration and triggering logic.
 * The return of this composable should be saved into a singleton to avoid triggering watchers too much.
 *
 * ```ts
 * const config = {
 *   name: 'MY_EXPERIMENT',
 *   variants: { CONTROL: 'control', VARIANT_1: 'variant_1'} as const,
 * };
 *
 * // experiment singleton
 * let experiment: FromExperiment<typeof config> = null;
 *
 * export function useMyExperiment() {
 * 	// make sure to call `useExperiment` inside!
 * 	if (experiment === null) {
 * 		experiment = useExperiment({
 * 			...config,
 * 			// other configuration options
 * 			shouldTrigger: () => country.value === 'US',
 * 		});
 * 	}
 *
 * 	// rest of experiment composable code .....
 * }
 * ```
 */
export function useExperiment<T extends UseExperimentOptions>({
	name,
	variants,
	shouldTrigger = () => true,
	isExperimentReady = () => true,
	readyOn = [],
}: T) {
	const experimentData = ref<ExperimentData>({
		name,
		variants: Object.values(variants).map(name => ({ name, weight: 1 })),
		goals: [],
	});

	const { activeVariantsWithControlGroup } = useExperimentStore();

	type Variants = 'control' | ToVariants<T['variants']>;

	const activeVariant = computed<Variants | null>(() => activeVariantsWithControlGroup.value?.[name] ?? null);

	type TrackingOptions = ExperimentTrackEventOptions<Variants>;
	function trackEvent({ version = '1', action, label, variant, contexts = [], value, property }: TrackingOptions) {
		// not in experiment
		if (activeVariant.value == null && variant == null) return;

		const currentVariant = variant ?? activeVariant.value;

		const properties: TrackingProviderPropertiesInterface = { action, label, value, property };
		if (action === 'impression') properties.nonInteraction = true;

		TrackingHelper.trackEvent('onpage_test', properties, [
			...contexts,
			new SnowplowExperimentContext(name, version, currentVariant!),
		]);
	}

	const { registerExperiment, triggerExperiment } = useExperimentStore();

	let resolveMounted: <T>(val: T) => void;
	const awaitMounted = new Promise(res => (resolveMounted = res));

	const isMounted = ref(false);
	watch(isMounted, mounted => mounted && resolveMounted(mounted));
	onMounted(() => {
		if (isMounted.value) return;
		isMounted.value = true;

		registerExperiment(experimentData.value);
	});

	const { experimentsLoaded } = useReadyStore();
	watch(experimentsLoaded, loaded => loaded && resolveExperimentsLoaded(loaded), { immediate: true });

	const hasActivated = ref(false);
	const { activeRoute } = useRoutingStore();

	async function setReady() {
		// Can only be ready if any page is accepted (readyOn.length === 0) or
		// the current page isn't for the experiment or it isn't ready
		if (readyOn.length !== 0 && (!readyOn.includes(activeRoute.value?.name) || !isExperimentReady())) return;

		// experiments are client side only
		await Promise.all([awaitExperimentsLoaded, awaitMounted]);

		// only set ready when the experiment hasn't been activated before (only one try per session per experiment)
		if (hasActivated.value || activeVariant.value) return;
		hasActivated.value = true;

		if (!shouldTrigger()) return;

		triggerExperiment(experimentData.value);
	}

	watch(activeRoute, () => setReady(), { immediate: true });

	const { preferredExperiments } = useUserStore();
	watch(preferredExperiments, () => {
		hasActivated.value = false;

		registerExperiment(experimentData.value);
		setReady();
	});

	return {
		/** Returns the string value of the currently active experiment variant. `null` otherwise. */
		activeVariant,

		/**
		 * Track an experiment event with category `onpage_test`.
		 * Experiment context of the current variant is already included.
		 */
		trackEvent,
	};
}
