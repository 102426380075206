import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { getAxiosCached } from '@/helpers/axios-helper';
import { ExperimentData, ExperimentMap, addExperiment, getExpActiveVariants } from '@/helpers/experiment-helper';

import { APP_PLATFORM } from '@/stores/app.store';
import { ReadyType } from '@/stores/ready.store';

export interface ExperimentsVariants {
	home: [];
	titleDetail: [];
}

// STATE
export const INITIAL_EXPERIMENT_STATE = {
	/**
	 * set ?preferred_variant=EXPERIMENT_NAME:variant to activate test manually
	 * set &snowplow=true to send snowplow event in dubug mode
	 */
	experiments: {} as ExperimentMap,
	platform: APP_PLATFORM.substring(0, 3),
	experimentsVariants: {
		home: [],
		titleDetail: [],
	} as ExperimentsVariants,
};

const state = () => ({ ...INITIAL_EXPERIMENT_STATE });

type State = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<State, any> = {
	experimentsList(state): ExperimentMap {
		if (state.experiments !== undefined) {
			return state.experiments;
		} else {
			return {};
		}
	},

	activeVariants(state, getters, _rootState, rootGetters): Record<string, string> {
		return getExpActiveVariants(state.experiments, rootGetters['user/userExperiments']);
	},

	// This is a quick fix just to make sure to not mess with the activeVariants
	activeVariantsWithControlGroup(state, getters, _rootState, rootGetters): Record<string, string> {
		return getExpActiveVariants(state.experiments, rootGetters['user/userExperiments'], true);
	},

	getMLExperimentsVariants:
		state =>
		(key: keyof ExperimentsVariants): [] => {
			return state.experimentsVariants[key];
		},
};

// ACTIONS
const actions: ActionTree<State, any> = {
	/**
	 * experiment blueprints call this function to register an experiment.
	 * if the experiment has been activated before, it will be added to track goals.
	 */
	registerExperiment({ commit, state, rootGetters, rootState }, experimentData: ExperimentData) {
		if (process.client) {
			addExperiment(
				experimentData,
				state.experiments,
				rootGetters['user/userExperiments'],
				rootGetters['user/bestEffortId'],
				rootState.user.alephbet,
				commit
			);
		}
	},

	triggerExperiment({ state, commit, rootGetters, rootState }, experimentData: ExperimentData) {
		if (process.client) {
			addExperiment(
				experimentData,
				state.experiments,
				rootGetters['user/userExperiments'],
				rootGetters['user/bestEffortId'],
				rootState.user.alephbet,
				commit,
				false
			);
		}
	},

	async fetchExperimentsConfig({ rootGetters, commit }) {
		// For testing and dev environment we sometimes do not want to have experiments.
		if (process.env.VUE_APP_DISABLE_EXPERIMENTS) {
			commit('SET_EXPERIMENT_CONFIG', []);
			return;
		}
		const language = rootGetters['language/language'];
		const country = rootGetters['language/country'];

		if (!language || !country) {
			console.error(`[fetchExperimentsConfig] language: ${language} || country: ${country}  undefined`);
			return;
		}
		try {
			const notLocalizedLanguage = language.split('-')[0];
			const response = await (
				await getAxiosCached()
			).get(`${JW_CONFIG.EXPERIMENT_CONFIG_URL}?language=${notLocalizedLanguage}&country=${country}`);

			const legacyFormatData = response.data.reduce((acc: any, cur: any) => {
				acc[cur.name] = cur;

				return acc;
			}, {});

			await commit('SET_EXPERIMENTS', legacyFormatData);

			commit('ready/SET_READY', ReadyType.EXPERIMENTS_LOADED, { root: true });
		} catch (error) {
			console.error('[fetchExperimentsConfig] failed to fetch experiments config');
		}
	},

	addHomeExperimentsVariants({ commit, getters }, variant: string) {
		const currentVariants = [...getters.getMLExperimentsVariants('home')];

		if (currentVariants.indexOf(variant) === -1) {
			currentVariants.push(variant);
		}
		commit('SET_HEADER_EXPERIMENTS_VARIANTS', { key: 'home', currentVariants });
	},

	addTitleDetailExperimentsVariants({ commit, getters }, variant: string) {
		const currentVariants = [...getters.getMLExperimentsVariants('titleDetail')];

		if (currentVariants.indexOf(variant) === -1) {
			currentVariants.push(variant);
		}
		commit('SET_HEADER_EXPERIMENTS_VARIANTS', { key: 'titleDetail', currentVariants });
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {
	SET_EXPERIMENTS: (state, experiments) => {
		state.experiments = experiments || {};
	},

	SET_HEADER_EXPERIMENTS_VARIANTS(
		state,
		{ key, currentVariants }: { key: keyof ExperimentsVariants; currentVariants: [] }
	) {
		Vue.set(state.experimentsVariants, key, currentVariants);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
