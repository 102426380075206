import { Viewport } from '@/enums/viewport';
import { createThumborFilter, IMAGE_PROFILE, Profile, showLowQualityImages, ThumborImage } from '@/filters/thumbor';
import { hasRetinaSupport } from './ui-helper';

export interface Breakpoints {
	'screen-xs-min': number;
	'screen-sm-min': number;
	'screen-md-min': number;
	'screen-lg-min': number;
}
/**
 * Get all breakpoint keys and pixel values via html::after generated content from global.scss
 * @return {Breakpoints} - key/value pairs
 **/
const BREAKPOINT_VALUES: Record<string, number> = {
	// @todo import $breakpoints variable from css
	'screen-xs-min': 480,
	'screen-sm-min': 768,
	'screen-md-min': 992,
	'screen-lg-min': 1200,
};

interface Source {
	extension?: string;
	resolution?: string;
	slug?: string;
	viewport?: Viewport;
}

export interface Sources {
	viewport: Viewport;
	breakpoint: keyof Breakpoints;
}

export interface ImageProp {
	type: string;
	extension: string | undefined;
}

export const SOURCE_PROPS: Sources[] = [
	{
		viewport: Viewport.SMARTPHONE,
		breakpoint: 'screen-xs-min',
	},
	{
		viewport: Viewport.TABLET,
		breakpoint: 'screen-md-min',
	},
	{
		viewport: Viewport.DESKTOP,
		breakpoint: 'screen-lg-min',
	},
];

const IMAGE_PROPS: Record<string, ImageProp> = {
	avif: {
		type: 'image/avif',
		extension: '.avif',
	},
	webp: {
		type: 'image/webp',
		extension: '.webp',
	},
	jpeg: {
		type: 'image/jpeg',
		extension: undefined,
	},
};

/**
 * Returns comma and space separated backdrop image URLs for the srcset attribute
 * @param {Source} srcSets - viewport, extension, and resolution
 */
function getImageUrls(
	srcSets: Source[],
	imageUrl: string,
	imageProfile: keyof ThumborImage<any>,
	slug: string,
	criticalImage: boolean
): string {
	const imgUrl = imageUrl.replace(JW_CONFIG.IMAGESCALER_FAST_URL, JW_CONFIG.IMAGESCALER_URL);

	return srcSets
		.map(srcSet =>
			createThumborFilter()({
				// TODO: in some cases we get a fullpath as an imageUrl so we won't interpolate {profile} anymore.
				// but there still can be a {format} that needs to be replaced.
				url: imgUrl.replace('.{format}', srcSet.extension || ''),
				imageType: imageProfile,
				currentViewport: srcSet.viewport,
				extension: srcSet.extension,
				resolution: srcSet.resolution,
				slug,
				criticalImage,
			})
		)
		.join(', ');
}

export const getMediaForViewport = (sources: Sources[]): Partial<Record<Viewport, string>> => {
	if (sources.length === 1) {
		return {};
	}

	const result: Partial<Record<Viewport, string>> = {};
	sources.forEach((source, index) => {
		// For last viewport we ignore breakpoint value, and take value from previous one instead.
		if (index === sources.length - 1) {
			const breakpoint = BREAKPOINT_VALUES[sources[index - 1].breakpoint];
			result[source.viewport] = `(min-width: ${breakpoint}px)`;
			return;
		}

		const breakpoint = BREAKPOINT_VALUES[source.breakpoint] - 1;
		result[source.viewport] = `(max-width: ${breakpoint}px)`;
	});
	return result;
};

/**
 * @param isLowQuality null means both compare across both qualities.
 * @param imageProfile
 */
export const getViewportsWithUniqueSources = (imageProfile: Profile, lowQuality: boolean | null = null): Viewport[] => {
	// Image profile might contain same source for multiple viewports.
	// We want to remove duplicates to keep minimal number of sourcesets in DOM.
	// For duplicates we always take latter viewport.
	const result: Record<string, Viewport> = {};
	const lowQualitySet = lowQuality === null ? [false, true] : [lowQuality];

	Object.values(Viewport).forEach((viewport: Viewport) => {
		// looks like this when lowQuality is null - s640-s1440
		// like this when lowQuality is boolean - s640
		const key = lowQualitySet
			.map(quality => {
				const imageProfileKey = `${viewport}${quality ? '_low' : ''}` as keyof Profile;
				return imageProfile[imageProfileKey];
			})
			.join('-');

		result[key] = viewport;
	});
	return Object.values(result);
};

const PictureCompHelper = {
	/**
	 * returns the source-set of one image based on:
	 * - supported breakpoints in SOURCE_PROPS
	 * - supported image formats in IMAGE_PROPS
	 */
	getSourceSetImages: (
		imageUrl: string,
		profile: keyof ThumborImage<any>,
		slug: string,
		imagePropFilter = ['avif', 'webp', 'jpeg'], // undefined = unfiltered, ['webp']
		criticalImage = false,
		imageProps = IMAGE_PROPS
	) => {
		const imageProfile: Profile = IMAGE_PROFILE[profile];
		const isLowQuality = showLowQualityImages({
			hasRetinaSupport: hasRetinaSupport(),
		});

		const uniqueViewports = getViewportsWithUniqueSources(
			imageProfile,
			!isLowQuality ? false : undefined /* for high quality we don't need low quality */
		);
		const filteredSourceProps = SOURCE_PROPS.filter(sourceProp => uniqueViewports.includes(sourceProp.viewport));
		const mediaForViewport = getMediaForViewport(filteredSourceProps);

		return filteredSourceProps
			.map(sourceProp => {
				// const media =
				return Object.keys(imageProps)
					.filter(key => !imagePropFilter || imagePropFilter.includes(key))
					.map(key => {
						const imageProp = imageProps[key];
						const srcSet = getImageUrls(
							// TODO Resolution is not handled well, we should make it easier to understand/support.
							[
								{
									extension: imageProp.extension,
									resolution: undefined,
									viewport: sourceProp.viewport as any,
								},
								{
									extension: imageProp.extension,
									resolution: '2x',
									viewport: sourceProp.viewport as any,
								},
							],
							imageUrl,
							profile,
							slug,
							criticalImage
						);

						return {
							sourceProp,
							imageProp: imageProps[key],
							srcSet,
							media: mediaForViewport[sourceProp.viewport] || '',
						};
					});
			})
			.flat();
	},

	getDefaultImageUrl: (imageUrl: string, profile: keyof ThumborImage<any>, slug: string, criticalImage = false) => {
		return getImageUrls([{}], imageUrl, profile, slug, criticalImage);
	},
};

export default PictureCompHelper;
