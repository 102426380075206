import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type RegisterSessionStartMutationVariables = Types.Exact<{ [key: string]: never }>;

export type RegisterSessionStartMutation = {
	__typename?: 'Mutation';
	registerSessionStart: { __typename?: 'RegisterPayload'; loginId: string };
};

export const RegisterSessionStartDocument = gql`
	mutation RegisterSessionStart {
		registerSessionStart(input: { platform: WEB }) {
			loginId
		}
	}
`;
