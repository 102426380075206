export enum ROLE_GROUP {
	ALL = 'ALL',
	STARRED = 'STARRED',
	WRITTEN = 'WRITTEN',
	PRODUCED = 'PRODUCED',
	DIRECTED = 'DIRECTED',
}

export enum ROLE {
	ACTOR = 'ACTOR',
	ASSISTANT_DIRECTOR = 'ASSISTANT_DIRECTOR',
	AUTHOR = 'AUTHOR',
	CAMEO = 'CAMEO',
	CO_EXECUTIVE_PRODUCER = 'CO_EXECUTIVE_PRODUCER',
	CO_PRODUCER = 'CO_PRODUCER',
	CO_WRITER = 'CO_WRITER',
	DIRECTOR = 'DIRECTOR',
	EDITOR = 'EDITOR',
	EXECUTIVE_PRODUCER = 'EXECUTIVE_PRODUCER',
	MUSIC = 'MUSIC',
	ORIGINAL_MUSIC_COMPOSER = 'ORIGINAL_MUSIC_COMPOSER',
	PRODUCER = 'PRODUCER',
	PRODUCTION_DESIGN = 'PRODUCTION_DESIGN',
	PRODUZENT = 'PRODUZENT',
	SCREENPLAY = 'SCREENPLAY',
	SONGS = 'SONGS',
	SPECIAL_GUEST = 'SPECIAL_GUEST',
	SPECIAL_GUEST_DIRECTOR = 'SPECIAL_GUEST_DIRECTOR',
	STORY = 'STORY',
	VISUAL_EFFECTS = 'VISUAL_EFFECTS',
	VOICE = 'VOICE',
	WRITER = 'WRITER',
}
