import { PurchaserInfo } from '@ionic-native/purchases';

import { SnowplowFreemiumContext } from '@/helpers/tracking/providers';
import { getVm } from '@/helpers/vm-helper';

import { Response } from '@/interfaces/revenueCat';

import revenueCat from '@/services/revenueCatAPI';
import { Store } from 'vuex';
import { findSubscriptionBy } from './pro-web-helper';

const iapCountries = [
	'AT',
	'AU',
	'BE',
	'BG',
	'CA',
	'CH',
	'CZ',
	'DE',
	'DK',
	'EE',
	'ES',
	'GB',
	'GR',
	'FI',
	'FR',
	'HR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'NZ',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SE',
	'US',
	'ZA',
];

export function isPremiumUser(): boolean {
	return getVm()?.$store.getters['user/isPremium'];
}

export function isSupportedPurchaseCountry(): boolean {
	const appCountry = getVm()?.$store.getters['language/country'];
	const isSupportedPurchaseCountry = iapCountries.includes(appCountry);
	return isSupportedPurchaseCountry;
}

export function showFreemium(): boolean {
	// show for premium-enabled users, no matter which country is set and for all platforms
	// show for supported countries on mobile (individual features are responsible for locked/unlocked state)
	// hide in all other cases
	return isSupportedPurchaseCountry() || isPremiumUser();
}

const getFreemiumContext = () => {
	const isHideSeenDislikedTitlesEnabled = getVm()?.$store.getters['user/isHideSeenDislikedTitlesEnabled'] ?? false;
	return new SnowplowFreemiumContext(isHideSeenDislikedTitlesEnabled);
};

const getOrCreateSubscriber = async (): Promise<PurchaserInfo | Response | null> => {
	try {
		const newJwId = getVm().$store.getters['user/jwLoginId'];
		if (newJwId == null) return null;

		const response = await revenueCat.get<Response>(newJwId);
		return response.data;
	} catch {
		return null;
	}
};
const getPurchases = async () => (await import('@ionic-native/purchases')).Purchases;

const getSubscriptionPriceString = async (): Promise<string> => {
	return new Promise(async (resolve, reject) => {
		try {
			const offerings = await (await getPurchases()).getOfferings();
			const priceString = offerings.current?.monthly?.product.price_string;
			if (priceString) {
				resolve(priceString);
			} else {
				reject('no price string returned');
			}
		} catch (error) {
			reject(error);
		}
	});
};

const restorePurchases = async (): Promise<boolean> => {
	return new Promise(async resolve => {
		let isPremium = false;
		try {
			const restoredPurchaserInfo = await (await getPurchases()).restoreTransactions();
			// ... check restored purchaserInfo to see if entitlement is now active
			if (restoredPurchaserInfo && typeof restoredPurchaserInfo.entitlements.active['Pro'] !== 'undefined') {
				isPremium = true;
			}
		} catch (e) {}
		await getVm().$store.dispatch('user/setPremiumStatus', isPremium);
		resolve(isPremium);
	});
};

const updatePremiumStatus = async (store?: Store<any>): Promise<void> => {
	const _store = store || getVm()?.$store;
	let newPremiumStatus = false;

	const purchaserInfo = await getOrCreateSubscriber();

	if (purchaserInfo) {
		if (!('subscriber' in purchaserInfo)) {
			newPremiumStatus = purchaserInfo.activeSubscriptions.length > 0;
		} else {
			const expireDate = purchaserInfo && purchaserInfo?.subscriber?.entitlements['Pro']?.expires_date;
			if (expireDate) {
				newPremiumStatus = new Date(expireDate).getTime() > Date.now();
			} else {
				const product_identifier = (purchaserInfo as Response).subscriber?.entitlements['Pro']
					?.product_identifier;
				newPremiumStatus = findSubscriptionBy('id', product_identifier)?.timeframe === 'one time';
			}
		}
	}
	//@ts-ignore
	const subscriptionId = newPremiumStatus
		? (purchaserInfo as Response)?.subscriber?.entitlements['Pro']?.product_identifier
		: null;

	await _store.dispatch('user/setPremiumStatus', newPremiumStatus);
	await _store.dispatch('user/setSubscriptionInfo', subscriptionId);
};

export { getFreemiumContext, getOrCreateSubscriber, getSubscriptionPriceString, restorePurchases, updatePremiumStatus };
