import { getUrlQueryParameters } from '@/helpers/query-parameters-helper';
import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { CountryCode, getCountryCode } from './geo-location-helper';

interface SrDebuggerInfo {
	holdoutGroup: boolean;
	campaignName: string;
	geoCountry: CountryCode | null;
	sr_keep: boolean;
	testingMode: boolean;
}

// Debugger URL keys
const USER_SR_DEBUGGER_LOCALSTORAGE_KEY = 'sr_debugger';
const FORCE_SR_QUERY = 'sr' as const;
const FORCE_HOLDOUT_QUERY = 'holdout' as const;
const FORCE_GEO_COUNTRY_QUERY = 'geo_country' as const;
const FORCE_SUBDIVISION_QUERY = 'subdivision' as const;
const FORCE_TESTING_MODE_QUERY = 'sr_testing' as const;
const FORCE_KEEP_QUERY = 'sr_keep' as const;

/**
 * Checks to see if the URL query parameter contains any Sponsored Recommendation
 * debugging information. If it does, then we will save this information to
 * localStorage to use to help us debug the SR.
 *
 * Information we will save:
 * - geo_country: using the subdivision or geo_country to get the geo-targeted region
 * - sr: the sponsored recommendation campaign name
 * - holdout: force the campaign to be part of the holdout group
 */
export const storeSrDebuggerInfo = () => {
	if (!process.client) {
		return null;
	}

	// By default, we will start things off by clearing the previous session data
	// A little way for devs to keep the previous local storage value on app init
	if (!isKeepModeEnabled()) {
		clearSrDebuggerInfomation();
	}

	const debuggingInfo: Partial<SrDebuggerInfo> = {};

	// Set the geo-country of the campaign, allowing us to return targeted campaigns without needing a VPN
	const geoCountryQueryParameter =
		getUrlQueryParameters(FORCE_SUBDIVISION_QUERY) || getUrlQueryParameters(FORCE_GEO_COUNTRY_QUERY);
	const forceGeoCountry = getCountryCode(geoCountryQueryParameter);
	if (forceGeoCountry) debuggingInfo.geoCountry = forceGeoCountry;

	// Ensure that the campaign belongs to a holdout group
	const forceHoldoutGroup = getUrlQueryParameters(FORCE_HOLDOUT_QUERY) === 'true';
	if (forceHoldoutGroup) debuggingInfo.holdoutGroup = forceHoldoutGroup;

	// Call a campaign by using it's name
	const forceCampaignName = getUrlQueryParameters(FORCE_SR_QUERY);
	if (forceCampaignName) debuggingInfo.campaignName = forceCampaignName;

	// Allow user to see the campaign by using testing mode, without needing any other variables
	const forceTestingMode = getUrlQueryParameters(FORCE_TESTING_MODE_QUERY) === 'true';
	if (forceTestingMode) debuggingInfo.testingMode = forceTestingMode;

	// Do not delete the local storage on reload
	const hasKeepOnReload = getUrlQueryParameters(FORCE_KEEP_QUERY) === 'true';
	if (hasKeepOnReload) debuggingInfo.sr_keep = hasKeepOnReload;

	// Check to ensure we have something to save into local storage
	if (Object.keys(debuggingInfo).length) {
		try {
			localStorage.setItem(USER_SR_DEBUGGER_LOCALSTORAGE_KEY, JSON.stringify(debuggingInfo));
		} catch (error: any) {
			captureMessageForSentry(
				'[Save SR debuggers info into localStorage]:',
				{
					error,
					where: '[useSponsoredRec.ts] storeSrDebuggerInfo()',
					localStorageKey: USER_SR_DEBUGGER_LOCALSTORAGE_KEY,
				},
				'error'
			);
		}
	}
};

/**
 * Get the values out of local storage
 */
export const getSrDebuggerInfo = (): Partial<SrDebuggerInfo> | null => {
	if (!process.client) {
		return null;
	}

	try {
		const srDebuggerInfo = localStorage.getItem(USER_SR_DEBUGGER_LOCALSTORAGE_KEY);
		const debuggerInputs: Partial<SrDebuggerInfo> | null = JSON.parse(srDebuggerInfo ?? 'null');

		if (debuggerInputs == null) return null;

		// Check if testing mode should be enabled
		if (debuggerInputs?.testingMode || Object.keys(debuggerInputs).length !== 0) debuggerInputs.testingMode = true;

		return {
			...debuggerInputs,
			// Check geoCountry just in case local storage was manually edited
			geoCountry: getCountryCode(debuggerInputs?.geoCountry),
		};
	} catch (error: any) {
		captureMessageForSentry(
			'[Get SR debuggers info from localStorage]:',
			{
				error,
				where: '[sponsoredrec-debugging-tools.ts] getSrDebuggerInfo()',
				localStorageKey: USER_SR_DEBUGGER_LOCALSTORAGE_KEY,
			},
			'error'
		);

		return null;
	}
};

/**
 * We want to clear the debugger at the start of every instance of the application (for example: on refresh)
 */
const clearSrDebuggerInfomation = () => {
	if (!process.client) {
		return undefined;
	}

	localStorage.removeItem(USER_SR_DEBUGGER_LOCALSTORAGE_KEY);
};

const isKeepModeEnabled = () => {
	const srDebuggerInfo = getSrDebuggerInfo();

	if (srDebuggerInfo?.hasOwnProperty(FORCE_KEEP_QUERY)) {
		return true;
	}

	return false;
};
