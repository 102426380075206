import Vue from 'vue';
import { debugStorage } from '@/services/DebugStorage';
import { ReadyType } from '@/stores/ready.store';
import BuildConfig from '../../env/build-config.json';
import type { SeverityLevel } from '@sentry/browser';

/** Init Config */
let dsn = JW_CONFIG.SENTRY_DSN;
if (BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
	dsn = JW_CONFIG.SENTRY_DSN_SSR_CLIENT;
}

let release = `jw-app:spa@${BuildConfig.commitShortHash}`;
if (BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
	release = `jw-app:ssr-client@${BuildConfig.commitShortHash}`;
}

// environment string à la: {prod|stage}-{SSR|SPA}-{client|server}
function getEnvironment() {
	const { env } = BUILD_CONFIG; // prod or stage
	const { BUILD_TARGET } = BUILD_CONFIG.globals; // SSR or SPA
	const platform = process.client ? 'client' : 'server'; // server or client

	return `${env}-${BUILD_TARGET}-${platform}`;
}

function getSampleRate() {
	const { env } = BUILD_CONFIG; // prod or stage
	if (debugStorage.getState()) {
		// If user is in debug mode - we send full data.
		return 1.0;
	}
	if (env === 'prod' && BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
		return 0.1;
	}
	if (env === 'prod' && BUILD_CONFIG.globals.BUILD_TARGET === 'SPA') {
		return 0.1;
	}
	return 1.0;
}
/** Init Config */

const setupSentry = async () => {
	if (!dsn) return false;

	const environment = getEnvironment();
	const sampleRate = getSampleRate();

	try {
		const { init, setUser } = await import('@sentry/vue');
		const { getVm } = await import('@/helpers/vm-helper');
		const vm = getVm();

		init({
			Vue,
			dsn,
			environment,
			enabled: process.env.NODE_ENV !== 'development',
			integrations: [],
			maxValueLength: 10000,
			release,
			tracesSampleRate: sampleRate,

			// EVENT SAMPLING
			sampleRate: sampleRate,
		});

		// Sentry User ID
		Vue.$jw.ready?.waitFor(ReadyType.JW_ID).then(async () => {
			const { getVm } = await import('@/helpers/vm-helper');
			const vm = getVm();
			// there is a possibility that we haven't initiated the vue app yet and that makes getVm() return undefined.
			// this is mostly due to failing requests during bootstrap (geolocation, transaltion, ...; for whatever reason) that throw errors.
			setUser({ id: vm?.$store.state.user.jwId });
		});
	} catch (error) {
		close();
		throw new Error(error as any);
	}
};

/** Sentry Init State Machine */
enum STEPS {
	COLD = 0,
	INITIALIZED = 1,
	FAILED = 2,
}
let INIT_STEP: 0 | 1 | 2 = STEPS.COLD;
const initSentry = () => {
	if (!process.client) return false;
	switch (INIT_STEP) {
		case STEPS.COLD:
			try {
				setupSentry();
				INIT_STEP = STEPS.INITIALIZED;
				return true;
			} catch {
				INIT_STEP = STEPS.FAILED;
				return false;
			}
		case STEPS.INITIALIZED:
			return true;
		case STEPS.FAILED:
			return false;
	}
};

/** Sentry Init State Machine */

const captureMessageForSentry = async (
	name: string,
	payload: { [key: string]: any; error?: Error } = {},
	level: SeverityLevel = 'info',
	tag?: { tag: string; value: string }
) => {
	if ((process.env.NODE_ENV === 'development' || JW_CONFIG.DOMAIN === 'moviecycle.com') && payload.error) {
		console.error(`${name}\n`, `${payload.where ?? ''}\n`, payload.error);
	}
	if (!initSentry()) return false;

	const { withScope, captureException, captureMessage } = await import('@sentry/vue');

	withScope(scope => {
		const error = payload['error'];
		const asException = level === 'error' && error;
		if (asException) {
			delete payload['error'];
		}

		scope.setExtra('1. version', process.env.VERSION);
		scope.setExtra('2. message', payload);
		scope.setLevel(level);

		if (tag) scope.setTag(tag.tag, tag.value);

		if (asException) {
			if (error.message && Object.getOwnPropertyDescriptor(error, 'message')?.set != null) {
				error.message = [name, error!.message].join(' ');
			}
			captureException(error);
		} else {
			captureMessage(name);
		}
	});
};

export { captureMessageForSentry, initSentry };
