
import Vue, { type CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Routing = namespace('routing');

@Component({
	name: 'StyleInjector',
	components: {},
})
export default class StyleInjector extends Vue {
	@Routing.State urlMetadata: any;

	get styles() {
		return this.urlMetadata && this.urlMetadata.html_content && this.urlMetadata.html_content.styles
			? this.urlMetadata.html_content.styles
			: null;
	}

	render(h: CreateElement) {
		return h('style', {
			domProps: {
				innerHTML: this.styles,
			},
		});
	}
}
