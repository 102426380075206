import Vue from 'vue';
import ApolloSSR from 'vue-apollo/ssr';

export default {
	beforeCreate({ isServer }) {
		if (isServer) Vue.use(ApolloSSR);
	},

	async sendSSRData(context) {
		const { app, ssr, isServer } = context;
		const { $apolloProvider } = app;

		if (isServer) {
			// Inject store data in __APOLLO_STATE__ on server side
			const apolloState = ApolloSSR.serializeStates($apolloProvider);
			context.apolloState = ApolloSSR.getStates($apolloProvider);

			if (ssr.bodyAdd) {
				ssr.bodyAdd += `<script>window.__APOLLO_STATE__=${apolloState}</script>`;
			} else {
				ssr.bodyAdd = `<script>window.__APOLLO_STATE__=${apolloState}</script>`;
			}
		}
	},
};
