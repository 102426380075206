import { getAxios } from '@/helpers/axios-helper';

import { UserSettings } from '@/stores/user.store';

const WebPlatform = 3 as const;

const getIdServiceVariables = async (jwId: string | null, consents: UserSettings['jw_consents']) => {
	const { hasDoNotTrack } = await await import(/* webpackChunkName: "consent-helper" */ '@/helpers/consent-helper');
	const dnt = hasDoNotTrack(false, consents);
	return { dnt, ids: [jwId].filter(Boolean).join(',') };
};

const updateUserProfile = async (jwId: string, country: string, language: string, pushToken?: string) => {
	const profile = {
		geoip_country: 'XX',
		app_country: country.toUpperCase(),
		device_language: language,
		utc_offset_minutes: new Date().getTimezoneOffset(),
		push_token: pushToken !== undefined ? pushToken : null,
		push_allowed: pushToken !== undefined ? !!pushToken : null,
		token_platform: null,
	};

	// remove null values
	for (const propName in profile) {
		if (profile[propName as keyof typeof profile] === null) {
			delete profile[propName as keyof typeof profile];
		}
	}

	try {
		await getAxios().post(`${JW_CONFIG.ID_URL}/s`, { id: jwId, profile }, { params: { a: WebPlatform } });
	} catch (error: any) {
		const message =
			error.config.url + ': ' + (error.response?.status ?? 'The request was made but no response was received') ||
			error.message;
		throw message;
	}
};

export { getIdServiceVariables, updateUserProfile };
