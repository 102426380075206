import { ActionTree, GetterTree, MutationTree } from 'vuex';

export enum ReadyType {
	CONSENT_PARTNERS_ANSWERED = 'consentPartnersAnswered', // either `true` or `false`, but not `null`—we expect this to only happen once per user session, for simplicity's sake
	CONSTANTS = 'constants',
	EXPERIMENTS_INIT = 'experimentInit',
	EXPERIMENTS_LOADED = 'experimentsLoaded',
	JW_ID = 'jwId',
	LOGIN_FINISHED = 'loginFinished',
	SNOWPLOW_INIT = 'snowplowInit',
	USER_SETTINGS_LOADED = 'userSettingsLoaded',
}

// STATE
const state = () => {
	return [
		ReadyType.CONSENT_PARTNERS_ANSWERED,
		ReadyType.CONSTANTS,
		ReadyType.EXPERIMENTS_INIT,
		ReadyType.EXPERIMENTS_LOADED,
		ReadyType.JW_ID,
		ReadyType.LOGIN_FINISHED,
		ReadyType.SNOWPLOW_INIT,
		ReadyType.USER_SETTINGS_LOADED,
	].reduce((root, key) => {
		root[key] = false;
		return root;
	}, {} as Record<ReadyType, boolean>);
};

export type ReadyState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<ReadyState, any> = {};

// ACTIONS
const actions: ActionTree<ReadyState, any> = {
	setReady({ commit }, readyType: ReadyType) {
		commit('SET_READY', readyType);
	},
};

// MUTATIONS
const mutations: MutationTree<ReadyState> = {
	SET_READY(state, readyType: ReadyType) {
		state[readyType] = true;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
