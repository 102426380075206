import { CCPA_SUBDIVISION, GDPR_WEB_LOCALES } from '@/constants/web-locales.constant';
import type { WebLocale } from '@/enums/web-locale';

import { getUserLocationSubdivision, getUserLocationCountryCode } from '@/helpers/geo-location-helper';

function hasUCConsentBanner(webLocale: WebLocale) {
	if (process.server) return false;

	return GDPR_WEB_LOCALES.includes(webLocale) || hasCCPABanner();
}

function hasCCPABanner(): boolean {
	if (process.server) return false;

	const subdivision = getUserLocationSubdivision() || '';
	return CCPA_SUBDIVISION.includes(subdivision);
}

export { hasUCConsentBanner, hasCCPABanner };
