import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type WatchNowOfferFragment = {
	__typename?: 'Offer';
	id: string;
	standardWebURL?: string | null;
	streamUrl?: string | null;
	retailPrice?: string | null;
	retailPriceValue?: number | null;
	lastChangeRetailPriceValue?: number | null;
	currency?: string | null;
	presentationType: Types.PresentationType;
	monetizationType: Types.MonetizationType;
	availableTo?: any | null;
	package: {
		__typename?: 'Package';
		id: string;
		icon: string;
		packageId: number;
		clearName: string;
		shortName: string;
		technicalName: string;
		iconWide: string;
		hasRectangularIcon: boolean;
	};
};

export const WatchNowOfferFragmentDoc = gql`
	fragment WatchNowOffer on Offer {
		id
		standardWebURL
		streamUrl
		package {
			id
			icon
			packageId
			clearName
			shortName
			technicalName
			iconWide
			hasRectangularIcon(country: $country, platform: WEB)
		}
		retailPrice(language: $language)
		retailPriceValue
		lastChangeRetailPriceValue
		currency
		presentationType
		monetizationType
		availableTo
	}
`;
