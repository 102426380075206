import Vue, { PluginObject } from 'vue';

// Interface for plugin implementation definitions
export interface JwPlugins {}

declare module 'vue/types/vue' {
	interface VueConstructor {
		$jw: JwPlugins;
	}
}

export interface Options {}

export default {
	install(Vue, _options) {
		Vue.$jw = {};
	},
} as PluginObject<Options>;
