import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';

// STATE
const state = () => ({
	/**
	 * whether constants and router has been loaded.
	 */
	invitationPlacement: '' as string,
});

type State = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<State, any> = {
	getInvitationPlacement(state): string {
		return state.invitationPlacement;
	},
};

// ACTIONS
const actions: ActionTree<State, any> = {
	setInvitationPlacement({ commit }, value: string) {
		commit('SET_INVITATION_PLACEMENT', value);
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {
	SET_INVITATION_PLACEMENT(state, value: string) {
		state.invitationPlacement = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
