import { MonetizationType } from '@/@types/graphql-types';
import { ROLE } from '@/enums/person';
import type { FormattedScoring } from '@/helpers/scoring-helper';
import type { SeenSeason } from '@/helpers/watchlist-helper';

export enum TitleObjectType {
	MOVIE = 'movie',
	SHOW = 'show',
	SEASON = 'show_season',
	EPISODE = 'show_episode',
	PERSON = 'person',
	LEAVING_SOON = 'leaving_soon',
	SPORTS_GAME = 'sports_game',
}

export enum PopularTabType {
	MOVIE = 'movie',
	SHOW = 'show',
	ALL = 'overview',
}

// model of a title loaded from the popular endpoint
export interface PopularTitle {
	age_certification: string;
	cinema_release_date: string;
	cinema_release_week: string;
	full_path: string;
	full_paths: { [key: string]: string };
	id: number;
	is_nationwide_cinema_release_date: boolean;
	jw_entity_id: string;
	localized_release_date: string;
	object_type: TitleObjectType;
	original_language: string;
	original_release_year: number;
	original_title: string;
	offers?: Offer[]; // @TODO added for the titles returned by full watchlist but must be seperate type
	poster: string;
	pagination_token: string;
	runtime: number;
	short_description: string;
	original_short_description: string;
	title: string;
	credits?: Credit[];
	tracking_token?: string;
	season_number?: undefined; // trello#274: this property is not on PopularTitle response object, but for typescript property check
	hideTitle?: boolean;
}

// model of a title loaded using the new endpoint
// TODO Why are we having 2 interfaces for one entity? Also 3 years old hardly qualifies as "new" :).
export interface NewTitle {
	full_path: string;
	id: number;
	jw_entity_id: string;
	object_type: TitleObjectType;
	offers: Offer[];
	original_release_year?: number;
	original_title: string;
	poster: string;
	season_number?: number;
	seen?: boolean;
	show_id?: number;
	show_title?: string;
	title: string;
	tracking_token?: string;
	newElementCount?: number; // used for new timeline badges "2 new episodes"
	watch_now?: { offer: Offer };
	pagination_token?: string;
	episode_number?: number;
	runtime?: number;
	hideTitle?: boolean;
}

// detail page model of a title
export interface Title extends TitleResponse {
	list_entry_created_at?: string;
}

// interface for a single title response
export interface TitleResponse {
	age_certification: string;
	all_titles: string[];
	backdrops: Backdrop[];
	cinema_release_date: string;
	cinema_release_week: string;
	clips: Clip[];
	credits: Credit[];
	episodes: Episode[];
	episode_number?: number;
	external_ids: ExternalId[];
	full_path: string;
	full_paths: { [key: string]: string };
	genre_ids?: number[];
	id: number;
	is_nationwide_cinema_release_date: boolean;
	jw_entity_id: string;
	localized_release_date: string;
	max_season_number: number;
	object_type: TitleObjectType; // show, movie
	offers: Offer[];
	original_language: string;
	original_release_year?: number;
	original_short_description: string;
	original_title: string;
	poster: string;
	pagination_token?: string;
	production_countries: string[];
	runtime: number;
	scoring?: Score[];
	seasons?: Season[];
	season_number?: number;
	seen?: boolean;
	seen_seasons?: SeenSeason[];
	short_description: string;
	show_id?: number;
	show_title?: string;
	title: string;
	tmdb_popularity: number;
	watch_now?: { offer: Offer };
	permanent_audiences?: string[];
	upcoming?: TitleUpcomingData[];
}

export interface NewWatchlistTitleCommon {
	full_path: string;
	full_paths: { [key: string]: string };
	genre_ids: number[];
	id: number;
	jw_entity_id: string;
	object_type: TitleObjectType;
	original_release_year: number;
	poster: string;
	backdrops: Backdrop[];
	scoring: FormattedScoring[];
	short_description: string;
	title: string;
	tmdb_popularity: number;
	pagination_token?: string;
	external_ids?: ExternalId[];
}

export interface NewWatchlistTvShowTitle extends NewWatchlistTitleCommon, NewWatchlistTitleEpisodeCount {
	seasons?: Season[];
}

export interface NewWatchlistMovieTitle extends NewWatchlistTitleCommon {
	offers: Offer[];
	watch_now: Offer;
}

export type NewWatchlistTitle = NewWatchlistTvShowTitle & NewWatchlistMovieTitle;

export interface NewWatchlistTitleEpisodeCount {
	remaining_season_episode_count?: number;
	seen_episode_count?: number;
	total_episode_count?: number;
	total_season_count?: number;
	last_seen_episode_number?: number;
	last_seen_season_number?: number;
	earliest_release_time?: string;
}

export interface Episode {
	episode_number: number;
	id: number;
	jw_entity_id: string;
	object_type: TitleObjectType.EPISODE;
	offers: Offer[];
	season_number: number;
	show_id: number;
	show_title: string;
	title: string;
	watch_now?: { offer: Offer };
	poster?: string;
	runtime?: number;
	short_description?: string;
	original_short_description?: string;
	upcoming?: TitleUpcomingData[];
}

export interface Season {
	full_path: string;
	object_type: TitleObjectType.SEASON;
	id: number;
	poster: string;
	season_number: number;
	show_title: string;
	show_id?: number;
	episodes?: Episode[];
	upcoming?: TitleUpcomingData[];
}

export interface TitleUpcomingData {
	package_id: number;
	release_window_from: string;
	release_window_to: string;
}

export interface Score {
	provider_type: string;
	value: number;
}

export interface ExternalId {
	external_id: string;
	provider: string;
}

export interface Backdrop {
	backdrop_url: string;
}

export interface Credit {
	character_name?: string; // only defined for roles
	name: string;
	person_id: number;
	role: ROLE; // DIRECTOR, ACTOR, PRODUCER, PRODUCTION_DESIGN, SCREENPLAY …
}

export enum ClipProvider {
	YOUTUBE = 'youtube',
}

export interface Clip {
	external_id: string;
	height: number;
	name: string;
	provider: ClipProvider;
	type: string;
}

export interface URLSet {
	deeplink_android?: string;
	deeplink_ios?: string;
	deeplink_rokuos?: string;
	deeplink_web?: string;
	standard_season?: string;
	standard_show?: string;
	standard_web: string;
}

// @todo not happy that there are OfferMonetizationType and OfferMonetizationTypes
export const enum OfferMonetizationType {
	ADS = 'ads',
	BUY = 'buy',
	FLATRATE = 'flatrate',
	RENT = 'rent',
	// STREAM = 'stream',
	FREE = 'free',
	FLATRATE_AND_BUY = 'flatrate_and_buy',
	BUNDLE = 'bundle', // BUYBOX_BUNDLE: add bundle offer, so we can correctly show promoted_bundle in the buybox
	CINEMA = 'cinema',
}

interface OfferMonetizationTypeInterface {
	label: string;
	priority: number; // might be used for sorting
	value: OfferMonetizationType;
	visible: boolean; // false value should not be shown when possible
}

interface OfferMonetizationTypeInterfaceGraphql {
	label: string;
	priority: number; // might be used for sorting
	value: MonetizationType;
	visible: boolean; // false value should not be shown when possible
}

export const OfferMonetizationTypes: OfferMonetizationTypeInterface[] = [
	{
		label: 'WEBAPP_ADS',
		priority: 2000,
		value: OfferMonetizationType.ADS,
		visible: true,
	},
	{
		label: 'WEBAPP_BUY',
		priority: 4000,
		value: OfferMonetizationType.BUY,
		visible: true,
	},
	{
		label: 'WEBAPP_FLATRATE',
		priority: 3000,
		value: OfferMonetizationType.FLATRATE,
		visible: true,
	},
	{
		label: 'WEBAPP_FLATRATE',
		priority: 3000,
		value: OfferMonetizationType.FLATRATE_AND_BUY,
		visible: false,
	},
	{
		label: 'WEBAPP_RENT',
		priority: 4000,
		value: OfferMonetizationType.RENT,
		visible: true,
	},
	{
		label: 'WEBAPP_FREE',
		priority: 1000,
		value: OfferMonetizationType.FREE,
		visible: true,
	},
];

export const OfferMonetizationTypesGraphql: OfferMonetizationTypeInterfaceGraphql[] = [
	{
		label: 'WEBAPP_ADS',
		priority: 2000,
		value: MonetizationType.Ads,
		visible: true,
	},
	{
		label: 'WEBAPP_BUY',
		priority: 4000,
		value: MonetizationType.Buy,
		visible: true,
	},
	{
		label: 'WEBAPP_FLATRATE',
		priority: 3000,
		value: MonetizationType.Flatrate,
		visible: true,
	},
	{
		label: 'WEBAPP_FLATRATE',
		priority: 3000,
		value: MonetizationType.FlatrateAndBuy,
		visible: false,
	},
	{
		label: 'WEBAPP_RENT',
		priority: 4000,
		value: MonetizationType.Rent,
		visible: true,
	},
	{
		label: 'WEBAPP_FREE',
		priority: 1000,
		value: MonetizationType.Free,
		visible: true,
	},
];

export const enum OfferPresentationType {
	_4K = '4k',
	BLURAY = 'bluray',
	CANVAS = 'canvas',
	DVD = 'dvd',
	HD = 'hd',
	SD = 'sd',
	FREE = 'free',
}

// list of presentation types actually returned by the backend
export const OfferPresentationTypes = [
	{
		value: OfferPresentationType.SD,
		label: 'SD',
		isQuality: true,
	},
	{
		value: OfferPresentationType.HD,
		label: 'HD',
		isQuality: true,
	},
	{
		value: OfferPresentationType.DVD,
		label: 'DVD',
	},
	{
		value: OfferPresentationType.BLURAY,
		label: 'Blu-ray',
	},
	{
		value: OfferPresentationType._4K,
		label: '4K',
		isQuality: true,
	},
];

export interface Offer {
	currency?: string;
	element_count?: number;
	last_change_date_provider_id?: string;
	last_change_date?: string;
	last_change_difference?: number;
	last_change_percent?: number;
	last_change_retail_price?: number;
	monetization_type: OfferMonetizationType;
	new_element_count?: number;
	original_currency?: string;
	original_retail_price?: number;
	presentation_type: OfferPresentationType;
	provider_id: number;
	retail_price?: number;
	country?: string;
	type?: string;
	urls: URLSet;
	jw_entity_id?: string;
	bundle_id?: number;
	bundle_technical_name?: string;
	bundle_icon_url?: string;
}
