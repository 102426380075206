import { CollectionType } from '@/enums/collection-type';
import { getSportCategoryMap, isSportSupported, sportAliases, SportRouteTypes, sportTranslated } from '@/enums/sports';

import type { SportType } from '@/@types/graphql-types';
import type { SportCategory, SportLocale } from '@/enums/sports';
import type { WebLocale } from '@/enums/web-locale';
import type { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import type { Store } from 'vuex';

// MultiStep Pages
import type MotorsportCompetition from '@/pages/sports/motorsport/MotorsportCompetition.vue';
import type MotorsportEvent from '@/pages/sports/motorsport/MotorsportEvent.vue';
import type MotorsportOverview from '@/pages/sports/motorsport/MotorsportOverview.vue';

// SingleStep Pages
import type SingleStepCompetition from '@/pages/sports/single-step/SingleStepCompetition.vue';
import type SingleStepEvent from '@/pages/sports/single-step/SingleStepEvent.vue';
import type SingleStepOverview from '@/pages/sports/single-step/SingleStepOverview.vue';
import type SingleStepTeam from '@/pages/sports/single-step/SingleStepTeam.vue';
import { PageType } from './config';

/*
	This route config generates all the Sport routes per locale.
	The point was to have a typesafe way to create all routes (thanks to `src/enums/sports.ts`) and
	to expose a `$route.meta.sport` property that can be used in the app.

	With this property, we do not need to wait for data to load before knowing the "objectType" as
	is the case in `TitleDetail` for example.

	We couldn't just use route's dynamic parts like `/de/:sport` and then `$route.params.sport`
	because the routes get translated and we would have too many routes clashing:
	`/${webLocale}/*` -> All sports, each sport, static pages

	For more info see: https://gitlab.justwatch.com/jw/jw-app/-/wikis/Sports-FE-Architecture

	## Example routes for "DE"

	All Sports (app.sports.all)
	* /de/sport

	Overview (app.sports.overview)
	* /de/fussball
	* /de/motorsport
	* ... for each sport listed in `src/enums/sports.ts:sportAliases`

	Competition (app.sports.competition)
	* /de/fussball/:competition
	* /de/motorsport/:competition
	* ... for each sport listed in `src/enums/sports.ts:sportAliases`

	Event (app.sports.event)
	* /de/fussball/:competition/:event
	* /de/motorsport/:competition/:event
	* ... for each sport listed in `src/enums/sports.ts:sportAliases`
*/

type beforeEnterArgs<T extends Vue = Vue> = [to: Route, from: Route, next: NavigationGuardNext<T>];

const baseMeta = {
	tab: 'sports',
	collectionType: CollectionType.SPORTS,
	pageType: PageType.SPORTS,
};

const beforeEnter = async (to: Route, from: Route, next: NavigationGuardNext, store: Store<any>) => {
	// Fetch sports translations if it's the first time navigating into Sports.
	if (from.name != null && !from.name.startsWith('app.sports')) {
		await store.dispatch('language/fetchSportsTranslation');
	}
	if (to.name?.startsWith('app.sports')) {
		// sports relies heavily on locales, which is kinda expensive for every render (12.5kb gzipped, ~50ms request).
		// that's why we only load it on sports.
		// all other areas, that depend on it, load it dynamically (e.g. SettingsModal).
		await store.dispatch('language/loadLocales');
	}
	next();
};

function makeAllSportsRoute(currentLocale: SportLocale, store: Store<any>): RouteConfig {
	return {
		name: 'app.sports.all',
		path: `/${currentLocale}/${encodeURI(sportTranslated[currentLocale])}`,
		component: () => import('@/pages/sports/AllSports.vue'),
		meta: { ...baseMeta, data: 'all' },
		beforeEnter: (...args) => beforeEnter(...args, store),
	};
}

function makeOverviewRoutes(currentLocale: SportLocale, store: Store<any>): RouteConfig[] {
	// /uk/{sport} -> /uk/football
	const singleSport = SportRouteTypes.sports
		.filter(sport => sportAliases[sport][currentLocale])
		.map(
			sport =>
				({
					name: 'app.sports.overview',
					// @ts-ignore
					path: `/${currentLocale}/${encodeURI(sportAliases[sport][currentLocale])}`,
					component: () => import('@/pages/sports/single-step/SingleStepOverview.vue'),
					meta: { ...baseMeta, data: 'overview.singlestep', sport, contexts: ['filter'] },
					beforeEnter: (...args: beforeEnterArgs<SingleStepOverview>) => beforeEnter(...args, store),
				} as RouteConfig)
		);

	// /uk/{category} -> /uk/motorsport
	const sportCategory = (<SportCategory[]>Object.keys(SportRouteTypes.categories))
		.filter(category => sportAliases[category][currentLocale])
		.map(
			category =>
				({
					name: 'app.sports.overview',
					// @ts-ignore
					path: `/${currentLocale}/${encodeURI(sportAliases[category][currentLocale])}`,
					component: () => import('@/pages/sports/motorsport/MotorsportOverview.vue'),
					meta: { ...baseMeta, data: 'overview.multistep', sport: category, contexts: ['filter'] },
					beforeEnter: (...args: beforeEnterArgs<MotorsportOverview>) => beforeEnter(...args, store),
				} as RouteConfig)
		);

	return [...singleSport, ...sportCategory];
}

function makeMultistepCompetitionRoutes(currentLocale: SportLocale, store: Store<any>): RouteConfig[] {
	return (<[SportCategory, SportType[]][]>Object.entries(SportRouteTypes.categories))
		.map(([category, sports]) => {
			return sports
				.filter(sports => sportAliases[sports][currentLocale])
				.map(
					sport =>
						({
							// /uk/{category}/{competition} -> /uk/motorsport/formula-1
							name: 'app.sports.competition',
							// @ts-ignore
							path: `/${currentLocale}/${encodeURI(sportAliases[category][currentLocale])}/${
								sportAliases[sport][currentLocale]
							}`,
							component: () => import('@/pages/sports/motorsport/MotorsportCompetition.vue'),
							meta: { ...baseMeta, data: 'competition.multistep', sport, contexts: ['filter'] },
							beforeEnter: (...args: beforeEnterArgs<MotorsportCompetition>) =>
								beforeEnter(...args, store),
						} as RouteConfig)
				);
		})
		.flat();
}

function makeTeamOrCompetitionRoutes(currentLocale: SportLocale, store: Store<any>): RouteConfig[] {
	return SportRouteTypes.sports
		.filter(sports => sportAliases[sports][currentLocale])
		.map(
			sport =>
				({
					// /uk/{sport}/{team} -> /uk/football/manchester-united
					name: 'app.sports.team|competition',
					// @ts-ignore
					path: `/${currentLocale}/${encodeURI(sportAliases[sport][currentLocale])}/:team`,
					component: () => import('@/pages/sports/single-step/SingleStepTeamOrCompetition.vue'),
					meta: { ...baseMeta, data: 'team|competition.singlestep', sport, contexts: ['filter'] },
					beforeEnter: (...args: beforeEnterArgs<SingleStepTeam | SingleStepCompetition>) =>
						beforeEnter(...args, store),
				} as RouteConfig)
		);
}

function makeEventRoutes(currentLocale: SportLocale, store: Store<any>): RouteConfig[] {
	// /uk/{sport}/{competition}/{eventName} -> /uk/tennis/french-open-men-singles/pablo-carreno-busta-gilles-simon-2022
	const singleSport = SportRouteTypes.sports
		.filter(sports => sportAliases[sports][currentLocale])
		.map(
			sport =>
				({
					name: 'app.sports.event',
					// @ts-ignore
					path: `/${currentLocale}/${encodeURI(sportAliases[sport][currentLocale])}/:competition/:event`,
					component: () => import('@/pages/sports/single-step/SingleStepEvent.vue'),
					meta: { ...baseMeta, data: 'event.singlestep', sport },
					beforeEnter: (...args: beforeEnterArgs<SingleStepEvent>) => beforeEnter(...args, store),
				} as RouteConfig)
		);

	// /uk/{category}/{competition}/{event} -> /uk/motorsport/formula-1/dutch-grand-prix-2022
	const sportCategory = (<[SportCategory, SportType[]][]>Object.entries(SportRouteTypes.categories))
		.map(([category, sports]) => {
			return sports
				.filter(sports => sportAliases[sports][currentLocale])
				.map(
					sport =>
						({
							name: 'app.sports.event',
							// @ts-ignore
							path: `/${currentLocale}/${encodeURI(sportAliases[category][currentLocale])}/${
								sportAliases[sport][currentLocale]
							}/:competition`,
							component: () => import('@/pages/sports/motorsport/MotorsportEvent.vue'),
							meta: { ...baseMeta, data: 'event.multistep', sport },
							beforeEnter: (...args: beforeEnterArgs<MotorsportEvent>) => beforeEnter(...args, store),
						} as RouteConfig)
				);
		})
		.flat();

	return [...singleSport, ...sportCategory];
}

export function makeSportRoutes(locale: WebLocale, store: Store<any>) {
	if (!isSportSupported(locale)) return [];

	return [makeAllSportsRoute(locale, store)].concat(
		makeOverviewRoutes(locale, store),
		makeTeamOrCompetitionRoutes(locale, store),
		makeMultistepCompetitionRoutes(locale, store),
		makeEventRoutes(locale, store)
	);
}

/**
 * Get the URL to a sport or category's overview page. It's simple for
 * non category sports like football but more complicated for categories
 * and their competitions (i.e. Motorsport and Formula 1).
 *
 * @param sport The sport or category for which you want the URL
 * @param currentLocale
 * @returns The URL to the given sport's overview page
 */
export function getOverviewUrlBySport(sport: SportType | SportCategory, currentLocale: SportLocale) {
	if (sport in SportRouteTypes.categories || SportRouteTypes.sports.includes(sport as SportType)) {
		return `/${currentLocale}/${sportAliases[sport][currentLocale]}`;
	}

	const category = getSportCategoryMap()[sport as SportType];
	// this is mainly to catch sports that were implemented but not yet released
	if (category == null) return '';

	return `/${currentLocale}/${sportAliases[category][currentLocale]}/${sportAliases[sport][currentLocale]}`;
}
