export function disableScroll() {
	if (process.client) {
		document.body.style.overflowY = 'hidden';
	}
}

export function enableScroll() {
	if (process.client) {
		document.body.style.overflowY = 'auto';
	}
}
