export const shallowDifference = (obj1: any, obj2: any) =>
	!obj1 || !obj2 || JSON.stringify(obj1) !== JSON.stringify(obj2);

export const arrayOfStringsAreEquals = (arry1: string[] = [], arr2: string[] = []) =>
	JSON.stringify([...arry1].sort()) === JSON.stringify([...arr2].sort());

export const arraysAreEquals = (arry1: any[] = [], arr2: any[] = []) =>
	JSON.stringify([...arry1].sort()) === JSON.stringify([...arr2].sort());

export const shuffleArray = <T>(array: T[]) => {
	const arrayToShuffle = [...array];

	for (let i = arrayToShuffle.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arrayToShuffle[i], arrayToShuffle[j]] = [arrayToShuffle[j], arrayToShuffle[i]];
	}

	return arrayToShuffle;
};

export const cloneDeep = <O>(object: O): O => JSON.parse(JSON.stringify(object));

// helper function similar to underscore’s pluck
export const pluck = <T>(values: T[], by: keyof T) => {
	return values.reduce((previousValue, currentValue) => {
		const key: keyof T = currentValue[by] as unknown as keyof T;
		previousValue[key] = currentValue;
		return previousValue;
	}, {} as Record<keyof T, T>);
};

export const isEmptyArray = (value: any) => !!value && Array.isArray(value) && value.length === 0;

export const deleteEmptyPropsFromObject = (obj: any) => {
	Object.keys(obj).forEach((key: any) => deleteEmptyProp(obj, key));
};

const deleteEmptyProp = (obj: any, key: string | number) => {
	return (obj[key] === null || isEmptyArray(obj[key]) || isEmptyObject(obj[key])) && delete obj[key];
};

const isEmptyObject = (value: any) => {
	return value && !isPrimitive(value) && Object.entries(value).length === 0;
};

const isPrimitive = (arg: any) => {
	const type = typeof arg;
	return arg == null || (type != 'object' && type != 'function');
};

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
	arr.reduce((groups, item) => {
		(groups[key(item)] ||= []).push(item);
		return groups;
	}, {} as Record<K, T[]>);
