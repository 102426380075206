import VueI18n from 'vue-i18n';
import type { Route } from 'vue-router';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import type { WebLocales } from '@/constants/web-locales.constant';
import {
	COUNTRY_EXCEPTIONS,
	LANG_COUNTRY_MAPPING,
	WEB_LOCALES,
	WEB_LOCALES_EMOJIS,
} from '@/constants/web-locales.constant';

import { WebLocale } from '@/enums/web-locale';

import { getAxiosCached } from '@/helpers/axios-helper';
import { FallbackLocale, getActiveLocales, Locale } from '@/helpers/locale-helper';
import { getVm } from '@/helpers/vm-helper';

import { transformTranslations } from '@/helpers/language-helpers';
import { FreezingItems } from '@/store';

export const LANGUAGE_INIT_FREEZE_ITEMS: FreezingItems<LanguageState> = {
	locales: state => {
		state.locales.forEach(locale => Object.freeze(locale));
	},
	localeMessage: state => {
		Object.freeze(state.localeMessage);
	},
};

const SPORTS_TRANSLATION_PREFIX = 'SPORTS_WEBAPP_';
const STREAMING_TRANSLATION_PREFIX = 'WEBAPP_';

export const TTB_LANGUAGES = ['ja', 'ko', 'zh'];

// STATE
const state = () => ({
	webLocale: WebLocale.us, // default value now, will be overwritten during store initialisation in store.ts
	overriddenLanguage: '', // default value now, will be overwritten during store initialisation in store.ts
	initialized: false,
	detectedWebLocale: null as WebLocale | null, // coming from geo location lookups
	locales: [] as Locale[],
	localeMessage: {} as any,
	audioLanguage: '',
});

type LanguageState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<LanguageState, any> = {
	language: state => {
		// If the language is supported in LANG_COUNTRY_MAPPING return that
		if (LANG_COUNTRY_MAPPING[state.overriddenLanguage ?? ''] != null) {
			return state.overriddenLanguage;
		}

		// Otherwise try and get the language from the weblocale
		if (WEB_LOCALES[state.webLocale] != null) {
			return WEB_LOCALES[state.webLocale].substring(0, 2).toLowerCase();
		}

		// if all else fails, default to english
		return 'en';
	},
	isTTBLanguage: (_, getters) => TTB_LANGUAGES.includes(getters.language),
	country: state =>
		state.webLocale && WEB_LOCALES[state.webLocale] ? WEB_LOCALES[state.webLocale].substr(-2).toUpperCase() : 'US',
	countryEmoji: state =>
		state.webLocale && WEB_LOCALES_EMOJIS[state.webLocale] ? WEB_LOCALES_EMOJIS[state.webLocale] : null,
	locale: state => (state.webLocale && WEB_LOCALES[state.webLocale] ? WEB_LOCALES[state.webLocale] : 'en_US'),
	localeObject: state =>
		state.locales.filter(locale => locale.webLocale === state.webLocale).find(Boolean) ?? FallbackLocale,
	overriddenLocale: (state, getters) =>
		state.overriddenLanguage ? `${state.overriddenLanguage}_${getters.country}` : getters.locale,
	activeLocalesList: (state): WebLocales =>
		state.locales.length
			? (state.locales.reduce((arr: { [key: string]: string }, entry) => {
					const { webLocale, full_locale } = entry;
					arr[webLocale] = full_locale;
					return arr;
			  }, {}) as WebLocales)
			: WEB_LOCALES,
};

// ACTIONS
const actions: ActionTree<LanguageState, any> = {
	/**
	 * this causes brings the whole startup initialization of constants and language to life (via watcher in main.ts).
	 */
	async changeWebLocale({ commit, dispatch, rootState }, webLocale: WebLocale) {
		if (!rootState.filter[webLocale]) {
			// init filter here before setting webLocale for reactivity reason (webLocale is watched everywhere)
			dispatch('filter/initFiltersPerLocale', webLocale, { root: true });
		}

		commit('SET_WEBLOCALE', webLocale);
	},

	setPersistentWebLocale({ commit }, webLocale: WebLocale) {
		commit('user/SET_PERSISTED_WEB_LOCALE', webLocale, { root: true });
	},

	async setLanguageWhenUserDidNot({ dispatch, rootState }, language: string) {
		if (rootState.user.persistedOverriddenLanguage) {
			return;
		}
		await dispatch('setLanguage', language);
	},

	async fetchTranslation(
		{ commit, getters },
		{ language, webLocale, activeRoute }: { language: string; webLocale?: WebLocale; activeRoute: Route }
	) {
		const _webLocale = webLocale?.toUpperCase() ?? getters.country;
		const apiUrl = `${JW_CONFIG.API}/translations/${language}_${_webLocale}`;

		const params = { prefix: STREAMING_TRANSLATION_PREFIX, translatable_type: 'ui_string' };

		const fetchPromises = [(await getAxiosCached()).get(apiUrl, { params })];
		if (activeRoute?.name?.startsWith('app.sports') || activeRoute?.name?.startsWith('app.home')) {
			params.prefix = SPORTS_TRANSLATION_PREFIX;
			fetchPromises.push((await getAxiosCached()).get(apiUrl, { params }));
		}

		const [jwApp, jwSports] = (await Promise.all(fetchPromises)).map(({ data }) => transformTranslations(data));

		// @note: check into performance of adding so many entries that might cause perf problems
		// with creation and deletion of its reactivity?
		// haven't tested anything 🤷‍♀️
		commit('SET_LOCALE_MESSAGE', { localeMessage: Object.assign(jwApp, jwSports) });
	},

	async fetchSportsTranslation({ state, commit, getters }) {
		// check of the sports translations are already loaded
		if (Object.keys(state.localeMessage).some(key => key.startsWith(SPORTS_TRANSLATION_PREFIX))) return;
		const locale = state.webLocale?.toUpperCase() ?? getters.country;
		const url = `${JW_CONFIG.API}/translations/${getters.language}_${locale}`;
		const params = { prefix: SPORTS_TRANSLATION_PREFIX, translatable_type: 'ui_string' };

		const { data } = await (await getAxiosCached()).get(url, { params });

		commit('APPEND_LOCALE_MESSAGE', { localeMessage: transformTranslations(data) });
		// the transations object is non-reactive so it needs to be updated manually
		getVm().$i18n.setLocaleMessage(getters.language, state.localeMessage);
	},

	async setLanguage({ commit, dispatch, state }, { language, i18n }: { language: string; i18n: VueI18n }) {
		if (!language) {
			return false;
		}
		commit('SET_LANGUAGE', language);

		if (global.VUE_APP_TESTS_E2E) {
			i18n.setLocaleMessage(language, {});
		} else {
			i18n.setLocaleMessage(language, state.localeMessage);
		}

		i18n.locale = language;

		dispatch('meta/setAppDirection', { language }, { root: true });
	},

	setPreferredAudioLanguage({ commit }, { language }): void {
		commit('SET_AUDIO_LANGUAGE', language);
	},

	async fetchTranslationAndSetLanguage(
		{ commit, state, dispatch, getters },
		{ language, i18n, activeRoute }: { language: string; i18n: VueI18n; activeRoute: Route }
	) {
		if (Object.keys(state.localeMessage).length === 0) {
			await dispatch('fetchTranslation', {
				language,
				webLocale: getters.country,
				activeRoute,
			});
		}

		if (global.VUE_APP_TESTS_E2E) {
			i18n.setLocaleMessage(language, {});
		} else {
			i18n.setLocaleMessage(language, state.localeMessage);
		}

		i18n.locale = language;

		commit('SET_LANGUAGE', language);
	},

	async loadLocales({ state, commit }) {
		if (state.locales.length === 0) {
			const locales = await getActiveLocales();
			commit('SET_WEBLOCALES', locales);
		}
	},

	setDetectedWebLocale({ commit, dispatch }, locale) {
		const webLocale = COUNTRY_EXCEPTIONS[locale] ? COUNTRY_EXCEPTIONS[locale] : locale;
		commit('SET_DETECTED_WEBLOCALE', webLocale as WebLocale);
		if (WEB_LOCALES[webLocale as WebLocale]) {
			dispatch('changeWebLocale', webLocale);
		}
	},

	setLanguageFromLocale({ commit }, webLocale: WebLocale) {
		const isLocaleAvailable = WEB_LOCALES[webLocale];
		if (isLocaleAvailable) {
			const language = isLocaleAvailable.substr(0, 2);
			if (LANG_COUNTRY_MAPPING[language]) {
				commit('SET_DETECTED_LANGUAGE', language);
			}
		}
	},

	setDetectedLanguage({ commit }, language: string) {
		if (!LANG_COUNTRY_MAPPING[language]) {
			return commit('SET_DETECTED_LANGUAGE', 'en');
		}
		commit('SET_DETECTED_LANGUAGE', language);
	},

	async setWebLocale({ commit, dispatch }, webLocale: WebLocale) {
		await dispatch('changeWebLocale', webLocale);

		await dispatch('setPersistentWebLocale', webLocale);
	},
};

// MUTATIONS
const mutations: MutationTree<LanguageState> = {
	SET_WEBLOCALE(state, webLocale: WebLocale) {
		state.webLocale = webLocale;
	},
	SET_LANGUAGE(state, language: string) {
		state.overriddenLanguage = language;
		state.initialized = true; // @todo check if we need this?
	},
	SET_AUDIO_LANGUAGE(state, language: string) {
		state.audioLanguage = language;
	},
	SET_DETECTED_WEBLOCALE(state, webLocale: WebLocale) {
		state.detectedWebLocale = webLocale;
	},
	SET_DETECTED_LANGUAGE(state, webLocale: WebLocale) {
		state.overriddenLanguage = webLocale;
	},
	SET_WEBLOCALES(state, webLocales: Locale[]) {
		state.locales = webLocales;
	},
	SET_LOCALE_MESSAGE(state, { localeMessage }) {
		state.localeMessage = localeMessage;
	},
	APPEND_LOCALE_MESSAGE(state, { localeMessage }) {
		state.localeMessage = { ...state.localeMessage, ...localeMessage };
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<LanguageState, any>;
