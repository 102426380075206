import axios from 'axios';

const revenueCatAPI = axios.create({
	baseURL: `${JW_CONFIG.REVENUE_CAT_API_URL}/subscribers`,
});

revenueCatAPI.interceptors.request.use(
	async config => {
		config.headers = {
			Authorization: `Bearer ${JW_CONFIG.REVENUE_CAT_PUBLIC_KEY}`,
		};
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

export default revenueCatAPI;
