import { ApolloError, isApolloError } from 'apollo-client';

export function toApolloError(error: unknown): ApolloError {
	if (!(error instanceof Error)) {
		return new ApolloError({
			networkError: Object.assign(new Error(), { originalError: error }),
			errorMessage: String(error),
		});
	}

	if (isApolloError(error)) {
		return error;
	}

	return new ApolloError({ networkError: error, errorMessage: error.message });
}

// @note `errors: GraphQLErrors` set to any: not possible to use `GraphQLErrors`, it doesn't exist in this apollo version yet.
export function resultErrorsToApolloError(errors: any): ApolloError {
	return new ApolloError({
		graphQLErrors: errors,
		errorMessage: `GraphQL response contains errors: ${errors.map((e: any) => e.message).join(' | ')}`,
	});
}
