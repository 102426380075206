import Vue from 'vue';

import { ReadyType } from '@/stores/ready.store';
import { SnowplowContext } from './snowplow-contexts';
import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';
import { getUtmSearchParams } from '@/helpers/composables/useArbitrage';

let lastPath = '';

const DOMAIN = `https://www.${JW_CONFIG.DOMAIN}`;

async function tracker(...args: any) {
	if (typeof window !== 'undefined') {
		await Vue.$jw.ready?.waitFor(ReadyType.SNOWPLOW_INIT);

		try {
			window.snowplow(...args);
		} catch (err) {
			console.error('[snowplow] function not found in window object', err);
		}
	}
}

const attachSnowplow = (p: any, l: any, o: any, w: any, i: any, n?: any, g?: any) => {
	if (!p[i]) {
		p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
		p.GlobalSnowplowNamespace.push(i);
		p[i] = function () {
			(p[i].q = p[i].q || []).push(arguments);
		};
		p[i].q = p[i].q || [];
		n = l.createElement(o);
		g = l.getElementsByTagName(o)[0];
		n.async = 1;
		n.defer = 1;
		n.src = w;
		g.parentNode.insertBefore(n, g);
	}
};

interface SnowplowConfig {
	userId: string;
	appId: string;
	cookieDomain: string;
	platform: 'web' | 'mob';
	doNotTrack: boolean;
	eventMethod: string;
	postPath: string;
}

export class TrackingProviderSnowplow extends TrackingProvider {
	dataProcessingService = null;

	constructor(private config: SnowplowConfig) {
		super();

		// config validation
		if (!this.config.appId) {
			throw new Error('Expected "config.appId"');
		}
		if (!this.config.cookieDomain) {
			throw new Error('Expected "config.cookieDomain"');
		}
	}

	initialize() {
		// failsafe for SSR
		if (typeof window === 'undefined') {
			return Promise.resolve();
		}

		return new Promise<void>(async (resolve, reject) => {
			// ignore config.doNotTrack; it's first party data
			const splib = `/${ASSETS_DIR}/js/sp-3.8.0.lite.min.js`;

			if (!('snowplow' in window)) {
				attachSnowplow(window, document, 'script', splib, 'snowplow');
			}

			tracker('newTracker', 'cf', `${JW_CONFIG.SNOWPLOW_COLLECTOR_ENDPOINT}`, this.config);

			tracker('setUserId', this.config.userId);

			Vue.$jw.ready?.setReady(ReadyType.SNOWPLOW_INIT);

			resolve();
		});
	}

	async trackPage({ path, title, contexts = [] }: { path?: string; title?: string; contexts: SnowplowContext[] }) {
		const utmSearchParams = getUtmSearchParams();

		if (lastPath) {
			const url = applySearchParams(new URL(`${DOMAIN}${lastPath}`), utmSearchParams);
			tracker('setReferrerUrl', url.href);
		}

		const url = applySearchParams(new URL(`${DOMAIN}${path}`), utmSearchParams);
		tracker('setCustomUrl', url.href);
		tracker('trackPageView', {
			title,
			context: contexts.map(c => c.toObject()),
		});

		lastPath = path ? path : '';
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {}
	refreshPermanentAudiencesSubgenres(permanent_audiences: string[]): void {}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {
		if (lastPath) {
			tracker('setReferrerUrl', `${DOMAIN}${lastPath}`);
		}

		const { action, label, property, value } = properties;

		tracker('trackStructEvent', {
			category,
			action,
			label,
			property,
			value,
			context: contexts.map(c => c.toObject()),
		});
	}
}

function applySearchParams(url: URL, params: URLSearchParams | null) {
	if (params) {
		params.forEach((value, key) => url.searchParams.set(key, value));
	}

	return url;
}
