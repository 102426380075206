import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { UserState } from '@/stores/user.store';
import LocalForage from 'localforage';

export const STORAGE_MIGRATION_REVISION = 7;

/**
 * check if user is set at least, because it will always be set with the new LocalForage instance.
 * if it isn't, transfer all non-namespaced (keys with a `/`) over to LocalForage instance.
 */

export async function needsLocalStorageMigration() {
	const user = (await LocalForage.getItem('user')) as Partial<UserState>;
	return user && user.storageMigratedRevision! < STORAGE_MIGRATION_REVISION;
}

export async function migrateLocalStorage() {
	const user = (await LocalForage.getItem('user')) as Partial<UserState>;

	// Sentry logging to see if this function is still in use
	captureMessageForSentry(`migrateLocalStorage: v${STORAGE_MIGRATION_REVISION}`, {
		where: '[migrate-local-storage.ts]',
	});

	//@ts-ignore
	user.onboardingStatus = user.onboarding_status;

	const userContent: Partial<UserState> = {
		...user,
	};

	// update migration revision
	await LocalForage.setItem('user', {
		...userContent,
		storageMigratedRevision: STORAGE_MIGRATION_REVISION,
	});
}
