import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetGenericListCountQueryVariables = Types.Exact<{
	type: Types.GenericTitleListsType;
}>;

export type GetGenericListCountQuery = {
	__typename?: 'Query';
	genericTitleLists: { __typename?: 'GenericTitleListsConnection'; totalCount: number };
};

export const GetGenericListCountDocument = gql`
	query GetGenericListCount($type: GenericTitleListsType!) {
		genericTitleLists(type: $type) {
			totalCount
		}
	}
`;
