import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import Axios from 'axios';

import { generateToken } from '@/helpers/user-helper';

let axios: any = null;

// #### DEBUG API CALLS
// Axios.interceptors.request.use(config => {
// 	console.log('[API] url: ', config.url);
// 	console.log('[API] config: ', config);
// 	return config;
// });
// #### DEBUG API CALLS

const createAxios = (options: any = {}) => {
	return Axios.create({
		baseURL: '/',
		timeout: 20000,
		...options,
	});
};

const getAxios = (forceInit: boolean = false): AxiosInstance => {
	if (!axios || forceInit) {
		if (process.server && global.DEBUG_AXIOS === 'true') {
			require('axios-debug-log/enable');
		}
		axios = null;
		axios = setAxiosInterceptors(createAxios());
	}
	return axios;
};

const setAxiosInterceptors = (axios: AxiosInstance) => {
	return addEnforceTextPlainInterceptor(axios);
};

/**
 * enforces `text/plain` for post @see:https://stackoverflow.com/questions/12320467/jquery-cors-content-type-options/12320736#12320736
 */
const addEnforceTextPlainInterceptor = (axios: AxiosInstance): AxiosInstance => {
	axios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
		if (config.method && config.method.toLowerCase() === 'post') {
			// @ts-ignore
			config.headers['Content-Type'] = 'text/plain';
		}
		return config;
	});

	return axios;
};

/**
 * usually we'd use interceptors on axios to add auth headers on specific routes.
 * due to a CPU leak in axios interceptors, we now have to add headers manually on the routes.
 * @todo switch to interceptors again when axios fixed the CPU leak in interceptors (planned for 0.22.0)
 */
const getAuthHeaders = async (accessToken?: string, baseHeaders: any = {}) => {
	if (accessToken) {
		if (process.server) {
			return {
				...baseHeaders,
				Authorization: `Bearer ${accessToken}`,
			};
		} else {
			const token = await generateToken();
			// Still fallback to the state access token for users that still use legacy token
			return {
				...baseHeaders,
				Authorization: `Bearer ${token || accessToken}`,
			};
		}
	}

	return { ...baseHeaders };
};

const getAxiosCached = async (): Promise<AxiosInstance> => {
	if (process.server) {
		const { getAxiosServerCached } = await import('@/helpers/axios-server-helper');
		return getAxiosServerCached();
	} else {
		return getAxios();
	}
};

export { Axios, addEnforceTextPlainInterceptor, createAxios, getAuthHeaders, getAxios, getAxiosCached };
