import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetTopTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
}>;

export type GetTopTitlesQuery = {
	__typename?: 'Query';
	movie: {
		__typename?: 'PopularTitlesConnection';
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'MovieContent'; title: string; fullPath: string };
				  }
				| {
						__typename?: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'ShowContent'; title: string; fullPath: string };
				  };
		}> | null;
	};
	upcomingMovie: {
		__typename?: 'NewTitlesConnection';
		edges?: Array<{
			__typename?: 'NewTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'MovieContent'; title: string; fullPath: string };
				  }
				| {
						__typename?: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						show: {
							__typename?: 'Show';
							id: string;
							content: { __typename?: 'ShowContent'; title: string };
						};
						content: { __typename?: 'SeasonContent'; title: string; fullPath: string };
				  };
		}> | null;
	};
	show: {
		__typename?: 'PopularTitlesConnection';
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'MovieContent'; title: string; fullPath: string };
				  }
				| {
						__typename?: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'ShowContent'; title: string; fullPath: string };
				  };
		}> | null;
	};
	upcomingShow: {
		__typename?: 'NewTitlesConnection';
		edges?: Array<{
			__typename?: 'NewTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: { __typename?: 'MovieContent'; title: string; fullPath: string };
				  }
				| {
						__typename?: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						show: {
							__typename?: 'Show';
							id: string;
							content: { __typename?: 'ShowContent'; title: string };
						};
						content: { __typename?: 'SeasonContent'; title: string; fullPath: string };
				  };
		}> | null;
	};
};

export type TopTitle_Movie_Fragment = {
	__typename?: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	content: { __typename?: 'MovieContent'; title: string; fullPath: string };
};

export type TopTitle_Season_Fragment = {
	__typename?: 'Season';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	show: { __typename?: 'Show'; id: string; content: { __typename?: 'ShowContent'; title: string } };
	content: { __typename?: 'SeasonContent'; title: string; fullPath: string };
};

export type TopTitle_Show_Fragment = {
	__typename?: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	content: { __typename?: 'ShowContent'; title: string; fullPath: string };
};

export type TopTitleFragment = TopTitle_Movie_Fragment | TopTitle_Season_Fragment | TopTitle_Show_Fragment;

export const TopTitleFragmentDoc = gql`
	fragment TopTitle on MovieOrShowOrSeason {
		id
		objectId
		objectType
		content(country: $country, language: $language) {
			title
			fullPath
		}
		... on Season {
			show {
				id
				content(country: $country, language: $language) {
					title
				}
			}
		}
	}
`;
export const GetTopTitlesDocument = gql`
	query GetTopTitles($country: Country!, $language: Language!) {
		movie: popularTitles(country: $country, first: 5, filter: { objectTypes: MOVIE }) {
			edges {
				node {
					...TopTitle
				}
			}
		}
		upcomingMovie: newTitles(country: $country, first: 5, pageType: UPCOMING, filter: { objectTypes: MOVIE }) {
			edges {
				node {
					...TopTitle
				}
			}
		}
		show: popularTitles(country: $country, first: 5, filter: { objectTypes: SHOW }) {
			edges {
				node {
					...TopTitle
				}
			}
		}
		upcomingShow: newTitles(country: $country, first: 5, pageType: UPCOMING, filter: { objectTypes: SHOW_SEASON }) {
			edges {
				node {
					...TopTitle
				}
			}
		}
	}
	${TopTitleFragmentDoc}
`;
