import { headerfulCachedRequest } from '@/helpers/request-helper';
import {
	GetTopArticlesDocument,
	GetTopArticlesQuery,
	TopArticleFragment,
} from '@/pages/graphql/queries/GetTopArticles.query';
import { GetTopTitlesDocument, GetTopTitlesQuery } from '@/pages/graphql/queries/GetTopTitles.query';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

const state = () => ({
	topTitles: {
		movie: [] as any[],
		upcomingMovie: [] as any[],
		show: [] as any[],
		upcomingShow: [] as any[],
	},
	topArticles: [] as TopArticleFragment[],
});

type State = ReturnType<typeof state>;

const getters: GetterTree<State, any> = {};

const actions: ActionTree<State, any> = {
	async fetchTopTitles({ commit, rootGetters, rootState }) {
		const variables = {
			country: rootGetters['language/country'],
			language: rootGetters['language/language'],
		};
		const jwId = rootState.user.jwId;
		const response = await headerfulCachedRequest(GetTopTitlesDocument, variables, jwId);

		commit('SET_TOP_TITLES', response?.data);
	},
	async fetchTopArticles({ commit, rootGetters, rootState }) {
		const variables = {
			country: rootGetters['language/country'],
			language: rootGetters['language/language'],
			first: 5,
		};
		const jwId = rootState.user.jwId;
		const response = await headerfulCachedRequest(GetTopArticlesDocument, variables, jwId);

		commit('SET_TOP_ARTICLES', response?.data);
	},
};

const mutations: MutationTree<State> = {
	SET_TOP_TITLES: (state, payload: GetTopTitlesQuery) => {
		const movie = payload.movie?.edges?.map(({ node }) => node) ?? [];
		const upcomingMovie = payload.upcomingMovie?.edges?.map(({ node }) => node) ?? [];
		const show = payload.show?.edges?.map(({ node }) => node) ?? [];
		const upcomingShow = payload.upcomingShow?.edges?.map(({ node }) => node) ?? [];
		state.topTitles = {
			movie,
			upcomingMovie,
			show,
			upcomingShow,
		};
	},
	SET_TOP_ARTICLES: (state, payload: GetTopArticlesQuery) => {
		state.topArticles = (payload.articles.edges ?? []).map(({ node }) => node);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
