export interface UCConfigInterface {
	configId: string;
	isTCFEnabled: boolean;
}

export const TCF_PROFILE: UCConfigInterface = {
	configId: 'pNbv5DOWe',
	isTCFEnabled: true,
};

export const CCPA_PROFILE: UCConfigInterface = {
	configId: 'p9i1nfAVcBcGYb',
	isTCFEnabled: false,
};

export const UC_TCF_LOCALSTORAGE_KEY: string = 'uc_tcf';
