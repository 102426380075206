import { computed, readonly } from 'vue';

type Device = 'mobile' | 'desktop' | 'unknown';
type OS = 'android' | 'ios' | 'macos' | 'windows' | 'linux' | 'unknown';

export function useUserAgent() {
	const deviceType = computed<Device>(() => {
		if (process.server) return 'unknown';

		let hasTouchScreen = false;
		if ('ontouchstart' in window) {
			hasTouchScreen =
				navigator.maxTouchPoints > 0 ||
				// @ts-ignore
				navigator?.msMaxTouchPoints > 0;
		} else {
			const mQ = matchMedia?.('(pointer:coarse)');
			if (mQ?.media === '(pointer:coarse)') {
				hasTouchScreen = !!mQ.matches;
			} else if ('orientation' in window) {
				hasTouchScreen = true; // deprecated, but good fallback
			} else {
				// Only as a last resort, fall back to user agent sniffing
				const UA = navigator.userAgent;
				hasTouchScreen =
					/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
					/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
			}
		}

		return hasTouchScreen ? 'mobile' : 'desktop';
	});

	const deviceOs = computed<OS>(() => {
		if (process.server) return 'unknown';

		const userAgent = navigator.userAgent.toLowerCase();

		switch (true) {
			case userAgent.includes('android'):
				return 'android';
			case userAgent.includes('iphone'):
			case userAgent.includes('ipad'):
			case userAgent.includes('ipod'):
				return 'ios';
			case userAgent.includes('macintosh'):
			case userAgent.includes('mac os'):
				return 'macos';
			case userAgent.includes('win'):
				return 'windows';
			case userAgent.includes('linux'):
				return 'linux';
			default:
				return 'unknown';
		}
	});

	const deviceInfo = computed<'mios' | 'mo' | 'dos' | 'do'>(() => {
		if (deviceOs.value === 'ios') return 'mios';
		if (deviceOs.value === 'macos') return 'dos';

		// Mobile + not iOS -> mo | Desktop + not MacOS -> do
		return `${deviceType.value === 'mobile' ? 'm' : 'd'}o`;
	});

	const userBrowser = computed(() => {
		if (process.server) return 'unknown';
		let userAgent = navigator.userAgent;

		if (userAgent.includes('Edg')) {
			return 'edge';
		} else if (userAgent.includes('Chrome')) {
			return 'chrome';
		} else if (userAgent.includes('Firefox')) {
			return 'firefox';
		} else if (userAgent.includes('Safari')) {
			return 'safari';
		} else if (userAgent.includes('Opera')) {
			return 'opera';
		} else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
			return 'internet-explorer';
		}

		return 'unknown';
	});

	return {
		deviceType: readonly(deviceType),
		deviceOs: readonly(deviceOs),
		deviceInfo,
		userBrowser: readonly(userBrowser),
	};
}
