import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetFinalClickoutUrlMutationVariables = Types.Exact<{
	input: Types.Scalars['String']['input'];
}>;

export type GetFinalClickoutUrlMutation = {
	__typename?: 'Mutation';
	getFinalClickoutUrl: { __typename?: 'GetFinalClickoutUrlPayload'; url: string };
};

export const GetFinalClickoutUrlDocument = gql`
	mutation GetFinalClickoutUrl($input: String!) {
		getFinalClickoutUrl(input: $input) {
			url
		}
	}
`;
