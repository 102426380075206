import Vue, { type CreateElement } from 'vue';

import VueMeta from 'vue-meta';

import '@/features';
import 'lazysizes';

import type { Context } from '@/@types/ssr';

import App from '@/SSRApp.vue';

import { getApolloProvider } from '@/graphql/apollo';
import { createI18n } from '@/i18n';
import { createRouter } from '@/routing';
import createStore from '@/store';

import {
	initApp,
	initClientStorage,
	initFirebase,
	initLanguage,
	initLocale,
	initRouter,
	initSSR,
	initStore,
	jwIdServerClientHandover,
	refreshToken,
	initJwId,
	initUncriticalRequests,
} from '@/main.common';

import { createThumborFilter } from '@/filters/thumbor';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { setVm } from '@/helpers/vm-helper';

import { GlobalComponentsPlugin } from '@/plugins/global-components';
import { GlobalDirectivesPlugin } from '@/plugins/global-directives';
import Jw, { Options as JwOptions } from '@/plugins/jw';
import Ready, { Options as ReadyOptions } from '@/plugins/ready';
import type { ApolloProvider } from 'vue-apollo';
import { storeUserGeoLocation } from './helpers/geo-location-helper';
import { storeSrDebuggerInfo } from './helpers/sponsoredrec-debugging-tools';

Vue.config.ignoredElements = [/^ion-/];
Vue.config.productionTip = process.env.NODE_ENV !== 'production';
Vue.config.performance = process.env.NODE_ENV === 'production';

Vue.config.errorHandler = (error, _, info) => {
	import('@/helpers/sentry-helper').then(({ captureMessageForSentry }) => {
		captureMessageForSentry(
			'[Uncaught Error]:',
			{ where: '[main.ssr.ts]: Vue Error Handler', error, meta: { info } },
			'error'
		);
	});
};

Vue.use(VueMeta, {});

global.IS_TOUCH = process.client ? !window.matchMedia('(hover: hover)').matches : false;

Vue.use(GlobalComponentsPlugin);
Vue.use(GlobalDirectivesPlugin);

// constantly check `window.hydrationBailed` for hydration issues
async function checkHydrationBails() {
	if ('hydrationBailed' in window && Array.isArray(window.hydrationBailed) && window.hydrationBailed.length > 0) {
		const bails = [...window.hydrationBailed] as { cause: string; where: string; detail: { parent: any } }[];
		window.hydrationBailed = [];
		// fire hydration bails to sentry
		const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
		for (const bail of bails) {
			const { cause, where, detail } = bail;
			const title = where
				.split(' ')
				// only take last 3 portions of css selectors (-5 because " > " are also present)
				.slice(-5)
				// remove :nth-child() from selector to better group issues
				.map(item => item.split(':')[0])
				.join(' ');
			const error = new Error(cause);
			// Vnodes have additional information attached to the Vue component
			const parentComponentName = ((detail as any).VNodes?.[0]?.context?.$vnode.tag || 'none')
				.split('-')
				.slice(-1)
				.join('');
			error.name = `[HydrationBail] ${parentComponentName} ${title}`;
			console.error('[HydrationBail]', { cause, where, detail, parent: parentComponentName });
			TrackingHelper.trackEvent('performance_metrics', {
				action: 'measure',
				property: 'hydration',
				label: `${parentComponentName}###${where}`,
				nonInteraction: true,
			});
			captureMessageForSentry(`[HydrationBail]`, { error, where, parent: parentComponentName }, 'error');
		}
	}
}
if (process.client) {
	setInterval(checkHydrationBails, 1000);
}

// START: CLS Check
if (process.env.NODE_ENV !== 'production') {
	import('@/plugins/cls-check').then(module => Vue.use(module.ClsCheckPlugin));
}
// END: CLS Check

export default async (context: Context) => {
	const didGenerateId = initJwId(context);

	// pass context down to App instance and all Components
	Vue.prototype.$context = context;

	await initFirebase();

	/***** SERVER ENV *****/
	if (process.server) {
		const customHeaders = context.ssr.data?.SERVER_ENV?.customHeaders;

		if (customHeaders) {
			const { addCustomHeadersToAxios } = await import('@/helpers/axios-server-helper');
			addCustomHeadersToAxios(customHeaders);
		}
	}
	/***** SERVER ENV *****/

	let localStoragePromise;
	let ionicPromise;
	if (process.client) {
		// Start loading Ionic
		ionicPromise = import('@/ionic.loader');
		localStoragePromise = initClientStorage();
	}

	if (BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
		// Make basic SSR init before init Store
		initSSR(context, didGenerateId);
	}

	await localStoragePromise;
	const store = await createStore(context);
	context.store = store;

	let refreshTokenPromise;
	if (process.client) {
		refreshTokenPromise = refreshToken(context);
	}

	/***** JW PLUGIN *****/
	Vue.use<JwOptions>(Jw);
	/***** READY STORE *****/
	Vue.use<ReadyOptions>(Ready, { store });

	const i18n = createI18n();
	context.i18n = i18n;

	if (process.client) {
		await storeUserGeoLocation();
		storeSrDebuggerInfo();
	}

	context.webLocale = await initLocale(context);

	const router = createRouter(context.webLocale, store);
	context.router = router;

	// This line MUST come after `await initLocale(context)`
	// It's here because `initLanguage` needs the router to check if we're on a sports route
	context.language = await initLanguage(context);

	Vue.filter('Thumbor', createThumborFilter());

	TrackingHelper.resetState();
	TrackingHelper.setStore(store);

	if (process.client) {
		// jwId handover needs to happen before we await for refreshTokenPromise,
		// because if there is an error with the refresh token, it might await ReadyType.JW_ID endlessly
		await jwIdServerClientHandover(store);
	}

	// wait for the refreshToken promise to finish Apollo init (it needs a fresh access token)
	await refreshTokenPromise;

	let apolloProvider: ApolloProvider<any>;
	if (BUILD_CONFIG.globals.BUILD_TARGET === 'SPA') {
		// If SPA we need to wait for the store to be created to make basic SPA init
		const { initSPA } = await import(/* webpackChunkName: "main-common" */ '@/main.common');
		apolloProvider = await getApolloProvider({ store });

		await initSPA(context, apolloProvider);
	}

	/**
	 * Backend Tracking
	 * Forward HTTP request user-agent to apollo requests
	 * Used for Tracking Mutations
	 **/
	let extraHeaders = {};
	if (process.server) {
		const UserAgent = context?.req?.headers['user-agent'];
		if (UserAgent && UserAgent.indexOf('GoogleStackdriverMonitoring-UptimeChecks') === -1) {
			extraHeaders = {
				'x-forwarded-user-agent': UserAgent,
			};
		}
	}
	/***** Vue Apollo init *****/
	// @ts-ignore
	if (!apolloProvider) {
		apolloProvider = await getApolloProvider({
			headers: {
				...context.ssr?.data?.SERVER_ENV?.customHeaders,
				...extraHeaders,
			},
			store,
		});
	}
	/***** Vue Apollo init *****/

	/***** SERVER ENV *****/
	if (process.server) {
		// clear SERVER_ENV to avoid leaks
		delete context.ssr.data.SERVER_ENV;
	}
	/***** SERVER ENV *****/
	await initStore(context);

	// all requests that are used on pretty much all pages and are un-personalised and cacheable
	const uncriticalPromise = initUncriticalRequests(context);

	await initRouter(context);

	await initApp(context);

	if (process.client) {
		await ionicPromise;
		const Ionic = (await ionicPromise)?.default;
		Ionic && Vue.use(Ionic);
	}

	context.app = new Vue({
		router,
		store,
		i18n,
		apolloProvider,
		render: (h: CreateElement) => h(App),
	});

	setVm(context.app);

	// making sure that we got all uncritical requests done before we ship HTML back
	await Promise.all(uncriticalPromise);

	return context;
};
