export default {
	paths: {
		main: 'src/main.ssr', // Without file extension
	},
	plugins: ['../../uvue-plugins/vuex.js', '../../uvue-plugins/apollo.js'],
	css: {
		normal: 'inline', // Or `extract`
		vue: 'inline', // Or `extract`
	},
};
