import cookie from 'cookie';
import Cookies from 'js-cookie';
import LocalForage from 'localforage';
import 'localforage-getitems';

import { STORE_NAMESPACE } from '@/store';
import { UserState } from '@/stores/user.store';

type MutationType = { type: string; payload: any };

export const PersistStateLocalStoragePlugin = (storesToPersist: string[] = []) => {
	function isStoreToPersist(mutationType: string) {
		return storesToPersist.some(item => mutationType.includes(`${item}/`));
	}

	return function (store: any) {
		store.subscribe((mutation: MutationType, state: any) => {
			if (isStoreToPersist(mutation.type)) {
				const storeName = mutation.type.split('/')[0];

				// const trimmedState = getTrimmedState(storeName, state);
				LocalForage.setItem(storeName, state[storeName]);
			}
		});
	};
};

// we don't need to keep all user properties in sync with the cookies.
// that means they're not needed in the server for the initial render.
// note: cookie values may not exceed 4096 length.
const persistUserProperies = [
	'accessToken',
	'hideSeenDislikedTitles',
	'isPremium',
	'jwId',
	'jwLoginId',
	'lastUsedLoginProvider',
	'location',
	'loggedInProviders',
	'onboardingStatus',
	'persistedOverriddenLanguage',
	'persistedWebLocale',
	'preferences',
	'settings', // TO-DO check why loadSettings is not setting it right
	'visibleFlyouts',
	'newBuyboxFilterType',
	'newBuyboxView',
	'newBuyboxTooltipAcknowledged',
];

const filterUserProperties = (object: Partial<UserState>) =>
	Object.keys(object)
		.filter(key => persistUserProperies.includes(key))
		.reduce((root, key) => {
			root[key] = object[key as keyof UserState];
			return root;
		}, {} as any);

export const PersistStateCookiesPlugin = (storesToPersist: string[] = []) => {
	function isStoreToPersist(mutationType: string) {
		return storesToPersist.some(item => mutationType.includes(`${item}/`));
	}

	return function (store: any) {
		store.subscribe((mutation: any, state: any) => {
			if (isStoreToPersist(mutation.type)) {
				const storeName = mutation.type.split('/')[0];
				const durationDay = 60 * 60 * 24;
				const duration = durationDay * 30 * 12 * 2; // two years

				// holds only the necessary properties that are needed for the initial server render
				const storeValue = storeName === 'user' ? filterUserProperties(state[storeName]) : state[storeName];

				// keep it temporarely
				const cookieValue = cookie.serialize(`${STORE_NAMESPACE}/${storeName}`, JSON.stringify(storeValue), {
					expires: new Date(Date.now() + duration * 1000),
					path: '/',
				});
				document.cookie = cookieValue;

				Cookies.set(`${STORE_NAMESPACE}_${storeName}`, JSON.stringify(storeValue), {
					expires: new Date(Date.now() + duration * 1000),
					secure: true,
					sameSite: 'strict',
				});
			}
		});
	};
};
