
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Route } from 'vue-router';

import { ExperimentData } from '@/helpers/experiment-helper';

import { ReadyType } from '@/stores/ready.store';
import { UserState } from '@/stores/user.store';

const User = namespace('user');
const Experiment = namespace('experiment');
const Routing = namespace('routing');

@Component({ name: 'BaseExperiment' })
export default class BaseExperiment extends Vue {
	name: ExperimentData['name'];
	variants: ExperimentData['variants'];
	goals: ExperimentData['goals'] = [];

	private hasActivated = false;

	@Prop({ default: () => [] }) readyOn: string[];

	@Experiment.Action registerExperiment: (experimentData: ExperimentData) => void;
	@Experiment.Action triggerExperiment: (experimentData: ExperimentData) => void;
	@Experiment.Getter activeVariantsWithControlGroup: Record<string, string>;

	@Routing.State activeRoute: Route | null;

	@User.State preferredExperiments: UserState['preferredExperiments'];

	get experimentData(): ExperimentData {
		if (!this.name || !this.variants || !this.goals) {
			throw `the core properties haven't been provided correctly. please check again (name, variants, goals)`;
		}
		return {
			name: this.name,
			variants: this.variants,
			goals: this.goals,
		};
	}

	get activeVariant() {
		return this.activeVariantsWithControlGroup?.[this.name] ?? null;
	}

	/*
	  this function it will be overwritten by the experiment 
	  in order to check if the experiment is ready to be activated
	  it's different from shouldTrigger as this one don't even set 
	  the experiment as ready which means until it's true it won't be triggered.
	*/
	isExperimentReadyToBeActivated() {
		return true;
	}

	// Register experiment components if the experiment state changes coming from url parameters
	@Watch('preferredExperiments')
	onPreferredExperimentsChange() {
		this.hasActivated = false;
		this.goals.forEach(goal => (goal.experiments = []));
		this.registerExperiment(this.experimentData);
		this.onRouteChange();
	}

	@Watch('activeRoute', { immediate: true })
	async onRouteChange() {
		// needs to wait for the next tick otherwise ready is sent before 'registerExperiment'
		await this.$nextTick();
		if (this.readyOn.length === 0) {
			this._ready();
		} else if (this.activeRoute) {
			if (this.readyOn.indexOf(this.activeRoute.name || '') !== -1 && this.isExperimentReadyToBeActivated()) {
				this._ready();
			}
		}
	}

	async mounted() {
		this.registerExperiment(this.experimentData);
	}

	trigger() {
		this.triggerExperiment(this.experimentData);
	}

	private async _ready() {
		await Vue.$jw.ready?.waitFor(ReadyType.EXPERIMENTS_LOADED);

		// only set ready when the experiment hasn't been activated before (only one try per session per experiment)
		if (!this.hasActivated && !this.activeVariant) {
			this.onReady();
			this.hasActivated = true;
		}
	}

	// this will be called when:
	// 1) the user is on the right page to be activated
	// 2) the user hasn't activated the test before
	//
	// note: this function needs to be overwritten by the Experiment where the trigger() function will be put in
	onReady() {
		throw 'Error: implement onReady() function in Experiment.';
	}
}
