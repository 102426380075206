/**
 * a globalRoute must start with /
 */
export const globalRoutes: Array<string> = [
	'/',
	// the following routes are black listed from getting cached (Scylla & CDN)
	'/tv',
	'/refresh',
	'/privacy-policy',
	'/terms',
	'/pseudonym-ID',
	'/shared',
];
