import { createI18n as createI18nInstance } from '@/helpers/composables/useI18n';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { dateTimeFormats } from '@/helpers/language-helpers';

export const DEFAULT_LANGUAGE_FALLBACK = 'en';
export const DEFAULT_LOCALE_FALLBACK = 'en-US';

Vue.use(VueI18n);

export const createI18n = () =>
	createI18nInstance({
		locale: DEFAULT_LOCALE_FALLBACK, // @todo set locale
		fallbackLocale: DEFAULT_LOCALE_FALLBACK,
		messages: {},
		dateTimeFormats,
	});
