import { Viewport } from '@/enums/viewport';
import { getViewport, hasRetinaSupport } from '@/helpers/ui-helper';

export interface ThumborImage<T> {
	backdrop: T;
	article_backdrop: T;
	poster: T;
	poster_detail: T;
	poster_global: T;
	provider_icon: T;
	provider_icon_small: T;
	provider_icon_button: T;
	portrait: T;
	competitor_icon: T;
	competitor_small_icon: T;
}

export interface Profile {
	smartphone_low: string;
	smartphone: string;
	tablet_low: string;
	tablet: string;
	desktop_low: string;
	desktop: string;
}

export const IMAGE_PROFILE: ThumborImage<Profile> = {
	backdrop: {
		smartphone_low: 's480',
		smartphone: 's640',
		tablet_low: 's640',
		tablet: 's1440',
		desktop_low: 's1440',
		desktop: 's1920',
	},
	article_backdrop: {
		smartphone_low: 's480',
		smartphone: 's480',
		tablet_low: 's480',
		tablet: 's480',
		desktop_low: 's480',
		desktop: 's480',
	},
	poster: {
		smartphone_low: 's166',
		smartphone: 's332',
		tablet_low: 's166',
		tablet: 's332',
		desktop_low: 's166',
		desktop: 's332',
	},
	poster_detail: {
		smartphone_low: 's276',
		smartphone: 's592',
		tablet_low: 's592',
		tablet: 's718',
		desktop_low: 's592',
		desktop: 's718',
	},
	// poster sizes for Global.vue component
	poster_global: {
		smartphone_low: 's166',
		smartphone: 's166',
		tablet_low: 's166',
		tablet: 's332',
		desktop_low: 's166',
		desktop: 's332',
	},
	provider_icon: {
		smartphone_low: 's100',
		smartphone: 's100',
		tablet_low: 's100',
		tablet: 's100',
		desktop_low: 's100',
		desktop: 's100',
	},
	provider_icon_small: {
		smartphone_low: 's25',
		smartphone: 's50',
		tablet_low: 's25',
		tablet: 's50',
		desktop_low: 's25',
		desktop: 's50',
	},
	provider_icon_button: {
		smartphone_low: 's40',
		smartphone: 's40',
		tablet_low: 's40',
		tablet: 's40',
		desktop_low: 's20',
		desktop: 's40',
	},
	portrait: {
		smartphone_low: 's150',
		smartphone: 's300',
		tablet_low: 's150',
		tablet: 's300',
		desktop_low: 's150',
		desktop: 's300',
	},
	competitor_icon: {
		smartphone_low: 's166',
		smartphone: 's166',
		tablet_low: 's166',
		tablet: 's332',
		desktop_low: 's166',
		desktop: 's332',
	},
	competitor_small_icon: {
		smartphone_low: 's40',
		smartphone: 's100',
		tablet_low: 's40',
		tablet: 's100',
		desktop_low: 's40',
		desktop: 's100',
	},
};

export const showLowQualityImages = ({ hasRetinaSupport = false }): boolean => {
	return !hasRetinaSupport;
};

export type ThumborFunction = typeof getThumborUrl;
// This function is not bound to store.
export const getThumborUrl = ({
	url = '',
	imageType,
	viewport = Viewport.DESKTOP,
	lowQuality = false,
	extension = '',
	resolution = '',
	criticalImage = false,
}: {
	url: string;
	imageType: keyof ThumborImage<any>;
	viewport?: Viewport;
	lowQuality?: boolean;
	extension?: string;
	resolution?: string;
	// @deprecated.
	// using Google's CDN that's on justwatch.com/images instead of images.justwatch.com
	// which will save DNS Lookup & handshakes, especially useful for sped up loading performance
	criticalImage?: boolean;
}): string => {
	if (!url?.includes('{profile}')) return url;

	const profileKey = (viewport + (lowQuality && !resolution ? '_low' : '')) as keyof Profile;
	const path = url.replace('{profile}', IMAGE_PROFILE[imageType][profileKey]);

	return `${JW_CONFIG.IMAGESCALER_URL}${path}${path.includes(extension) ? '' : extension}${resolution}`;
};

export type ThumborOptions = {
	url: string;
	imageType: keyof ThumborImage<any>;
	currentViewport?: Viewport;
	extension?: string;
	resolution?: string;
	slug?: string;
	criticalImage?: boolean;
};

// This function is bound to store.
export const createThumborFilter = () => {
	return ({ url = '', imageType, currentViewport, extension, resolution, slug, criticalImage }: ThumborOptions) => {
		return getThumborUrl({
			url,
			imageType,
			viewport: currentViewport || getViewport(),
			lowQuality: showLowQualityImages({
				hasRetinaSupport: hasRetinaSupport(),
			}),
			extension: extension && RegExp(/({\w*})$/g).test(url) ? `/${slug}${extension}` : extension,
			resolution: resolution ? ` ${resolution}` : '',
			criticalImage,
		});
	};
};
