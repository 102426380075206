import { Offer as OfferGraphql, Package } from '@/@types/graphql-types';

export type ClickoutOfferDetails = Pick<
	OfferGraphql,
	'monetizationType' | 'presentationType' | 'retailPriceValue' | 'currency' | 'lastChangeRetailPriceValue'
> & { package: Pick<Package, 'packageId' | 'clearName'> };

export const isClickoutOfferDetails = (offer: any): offer is ClickoutOfferDetails =>
	offer != null &&
	offer.package?.clearName &&
	offer.package?.packageId &&
	offer.monetizationType &&
	offer.presentationType;

export enum ClickoutContentType {
	MainEvent = 'main-event',
	Highlight = 'highlight',
	Interview = 'interview',
	LeavingSoon = 'leaving-soon',
}
