import { Consent } from '@/stores/user.store';
import { hasAnyConsentsGiven } from './consent-helper';

let scriptLoaded = false;

function isCookiebotEnabled() {
	return process.client;
}

function loadScript(consents: Record<Consent, boolean | null>) {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	// script.async = true;
	// script.defer = true;
	script.async = false; // make this sync so the consent banner logic works
	script.src = 'https://consent.cookiebot.com/uc.js';
	script.setAttribute('data-cbid', '22e0d41b-d41b-45d9-bbfc-a8db679a8e0a');
	script.setAttribute('data-framework', 'IAB');
	// @ts-ignore
	document.head.appendChild(script);

	const consentCase = getConsentCase(consents);
	// logic to hide and display cookiebot dialog
	// consentCase === true when user has provided consent
	// and false otherwise
	//
	// jw		cb	action
	// 1		1		do nothing, cookiebot won't display for 13 months
	// 1		0		do nothing, cookiebot will display
	// 0		1		do nothing, this should never happen
	// 0		0		hide cookiebot, jw consent banner will display
	if (!consentCase.jw && !consentCase.cb) {
		window.addEventListener('CookiebotOnDialogDisplay', forceHideCookiebot, false);
	}

	scriptLoaded = true;
}

// save reference to anonymous function to effectively
// remove the function from an event listener
// https://stackoverflow.com/a/38026137
function forceHideCookiebot() {
	const cookiebot = document.getElementById('CybotCookiebotDialog');
	cookiebot!.style.display = 'none';
	window.removeEventListener('CookiebotOnDialogDisplay', forceHideCookiebot, false);
}

function cookiebotClickSubmit() {
	const agreeButton = document.getElementById('CybotCookiebotDialogBodyLevelButtonAccept');
	agreeButton && agreeButton!.click();
}

function cookiebotAgreeNecessary() {
	const marketingCheckbox = document.getElementById(
		'CybotCookiebotDialogBodyLevelButtonMarketing'
	) as HTMLInputElement;
	const statisticsCheckbox = document.getElementById(
		'CybotCookiebotDialogBodyLevelButtonStatistics'
	) as HTMLInputElement;
	const preferencesCheckbox = document.getElementById(
		'CybotCookiebotDialogBodyLevelButtonPreferences'
	) as HTMLInputElement;
	if (marketingCheckbox!.checked) {
		// use .click() instead of directly setting checked=true to ensure
		// the change event is fired
		// https://stackoverflow.com/a/33433357
		marketingCheckbox!.click();
	}
	if (statisticsCheckbox!.checked) {
		statisticsCheckbox!.click();
	}
	if (preferencesCheckbox!.checked) {
		preferencesCheckbox!.click();
	}
	cookiebotClickSubmit();
}

function getConsentCase(consents: Record<string, boolean | null>) {
	const cookiebotConsent = document.cookie.indexOf('CookieConsent=');

	const consentCase = {
		jw: false,
		cb: false,
	};

	// @todo hasAnyConsentsGiven is moved to userstore.hasAnyConsentsGiven
	if (hasAnyConsentsGiven(consents)) {
		// TODO Why are consents added to cookies? They're separate thing/
		consentCase.jw = true;
	}

	if (cookiebotConsent !== -1) {
		consentCase.cb = true;
	}

	return consentCase;
}

const initialize = (consents: Record<string, boolean | null>) => {
	if (isCookiebotEnabled() && !scriptLoaded) {
		loadScript(consents);
	}
};

const saveConsents = (consents: Record<Consent, boolean | null>) => {
	if (!isCookiebotEnabled()) return;

	// Click submit directly if user consents to all providers
	// Otherwise, only check necessary preferences and then click submit
	const consentedToAll = Object.values(consents).every(response => response === true);
	if (consentedToAll) {
		cookiebotClickSubmit();
	} else {
		// TODO Not clear why we need to touch cookiebot at all.
		cookiebotAgreeNecessary();
	}
};

const toggleCookiebot = (expanded: boolean) => {
	if (isCookiebotEnabled()) {
		if (expanded) {
			(window as any).CookieConsent.show();
		} else {
			(window as any).CookieConsent.hide();
		}
	}
};

export { initialize, saveConsents, toggleCookiebot };
