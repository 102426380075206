import { ObjectType } from '@/@types/graphql-types';
import { captureMessageForSentry } from './sentry-helper';

const getTitleContextEntityId = (objectType: ObjectType, objectId: number, locationDebugger: string) => {
	let str = 't';

	switch (objectType.toUpperCase()) {
		case ObjectType.Movie:
			str += 'm';
			break;
		case ObjectType.Show:
		case ObjectType.ShowEpisode:
			str += 's';
			break;
		case ObjectType.ShowSeason:
			str += 'ss';
			break;
		case ObjectType.Person:
			str += 'p';
			break;
		default:
			captureMessageForSentry(
				'[Cannot transform the Title Context Entry ID]:',
				{
					error: new Error(
						`${objectType} is not a valid object type on ObjectId: ${objectId}. Context location: ${locationDebugger}`
					),
					where: '[helper/entity-helper.ts] getTitleContextEntityId()',
				},
				'error'
			);
	}

	return str + `${objectId}`;
};

export { getTitleContextEntityId };
