import Vue from 'vue';
import { ActionTree, MutationTree, Module } from 'vuex';
import { GettersConfig } from '@/interfaces/store';

type QueueType = 'pageViews' | 'events' | 'consents';

export type TrackingQueues = Record<QueueType, any[]>;

export type TrackingState = {
	pagesVisited: number;
	queues: TrackingQueues;
};
export type TrackingGetters = {
	getQueueOf: (queueType: QueueType) => any[];
};

export type TrackingGettersConfig = GettersConfig<TrackingState, TrackingGetters>;

export type TrackingStore = {
	state: TrackingState;
	getters: TrackingGettersConfig;
};

// STATE
export const getInitialTrackingState = (): TrackingState => ({
	pagesVisited: 0,
	queues: ({
		pageViews: [],
		events: [],
		consents: [],
	} as unknown) as TrackingQueues,
});

// GETTERS
const getters: TrackingGettersConfig = {
	getQueueOf: state => (queueType: QueueType) => {
		return state.queues[queueType];
	},
};

// ACTIONS
const actions: ActionTree<TrackingState, any> = {
	setQueues: async ({ commit }, queues) => {
		commit('SET_QUEUES', queues);
	},

	resetQueue: async ({ commit }, queueType: QueueType) => {
		commit('RESET_QUEUE', queueType);
	},

	addToQueue: async ({ commit }, { queueType, event }) => {
		commit('ADD_TO_QUEUES', { queueType, event });
	},

	addPageView: async ({ dispatch }, event) => {
		const queueType = 'pageViews';
		dispatch('addToQueue', { queueType, event });
	},

	addTrackEvent: async ({ dispatch }, event) => {
		const queueType = 'events';
		dispatch('addToQueue', { queueType, event });
	},
};

// MUTATIONS
const mutations: MutationTree<TrackingState> = {
	RESET_QUEUE: (state, queueType: QueueType) => {
		Vue.set(state.queues, `${queueType}`, []);
	},

	SET_QUEUES: (state, queues: TrackingQueues) => {
		state.queues = queues;
	},

	ADD_TO_QUEUES: (state, { queueType, event }: { queueType: QueueType; event: any }) => {
		state.queues[queueType].push(event);
	},

	INCREASE_PAGES_VISITED: state => {
		state.pagesVisited = state.pagesVisited + 1;
	},
};

export default {
	namespaced: true,
	state: getInitialTrackingState,
	getters,
	actions,
	mutations,
} as Module<TrackingState, any>;
