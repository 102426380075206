import VueRouter, { Route } from 'vue-router';

import { ProModal } from '@/lazy-load-components';

import { WebLocale } from '@/enums/web-locale';
import { SubscriptionInfo } from '@/interfaces/subscriptions';

import { updatePremiumStatus } from '@/helpers/iap-helper';
import { ModalHelper } from '@/helpers/modal-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getVm } from '@/helpers/vm-helper';

const EXPERIMENT_PRO_ONBOARDING_COUNTRIES = [
	WebLocale.be,
	WebLocale.es,
	WebLocale.fr,
	WebLocale.ie,
	WebLocale.nl,
	WebLocale.pt,
	WebLocale.us,
	WebLocale.fi,
	WebLocale.se,
];

function findSubscriptionBy(key: keyof SubscriptionInfo, value: string | null) {
	return Object.values(JW_CONFIG.SUBSCRIPTIONS || {}).find(subscription => subscription[key] == value);
}

const SELECTED_SUBSCRIPTION = 'selected_subscription' as const;

function saveSelectedSubscriptionPriceId(subscriptionPriceId: string) {
	localStorage.setItem(SELECTED_SUBSCRIPTION, subscriptionPriceId);
}

function removeSelectedSubscriptionPriceId() {
	localStorage.removeItem(SELECTED_SUBSCRIPTION);
}

function getSelectedSubscriptionPriceId() {
	return localStorage.getItem(SELECTED_SUBSCRIPTION);
}

function isProWebCountry(webLocale: WebLocale) {
	const activeCountries = [
		WebLocale.us,
		WebLocale.de,
		WebLocale.at,
		WebLocale.ie,
		WebLocale.fr,
		WebLocale.es,
		WebLocale.it,
		WebLocale.pl,
		WebLocale.nl,
		WebLocale.se,
		WebLocale.fi,
		WebLocale.be,
		WebLocale.pt,
		WebLocale.cz,
		WebLocale.ro,
	];
	return activeCountries.includes(webLocale);
}

function isProOnboardingExperimentEnabled(webLocale: WebLocale) {
	return EXPERIMENT_PRO_ONBOARDING_COUNTRIES.includes(webLocale) && isProWebCountry(webLocale);
}

function priceFormatter(locale: string, webLocale: WebLocale, price: number): string {
	const formattedLocale = locale.replace(/_/g, '-');
	return new Intl.NumberFormat(formattedLocale, { style: 'currency', currency: 'EUR' }).format(price);
}
function isPremiumUser(): boolean {
	return getVm()?.$store.getters['user/isPremium'];
}

const LAST_PRO_VIEW = 'last_pro_view';

function saveLastProView(view: string) {
	localStorage.setItem(LAST_PRO_VIEW, view);
}

function getLastProView() {
	return localStorage.getItem(LAST_PRO_VIEW);
}

function showProFeatures(webLocale: WebLocale): boolean {
	return isProWebCountry(webLocale) || isPremiumUser();
}

function handleStripeRedirect(route: Route, router: VueRouter, jwLoginId: string | undefined) {
	if (route.query['jw-pro'] === 'redirect') {
		router.push({ name: 'app.titles.popular.list' });

		ModalHelper.openModal(ProModal, {}, {}, 'StripeReturn', true);

		saveLastProView('StripeReturn');
	} else if (route.query['jw-pro'] === 'success') {
		const sessionId = route.query['session-id'];

		router.push({ name: 'app.titles.popular.list' });

		ModalHelper.openModal(ProModal, { modalType: 'loading' }, undefined, undefined, true);
		if (typeof sessionId === 'string' && jwLoginId) {
			handleStripe(sessionId, jwLoginId);
		} else {
			console.error('missing or multiple session-id query params after Stripe redirect');
		}
	}
}

async function handleStripe(sessionId: string, jwLoginId: string) {
	setTimeout(async () => {
		await updatePremiumStatus();
		const isPremium = getVm().$store.getters['user/isPremium'];
		if (isPremium) {
			await ModalHelper.close();
			ModalHelper.openModal(ProModal, { modalType: 'success' }, undefined, undefined, true);

			TrackingHelper.trackEvent('user_account', {
				action: 'subscription_signup',
				label: jwLoginId,
				property: getLastProView() || undefined,
			});

			setTimeout(() => {
				window.location.reload();
			}, 3000);
		} else {
			await ModalHelper.close();
			ModalHelper.openModal(ProModal, { modalType: 'error' }, undefined, undefined, true);

			TrackingHelper.trackEvent('user_account', {
				action: 'subscription_signup_error',
				label: jwLoginId,
				property: getLastProView() || undefined,
			});
		}
	}, 3000);
}

export {
	findSubscriptionBy,
	getLastProView,
	getSelectedSubscriptionPriceId,
	handleStripeRedirect,
	isProWebCountry,
	priceFormatter,
	removeSelectedSubscriptionPriceId,
	saveLastProView,
	saveSelectedSubscriptionPriceId,
	showProFeatures,
	isProOnboardingExperimentEnabled,
};
