import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetTopArticlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	first: Types.Scalars['Int']['input'];
}>;

export type GetTopArticlesQuery = {
	__typename?: 'Query';
	articles: {
		__typename?: 'ArticlesConnection';
		edges?: Array<{
			__typename?: 'ArticlesEdge';
			node: {
				__typename: 'Article';
				id: string;
				content: { __typename?: 'ArticleContent'; title?: string | null; fullPath: string };
			};
		}> | null;
	};
};

export type TopArticleFragment = {
	__typename: 'Article';
	id: string;
	content: { __typename?: 'ArticleContent'; title?: string | null; fullPath: string };
};

export const TopArticleFragmentDoc = gql`
	fragment TopArticle on Article {
		__typename
		id
		content {
			title
			fullPath
		}
	}
`;
export const GetTopArticlesDocument = gql`
	query GetTopArticles($country: Country!, $first: Int!) {
		articles(country: $country, first: $first, filter: { articleTypes: [NEWS_ARTICLE] }) {
			edges {
				node {
					... on Article {
						...TopArticle
					}
				}
			}
		}
	}
	${TopArticleFragmentDoc}
`;
