
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { type Genre } from '@/constants/types';

/** Use this symbol to Inject and Provide this constant. */
export const AgeCertificationsConstant = Symbol('AgeCertifications');
export const GenresConstant = Symbol('Genres');
export const GenresByShortNameConstant = Symbol('GenresByShortName');
/*********************/

const Constant = namespace('constant');

@Component({ name: 'ConstantsProvider' })
export default class ConstantsProvider extends Vue {
	@Constant.State('genres') _genres: Genre[];
	@Constant.State('ageCertifications') _ageCertifications: any;
	@Constant.Getter('genresByShortName') _genresByShortName: Record<string, Genre>;

	@ProvideReactive(GenresConstant)
	get genres() {
		return this._genres ?? [];
	}

	@ProvideReactive(GenresByShortNameConstant)
	get genresByShortName() {
		return this._genresByShortName;
	}

	@ProvideReactive(AgeCertificationsConstant)
	get ageCertifications(): {
		hasCertifications: boolean;
		movies: Array<{ __typename?: 'AgeCertification'; technicalName: string; id: number }>;
		organizations: string[];
		shows: Array<{ __typename?: 'AgeCertification'; technicalName: string; id: number }>;
	} {
		return this._ageCertifications;
	}
}
