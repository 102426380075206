import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetNewDeviceIdInputMutationVariables = Types.Exact<{
	input: Types.GetNewDeviceIdInput;
}>;

export type GetNewDeviceIdInputMutation = {
	__typename?: 'Mutation';
	getNewDeviceId: { __typename?: 'GetNewDeviceIdPayload'; deviceId: string };
};

export const GetNewDeviceIdInputDocument = gql`
	mutation GetNewDeviceIdInput($input: GetNewDeviceIdInput!) {
		getNewDeviceId(input: $input) {
			deviceId
		}
	}
`;
