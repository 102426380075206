import { MonetizationType, NewPageType, ObjectType, PresentationType, TitleFilter } from '@/@types/graphql-types';
import { CollectionType } from '@/enums/collection-type';
import { OfferMonetizationType, OfferPresentationType } from '@/interfaces/titles';
import { FilterCollection } from '@/stores/filter.store';
import { ScoringProvider } from './scoring-helper';

// UTILITY TYPES
type ExtractTypenames<T> = T extends { __typename?: infer name } ? name : never;

const getMinMaxFilter = (min: any, max: any, property: string) => {
	if (!min && !max) {
		return {};
	}

	return {
		[property]: {
			...(min ? { min } : {}),
			...(max ? { max } : {}),
		},
	};
};

const toGQLPresentationType = (pt: OfferPresentationType): PresentationType => {
	switch (pt) {
		case OfferPresentationType._4K:
			return PresentationType['4K'];
		default:
			return pt.toUpperCase() as PresentationType;
	}
};

/**
 * Extract from a generated graphql type the object types you want based on the "__typename".
 * **This is only a type utility, does not protect you if the value is undefined.**
 *
 * @example
 * ```ts
 * type Node = typeof url.node;
 * (data.node as AssertByTypeName<Node, 'Movie' | 'Show'>).offers;
 * ```
 */
export type AssertByTypename<
	T extends { __typename?: string } | undefined | null,
	Names extends ExtractTypenames<T>
> = T & { __typename: Names };

const toGQLMonetizationType = (mt: OfferMonetizationType): MonetizationType => mt.toUpperCase() as MonetizationType;

const toRESTMonetizationType = (mt: MonetizationType): OfferMonetizationType =>
	mt.toLowerCase() as OfferMonetizationType;

const toRESTPresentationType = (pt: PresentationType): OfferPresentationType => {
	switch (pt) {
		case PresentationType['4K']:
			return OfferPresentationType._4K;
		default:
			return pt.toLowerCase() as OfferPresentationType;
	}
};

//TODO [GraphQL] Workaround to make filters work while we have different filter between filter store (rest api) and graphql
const toGraphqlFilters = (
	filters: FilterCollection,
	providers: string[],
	excludeIrrelevantTitles = false
): TitleFilter => {
	const { sort_by, sort_asc, genres, ...restFilters } = filters;

	const restToGraphqlMapper: Partial<Record<keyof FilterCollection, keyof TitleFilter>> = {
		age_certifications: 'ageCertifications',
		exclude_genres: 'excludeGenres',
		exclude_production_countries: 'excludeProductionCountries',
		content_types: 'objectTypes',
		production_countries: 'productionCountries',
		subgenres: 'subgenres',
	};

	const restKeys = Object.keys(restToGraphqlMapper) as (keyof FilterCollection)[];
	const graphqlFilters: TitleFilter = restKeys.reduce((graphqlFilters, restKey) => {
		return {
			...graphqlFilters,
			[`${restToGraphqlMapper[restKey]}`]: (restFilters as Partial<FilterCollection>)[restKey],
		};
	}, {} as TitleFilter);

	return {
		...graphqlFilters,
		genres,
		packages: providers,
		excludeIrrelevantTitles,
		objectTypes: restFilters.content_types?.map(objectType => objectType.toUpperCase() as ObjectType),
		presentationTypes: restFilters.presentation_types.map(presentationType =>
			toGQLPresentationType(presentationType)
		),
		monetizationTypes: restFilters.monetization_types.map(monetizationType =>
			toGQLMonetizationType(monetizationType)
		),
		...getMinMaxFilter(restFilters.min_price, restFilters.max_price, 'price'),
		...getMinMaxFilter(
			restFilters.scoring_filter_types?.[ScoringProvider.IMDB]?.min_scoring_value,
			restFilters.scoring_filter_types?.[ScoringProvider.IMDB]?.max_scoring_value,
			'imdbScore'
		),
		...getMinMaxFilter(
			restFilters.scoring_filter_types?.[ScoringProvider.IMDB_VOTES]?.min_scoring_value,
			restFilters.scoring_filter_types?.[ScoringProvider.IMDB_VOTES]?.max_scoring_value,
			'imdbVotes'
		),
		...getMinMaxFilter(
			restFilters.scoring_filter_types?.[ScoringProvider.ROTTEN_TOMATOES]?.min_scoring_value,
			restFilters.scoring_filter_types?.[ScoringProvider.ROTTEN_TOMATOES]?.max_scoring_value,
			'tomatoMeter'
		),
		...getMinMaxFilter(restFilters.release_year_from, restFilters.release_year_until, 'releaseYear'),
		...getMinMaxFilter(
			restFilters.min_runtime && restFilters.min_runtime >= 5
				? restFilters.min_runtime - 5
				: restFilters.min_runtime,
			restFilters.max_runtime ? restFilters.max_runtime + 5 : restFilters.max_runtime,
			'runtime'
		),
	};
};

const getCollectionTypeByNewPageType = (newPageType?: NewPageType) => {
	if (!newPageType) {
		return null;
	}

	const map = {
		[NewPageType.New]: CollectionType.NEW,
		[NewPageType.Upcoming]: CollectionType.COMING_SOON,
		[NewPageType.LeavingSoon]: CollectionType.LEAVING_SOON,
		[NewPageType.PriceDrop]: CollectionType.PRICEDROPS,
	};

	return map[newPageType];
};

export {
	getCollectionTypeByNewPageType,
	toGQLMonetizationType,
	toGraphqlFilters,
	toRESTMonetizationType,
	toRESTPresentationType,
};
