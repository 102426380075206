import { CollectionType } from '@/enums/collection-type';
import { TitleOrder } from '@/enums/title-order';

const watchlistOnlySortingItems = [
	{
		key: TitleOrder.RELEVANCE, // only for watchlist
		name: 'Relevance',
		translationKey: 'WEBAPP_SORTING_RELEVANCE',
		asc: false,
		onlyIn: [CollectionType.MY_LISTS, CollectionType.TV_SHOW_TRACKING],
	},
	{
		key: TitleOrder.LAST_ADDED, // only for watchlist
		name: 'Last added',
		translationKey: 'WEBAPP_SORTING_LAST_ADDED',
		asc: false,
		onlyIn: [CollectionType.MY_LISTS, CollectionType.TV_SHOW_TRACKING],
	},
	{
		key: TitleOrder.RUNTIME,
		name: 'Content runtime',
		translationKey: 'WEBAPP_RUNTIME',
		asc: true,
		premium: true,
		onlyIn: [CollectionType.MY_LISTS, CollectionType.TV_SHOW_TRACKING],
	},
];

export const sortingItems: SortingItem[] = [
	...watchlistOnlySortingItems,
	{
		key: TitleOrder.POPULARITY,
		name: 'Popularity',
		translationKey: 'WEBAPP_SORTING_POPULARITY',
		asc: false,
	},
	{
		key: TitleOrder.TRENDING,
		name: 'Trending',
		translationKey: 'WEBAPP_SORTING_TRENDING',
		asc: false,
		onlyIn: [CollectionType.POPULAR],
	},
	{
		key: TitleOrder.RUNTIME,
		name: 'Content runtime',
		translationKey: 'WEBAPP_RUNTIME',
		asc: true,
		premium: true,
		onlyIn: [CollectionType.POPULAR],
	},
	{
		key: TitleOrder.RANDOM,
		name: 'Random',
		translationKey: 'WEBAPP_SORTING_RANDOM',
		asc: true,
	},
	{
		key: TitleOrder.ALPHABETICAL,
		name: 'Alphabetical',
		translationKey: 'WEBAPP_SORTING_ALPHABETICAL',
		asc: true,
	},
	{
		key: TitleOrder.RELEASE_YEAR,
		name: 'Release year',
		translationKey: 'WEBAPP_SORTING_RELEASE_YEAR',
		asc: false,
	},
	{
		key: TitleOrder.TMDB_POPULARITY,
		name: 'TMDB Popularity',
		translationKey: 'WEBAPP_SORTING_TMDB_POPULARITY',
		asc: false,
	},
	{
		key: TitleOrder.SCORE_IMDB,
		name: 'IMDB score',
		translationKey: 'WEBAPP_SORTING_IMDB',
		asc: false,
	},
	// {
	// 	key: TitleOrder.RUNTIME,
	// 	name: 'Content runtime',
	// 	translationKey: 'WEBAPP_SORTING_RUNTIME',
	// 	asc: true,
	// 	onlyIn: [CollectionType.POPULAR],
	// },
];

export interface SortingItem {
	key: TitleOrder;
	name: string;
	translationKey: string;
	asc: boolean;
	onlyIn?: CollectionType[];
	premium?: boolean;
}

export const defaultSortingItemSelectedKeys: Record<CollectionType, TitleOrder> = Object.values(CollectionType).reduce(
	(root, key) => {
		root[key] =
			key === CollectionType.MY_LISTS || key === CollectionType.TV_SHOW_TRACKING
				? TitleOrder.RELEVANCE
				: TitleOrder.POPULARITY;
		return root;
	},
	{} as Record<CollectionType, SortingItem['key']>
);

export const sortingItemsByCollectionType: Record<CollectionType, SortingItem[]> = Object.values(CollectionType).reduce(
	(root, key) => {
		root[key] = sortingItems.filter(sortingItem => !sortingItem.onlyIn || sortingItem.onlyIn.indexOf(key) !== -1);
		return root;
	},
	{} as Record<CollectionType, SortingItem[]>
);
