export enum TitleOrder {
	LAST_ADDED = 'list_natural',
	RELEVANCE = 'relevance',
	TRENDING = 'trending_7_day',
	RANDOM = 'random',
	ALPHABETICAL = 'title',
	RELEASE_YEAR = 'release_year',
	POPULARITY = 'popular_30_day',
	TMDB_POPULARITY = 'tmdb_popularity',
	SCORE_IMDB = 'imdb_score',
	RUNTIME = 'runtime',
}
