import { getAxiosCached } from '@/helpers/axios-helper';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

export interface StaticPage {
	attributes: { menu: string; menu_v2: string; sorting: string; platform?: string };
	full_path: string;
	id: number;
	technical_name: string;
	translation: string;
}

export type StaticRequestOptions = {
	attribute_key: string;
	attribute_value: number;
	language: string;
};

const defaultOptions: Partial<StaticRequestOptions> = {
	attribute_key: 'menu_v2',
	attribute_value: 1,
};

const fetchStaticPages = async (locale: string, options?: Partial<StaticRequestOptions>): Promise<StaticPage[]> => {
	try {
		const url = `${JW_CONFIG.API}/pages/locale/${locale}`;

		const params = { ...defaultOptions, ...options };

		const result = await (await getAxiosCached()).get<{ items: StaticPage[] }>(url, { params });
		return result.data.items.sort(
			(a: any, b: any) => parseInt(a.attributes.sorting, 10) - parseInt(b.attributes.sorting, 10)
		);
	} catch (err: any) {
		captureMessageForSentry(
			'[REST Fetch Static Pages]:',
			{ error: err, where: 'Component: static-page-helper' },
			'error'
		);
	}
	return [];
};

export { fetchStaticPages };
