
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import type { PageListItem } from '@/components/nav/static-pages/types';

@Component({
	name: 'PagesList',
})
export default class PagesList extends Vue {
	@Prop({ default: () => [] }) pages: PageListItem[];

	async go(path: string, external: boolean) {
		const { popoverController } = await import('@/ionic.loader');

		if (external) {
			window.open(path, '_blank', 'noopener');
		} else {
			this.$router.push({ path });
		}

		popoverController.dismiss();
	}
}
