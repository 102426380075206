export enum CollectionType {
	NEW = 'new',
	POPULAR = 'popular',
	SUBGENRE = 'subgenre',
	UPCOMING = 'upcoming',
	PRICEDROPS = 'pricedrops',
	LEAVING_SOON = 'leaving-soon',
	COMING_SOON = 'coming-soon',
	SEARCH = 'search',
	OFFERS = 'offers',
	MY_LISTS = 'my-lists',
	TV_SHOW_TRACKING = 'tv-show-tracking',
	PUBLIC_LISTS = 'public-lists',
	TITLELIST = 'titlelist',
	SEENLIST = 'seenlist',
	SPORTS = 'sports',
	EDITORIAL = 'editorial',
	// CINEMA_REGION = 'cinema_region',
	// CINEMA_SINGLE = 'cinema_single',

	// for mobile
	GLOBAL = 'global',
}
