import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { NetflixData } from '@/pages/landing-pages/statistics/types/NetflixData';

interface StatisticState {
	netflix_Data: NetflixData | null;
}

const state = (): StatisticState => ({
	netflix_Data: null,
});

const getters: GetterTree<StatisticState, any> = {
	netflixStatsData: state => {
		return state.netflix_Data;
	},
};

const mutations: MutationTree<StatisticState> = {
	setNetflixData(state, data) {
		state.netflix_Data = data;
	},
};

const actions: ActionTree<StatisticState, any> = {
	setNetflixStatsData({ commit }, data) {
		commit('setNetflixData', data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
} as Module<StatisticState, any>;
