import * as Types from '@/@types/graphql-types';
import { ObjectType } from '@/@types/graphql-types';

export interface SportEventV2Url<T extends Types.SingleStepSportEvent | Types.MultiStepSportEvent> extends Types.Url {
	node: T;
}

export interface SportCompetitionBucketsV2 {
	sportEventBucketsV2: Types.SportEventBucketsConnectionV2;
}

export type BreadcrumbItem = {
	name: string;
	objectType?: SportPageType | ObjectType;
	objectId?: number;
	routeName?: string;
	routeParams?: any;
	url?: string;
};

// UPCOMING EVENTS
interface SportEventAggregationSimplified {
	name?: string;
	number?: number;
}
// due to type conflicts we need to alias the competitionStage fields
export type SportEventDecoratedCompetitionStage = Types.SportEventV2 & {
	content: Types.SportEventV2['content'] & { competitionStage_FOOTBALL?: SportEventAggregationSimplified } & {
		competitionStage_AMERICAN_FOOTBALL?: SportEventAggregationSimplified;
	} & { competitionStage_FORMULA_RACING?: SportEventAggregationSimplified } & {
		competitionStage_TENNIS?: SportEventAggregationSimplified;
	} & {
		competitionStage_BASEBALL?: SportEventAggregationSimplified;
	};
};

export interface UpcomingEvent {
	id: string;
	lineItems: { text: string; iconUrl?: string; iconBlurHash?: string }[];
	fullPath: string;
	localStartAt: Date;
	startAt: Date;
	sport: Types.SportType;
	competition: {
		text: string;
		fullPath: string;
	};
	competitionStageText: string;
	offers: SportEventDecoratedCompetitionStage['offers'];
	watchNowOffer: SportEventDecoratedCompetitionStage['watchNowOffer'];
	sportEvent: Types.SingleStepSportEvent | Types.MultiStepSportEvent; // used for snowplow context and tracking
	mysportlistEntry: {
		createdAt?: string | null;
	};
}

/** The Sport Page Type */
export type SportPageType = (typeof SportPageType)[keyof typeof SportPageType];
export const SportPageType = {
	/** The JustWatch SEO Home Page */
	SportHome: 'SPORT_HOME',
	/** The All Sports Page */
	AllSports: 'ALL_SPORTS',
	/** A Sports Overview Page */
	SportOverview: ObjectType.Sport,
	/** A Sport Competition Page */
	SportCompetition: ObjectType.SportCompetition,
	/** A Sport Event Page */
	SportEvent: ObjectType.SportEvent,
	/** A Sport Team Page */
	SportTeam: ObjectType.SportCompetitor,
} as const;
