import { Store } from 'vuex';
import { PluginObject, VueConstructor } from 'vue';
import { ReadyType } from '@/stores/ready.store';

const SET_READY_MUTATION = 'ready/SET_READY';

declare module '@/plugins/jw' {
	interface JwPlugins {
		ready?: {
			waitFor: (readyType: ReadyType) => Promise<any>;
			setReady: (readyType: ReadyType) => void;
			store: ReadyDeferrer;
		};
	}
}

// Deferred definitions
type DeferredResolve = ((value?: boolean) => void) | (() => void);

interface Deferred {
	promise: Promise<boolean | void>;
	resolve: DeferredResolve;
}
interface readyOptions {
	type: ReadyType;
	timeout?: number;
}

// defer required stores
class ReadyDeferrer {
	private readyPromises: { [key in ReadyType]?: Deferred };

	constructor(options: readyOptions[], readyStore: ReadyState) {
		this.readyPromises = {};
		for (const option of options) {
			const { timeout, type } = option;

			if (readyStore[type as any]) {
				this.readyPromises[type] = {
					promise: Promise.resolve(),
					resolve: () => {},
				};
			} else {
				this.readyPromises[type] = this.createDeferred(timeout);
			}
		}
	}

	private createDeferred(timeout?: number): Deferred {
		let promise: Promise<boolean | void>;
		let resolve: DeferredResolve = () => {};

		if (timeout) {
			promise = Promise.race([
				new Promise<true>(r => setTimeout(r, timeout, true)),
				new Promise<void>(r => (resolve = r)),
			]);
		} else {
			promise = new Promise(r => (resolve = r));
		}

		return { promise, resolve };
	}

	resolve(type: ReadyType) {
		type && this.readyPromises[type]?.resolve();
	}

	wait(value: ReadyType | ReadyType[]) {
		if (Array.isArray(value)) {
			return Promise.all(value.map(type => this.readyPromises[type]?.promise));
		}
		return this.readyPromises[value]?.promise;
	}
}

export interface Options {
	store: Store<any>;
}

export default {
	install(Vue, options) {
		const { store } = options!;

		store.subscribe((mutation, state) => {
			if (mutation.type === SET_READY_MUTATION) {
				Vue.$jw.ready?.store.resolve(mutation.payload as ReadyType);
			}
		});

		const readyOptions: readyOptions[] = [];
		for (const item in ReadyType) {
			if (isNaN(Number(item))) {
				const type = item as ReadyType;
				switch (type) {
					/** Add state specific deferred options here.
					 * Example:
					 *
					 * case ReadyType.JW_ID:
					 *   readyOptions.push({ type, timeout: 1000 });
					 *   break;
					 */
					default:
						const value = (<any>ReadyType)[type];
						readyOptions.push({ type: value });
				}
			}
		}

		Vue.$jw.ready = {
			store: new ReadyDeferrer(readyOptions, store.state.ready),
			waitFor: (readyType: ReadyType) => waitFor(readyType, Vue, options!),
			setReady: (readyType: ReadyType) => setReady(readyType, Vue, options!),
		};
	},
} as PluginObject<Options>;

async function waitFor(readyType: ReadyType, Vue: VueConstructor, options: Options) {
	return Vue.$jw.ready?.store.wait(readyType);
}

function setReady(readyType: ReadyType, Vue: VueConstructor, options: Options) {
	options.store.commit('ready/SET_READY', readyType);
}
