import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';

const state = () => ({
	isOpen: false,
});

export type ModalState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<ModalState, any> = {
	isOpen(state): boolean {
		return state.isOpen;
	},
};
// ACTIONS
const actions: ActionTree<ModalState, any> = {
	setOpen({ commit }, isOpen: boolean) {
		commit('SET_OPEN', isOpen);
	},
};

// MUTATIONS
const mutations: MutationTree<ModalState> = {
	SET_OPEN(state, isOpen: boolean) {
		state.isOpen = isOpen;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<ModalState, any>;
