import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { TitleListLayout } from '@/enums/title-list-layout';

// STATE
const state = () => ({
	watchlistLayout: TitleListLayout.CARD,
});

type TitleState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<TitleState, any> = {
	watchlistLayout: state => state.watchlistLayout,
};

// ACTIONS
const actions: ActionTree<TitleState, any> = {
	setWatchlistLayout: ({ commit }, { listLayout }: { listLayout: TitleListLayout }) => {
		commit('SET_WATCHLIST_LAYOUT', listLayout);
	},
};

// MUTATIONS
const mutations: MutationTree<TitleState> = {
	SET_WATCHLIST_LAYOUT: (state, listLayout) => {
		state.watchlistLayout = listLayout;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<TitleState, any>;
