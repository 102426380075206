type queryParamemterOptions = 'geo_country' | 'subdivision' | 'sr' | 'holdout' | 'sr_testing' | 'sr_keep' | 'jw-pro';

/**
 * A non-vue way to get the query paramater
 * Useful for when we need the query parameter before Vue is initalised
 *
 * @param urlQueryKey: urlQueryKey
 * @returns The value of the Query Key
 */
export function getUrlQueryParameters(urlQueryKey: queryParamemterOptions) {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	return urlParams.get(urlQueryKey);
}
