import { getAuthHeaders, getAxios } from '@/helpers/axios-helper';
import { DEFAULT_USERSETTINGS, UserSettings } from '@/stores/user.store';

const getSettings = async (justwatchId = '', locale = '', accessToken = ''): Promise<UserSettings> => {
	// don't fetch personalized data when jwId is not available
	if (!justwatchId) {
		return Promise.resolve(DEFAULT_USERSETTINGS);
	}
	const headers = await getAuthHeaders(accessToken);
	const config = { params: { justwatch_id: justwatchId }, headers };
	const response = await getAxios().get(`${JW_CONFIG.WRITE_URL}/settings/${locale}`, config);
	const { onboarding_status, ...settings } = response.data;
	return { ...settings, onboardingStatus: onboarding_status };
};

const mutateSettings = async (
	justwatchId = '',
	accessToken = '',
	locale = '',
	currentSettings: UserSettings,
	newSettings: Partial<UserSettings>
) => {
	if (!justwatchId) {
		return Promise.resolve(currentSettings || DEFAULT_USERSETTINGS);
	}
	const headers = await getAuthHeaders(accessToken);
	const config = { params: { justwatch_id: justwatchId }, headers };

	const mutations = [];
	for (const [field, value] of Object.entries(newSettings)) {
		if (field === 'onboardingStatus') {
			mutations.push({
				field: 'onboarding_status',
				unset: (currentSettings as any)['onboarding_status'],
				set: value,
			});
		} else {
			mutations.push({
				field: field,
				unset: (currentSettings as any)[field],
				set: value,
			});
		}
	}

	const response = await getAxios().post(`${JW_CONFIG.WRITE_URL}/settings/${locale}/mutate`, { mutations }, config);
	const { onboarding_status, ...settings } = response.data;
	return { ...settings, onboardingStatus: onboarding_status };
};

export { getSettings, mutateSettings };
