import _Vue from 'vue';

// A place to keep global directives.
export const GlobalDirectivesPlugin = {
	async install(Vue: typeof _Vue) {
		let LongPress: any = {};

		if (process.client && IS_TOUCH) {
			LongPress = await (await import('@/directives/long-press/long-press')).default;
		}

		Vue.directive('image-fade-in', {
			bind() {},
		});
		Vue.directive('long-press', LongPress);
	},
};
