import { SportType } from '@/@types/graphql-types';
import { WebLocale } from './web-locale';

/*
	# How to add a sport

	1. Add the sport or category to `sportAliases`
		* This value gets exposed in `$route.meta.sport`
		* when the `SportType` updates you should see errors
	2. Add all the SportLocales with their translation of the sport (what appears in the URL)
	3. Add the sport or category to the correct property in `SportRouteTypes`


	# How to add a sport category

	1. Add the category to the `SportCategory` enum
	2. Follow steps in (How to add a sport)[#How-to-add-a-sport]


	# How to open Sports to a new country

	1. Add the locale to `SupportedSportLocales`
	2. Fix all the TS errors
		* Add the translation of "sport" to `sportTranslated` (based on the URL)
		* Add the missing locale and sport translation to each sport in `sportAliases`

	For more info see: https://gitlab.justwatch.com/jw/jw-app/-/wikis/Sports-FE-Architecture
*/

export type SportLocale = Extract<WebLocale, keyof typeof SupportedSportLocales>;

export enum SportCategory {
	Motorsport = 'MOTORSPORT',
}

const SupportedSportLocales = {
	[WebLocale.de]: WebLocale.de,
	[WebLocale.es]: WebLocale.es,
	[WebLocale.fr]: WebLocale.fr,
	[WebLocale.it]: WebLocale.it,
	[WebLocale.uk]: WebLocale.uk,
	[WebLocale.ca]: WebLocale.ca,
	[WebLocale.us]: WebLocale.us,
	[WebLocale.tr]: WebLocale.tr,
	[WebLocale.qa]: WebLocale.qa,
	[WebLocale.ae]: WebLocale.ae,
	[WebLocale.kw]: WebLocale.kw,
	[WebLocale.sa]: WebLocale.sa,
	[WebLocale.bh]: WebLocale.bh,
	[WebLocale.om]: WebLocale.om,
	[WebLocale.eg]: WebLocale.eg,
	[WebLocale.jo]: WebLocale.jo,
	[WebLocale.iq]: WebLocale.iq,
	[WebLocale.ar]: WebLocale.ar,
	[WebLocale.mx]: WebLocale.mx,
	[WebLocale.nl]: WebLocale.nl,
	[WebLocale.br]: WebLocale.br,
	[WebLocale.jp]: WebLocale.jp,
	[WebLocale.kr]: WebLocale.kr,
	[WebLocale.pt]: WebLocale.pt,
	[WebLocale.be]: WebLocale.be,
	[WebLocale.in]: WebLocale.in,
} as const;

// CS:GO launch was postponed, but BE already added CS:GO on their side. Please remove this ts-ignore when CS:GO will be added
//@ts-ignore
export const sportAliases: Record<SportType | SportCategory, Partial<Record<SportLocale, string>>> = {
	[SportType.Football]: {
		[SupportedSportLocales.de]: 'fussball',
		[SupportedSportLocales.es]: 'futbol',
		[SupportedSportLocales.fr]: 'foot',
		[SupportedSportLocales.it]: 'calcio',
		[SupportedSportLocales.uk]: 'football',
		[SupportedSportLocales.ca]: 'soccer',
		[SupportedSportLocales.us]: 'soccer',
		[SupportedSportLocales.tr]: 'futbol',
		[SupportedSportLocales.qa]: 'football',
		[SupportedSportLocales.ae]: 'football',
		[SupportedSportLocales.kw]: 'football',
		[SupportedSportLocales.sa]: 'football',
		[SupportedSportLocales.bh]: 'football',
		[SupportedSportLocales.om]: 'football',
		[SupportedSportLocales.eg]: 'football',
		[SupportedSportLocales.jo]: 'football',
		[SupportedSportLocales.iq]: 'football',
		[SupportedSportLocales.ar]: 'futbol',
		[SupportedSportLocales.mx]: 'futbol',
		[SupportedSportLocales.nl]: 'football',
		[SupportedSportLocales.br]: 'futebol',
		[SupportedSportLocales.jp]: 'football',
		[SupportedSportLocales.kr]: 'football',
		[SupportedSportLocales.pt]: 'futebol',
		[SupportedSportLocales.be]: 'foot',
		[SupportedSportLocales.in]: 'football',
	},
	[SportCategory.Motorsport]: {
		[SupportedSportLocales.de]: 'motorsport',
		[SupportedSportLocales.es]: 'motor',
		[SupportedSportLocales.fr]: 'sport-mecanique',
		[SupportedSportLocales.it]: 'motorsport',
		[SupportedSportLocales.uk]: 'motorsport',
		[SupportedSportLocales.ca]: 'motorsport',
		[SupportedSportLocales.us]: 'motorsport',
		[SupportedSportLocales.tr]: 'motorsporlari',
		[SupportedSportLocales.ae]: 'motorsport',
		[SupportedSportLocales.qa]: 'motorsport',
		[SupportedSportLocales.kw]: 'motorsport',
		[SupportedSportLocales.sa]: 'motorsport',
		[SupportedSportLocales.bh]: 'motorsport',
		[SupportedSportLocales.om]: 'motorsport',
		[SupportedSportLocales.eg]: 'motorsport',
		[SupportedSportLocales.jo]: 'motorsport',
		[SupportedSportLocales.iq]: 'motorsport',
		[SupportedSportLocales.ar]: 'motor',
		[SupportedSportLocales.mx]: 'motor',
		[SupportedSportLocales.nl]: 'motorsport',
		[SupportedSportLocales.br]: 'automobilismo',
		[SupportedSportLocales.jp]: 'motorsport',
		[SupportedSportLocales.kr]: 'motorsport',
		[SupportedSportLocales.pt]: 'automobilismo',
		[SupportedSportLocales.be]: 'sport-mecanique',
		[SupportedSportLocales.in]: 'motorsport',
	},
	[SportType.FormulaRacing]: {
		[SupportedSportLocales.de]: 'Formel-1',
		[SupportedSportLocales.es]: 'formula-1',
		[SupportedSportLocales.fr]: 'formule-1',
		[SupportedSportLocales.it]: 'formula-uno',
		[SupportedSportLocales.uk]: 'formula-1',
		[SupportedSportLocales.ca]: 'formula-1',
		[SupportedSportLocales.us]: 'formula-1',
		[SupportedSportLocales.tr]: 'formula-1',
		[SupportedSportLocales.qa]: 'formula-1',
		[SupportedSportLocales.ae]: 'formula-1',
		[SupportedSportLocales.kw]: 'formula-1',
		[SupportedSportLocales.sa]: 'formula-1',
		[SupportedSportLocales.bh]: 'formula-1',
		[SupportedSportLocales.om]: 'formula-1',
		[SupportedSportLocales.eg]: 'formula-1',
		[SupportedSportLocales.jo]: 'formula-1',
		[SupportedSportLocales.iq]: 'formula-1',
		[SupportedSportLocales.ar]: 'formula-1',
		[SupportedSportLocales.mx]: 'formula-1',
		[SupportedSportLocales.nl]: 'formula-1',
		[SupportedSportLocales.br]: 'formula-1',
		[SupportedSportLocales.jp]: 'formula-1',
		[SupportedSportLocales.kr]: 'formula-1',
		[SupportedSportLocales.pt]: 'formula-1',
		[SupportedSportLocales.be]: 'formule-1',
		[SupportedSportLocales.in]: 'formula-1',
	},
	[SportType.Tennis]: {
		[SupportedSportLocales.de]: 'tennis',
		[SupportedSportLocales.es]: 'tenis',
		[SupportedSportLocales.fr]: 'tennis',
		[SupportedSportLocales.it]: 'tennis',
		[SupportedSportLocales.uk]: 'tennis',
		[SupportedSportLocales.ca]: 'tennis',
		[SupportedSportLocales.us]: 'tennis',
		[SupportedSportLocales.tr]: 'tenis',
		[SupportedSportLocales.qa]: 'tennis',
		[SupportedSportLocales.ae]: 'tennis',
		[SupportedSportLocales.kw]: 'tennis',
		[SupportedSportLocales.sa]: 'tennis',
		[SupportedSportLocales.bh]: 'tennis',
		[SupportedSportLocales.om]: 'tennis',
		[SupportedSportLocales.eg]: 'tennis',
		[SupportedSportLocales.jo]: 'tennis',
		[SupportedSportLocales.iq]: 'tennis',
		[SupportedSportLocales.ar]: 'tenis',
		[SupportedSportLocales.mx]: 'tenis',
		[SupportedSportLocales.nl]: 'tennis',
		[SupportedSportLocales.br]: 'tenis',
		[SupportedSportLocales.jp]: 'tennis',
		[SupportedSportLocales.kr]: 'tennis',
		[SupportedSportLocales.pt]: 'tenis',
		[SupportedSportLocales.be]: 'tennis',
		[SupportedSportLocales.in]: 'tennis',
	},
	[SportType.AmericanFootball]: {
		[SupportedSportLocales.de]: 'american-football',
		[SupportedSportLocales.es]: 'futbol-americano',
		[SupportedSportLocales.fr]: 'football-americain',
		[SupportedSportLocales.it]: 'football-americano',
		[SupportedSportLocales.uk]: 'american-football',
		[SupportedSportLocales.ca]: 'football',
		[SupportedSportLocales.us]: 'football',
		[SupportedSportLocales.tr]: 'amerikan-futbolu',
		[SupportedSportLocales.qa]: 'american-football',
		[SupportedSportLocales.ae]: 'american-football',
		[SupportedSportLocales.kw]: 'american-football',
		[SupportedSportLocales.sa]: 'american-football',
		[SupportedSportLocales.bh]: 'american-football',
		[SupportedSportLocales.om]: 'american-football',
		[SupportedSportLocales.eg]: 'american-football',
		[SupportedSportLocales.jo]: 'american-football',
		[SupportedSportLocales.iq]: 'american-football',
		[SupportedSportLocales.ar]: 'futbol-americano',
		[SupportedSportLocales.mx]: 'futbol-americano',
		[SupportedSportLocales.nl]: 'american-football',
		[SupportedSportLocales.br]: 'futebol-americano',
		[SupportedSportLocales.jp]: 'american-football',
		[SupportedSportLocales.kr]: 'american-football',
		[SupportedSportLocales.pt]: 'futebol-americano',
		[SupportedSportLocales.be]: 'football-americain',
		[SupportedSportLocales.in]: 'american-football',
	},
	[SportType.Baseball]: {
		[SupportedSportLocales.de]: 'baseball',
		[SupportedSportLocales.es]: 'baseball',
		[SupportedSportLocales.fr]: 'baseball',
		[SupportedSportLocales.it]: 'baseball',
		[SupportedSportLocales.uk]: 'baseball',
		[SupportedSportLocales.ca]: 'baseball',
		[SupportedSportLocales.us]: 'baseball',
		[SupportedSportLocales.tr]: 'beyzbol',
		[SupportedSportLocales.qa]: 'baseball',
		[SupportedSportLocales.ae]: 'baseball',
		[SupportedSportLocales.kw]: 'baseball',
		[SupportedSportLocales.sa]: 'baseball',
		[SupportedSportLocales.bh]: 'baseball',
		[SupportedSportLocales.om]: 'baseball',
		[SupportedSportLocales.eg]: 'baseball',
		[SupportedSportLocales.jo]: 'baseball',
		[SupportedSportLocales.iq]: 'baseball',
		[SupportedSportLocales.ar]: 'baseball',
		[SupportedSportLocales.mx]: 'baseball',
		[SupportedSportLocales.nl]: 'baseball',
		[SupportedSportLocales.br]: 'baseball',
		[SupportedSportLocales.jp]: 'baseball',
		[SupportedSportLocales.kr]: 'baseball',
		[SupportedSportLocales.pt]: 'beisebol',
		[SupportedSportLocales.be]: 'baseball',
		[SupportedSportLocales.in]: 'baseball',
	},
	[SportType.Basketball]: {
		[SupportedSportLocales.de]: 'basketball',
		[SupportedSportLocales.es]: 'baloncesto',
		[SupportedSportLocales.fr]: 'basket',
		[SupportedSportLocales.it]: 'basket',
		[SupportedSportLocales.uk]: 'basketball',
		[SupportedSportLocales.ca]: 'basketball',
		[SupportedSportLocales.us]: 'basketball',
		[SupportedSportLocales.tr]: 'basketbol',
		[SupportedSportLocales.qa]: 'basketball',
		[SupportedSportLocales.ae]: 'basketball',
		[SupportedSportLocales.kw]: 'basketball',
		[SupportedSportLocales.sa]: 'basketball',
		[SupportedSportLocales.bh]: 'basketball',
		[SupportedSportLocales.om]: 'basketball',
		[SupportedSportLocales.eg]: 'basketball',
		[SupportedSportLocales.jo]: 'basketball',
		[SupportedSportLocales.iq]: 'basketball',
		[SupportedSportLocales.ar]: 'basquet',
		[SupportedSportLocales.mx]: 'baloncesto',
		[SupportedSportLocales.nl]: 'basketball',
		[SupportedSportLocales.br]: 'basquete',
		[SupportedSportLocales.jp]: 'basketball',
		[SupportedSportLocales.kr]: 'basketball',
		[SupportedSportLocales.pt]: 'basquetebol',
		[SupportedSportLocales.be]: 'basket',
		[SupportedSportLocales.in]: 'basketball',
	},
	[SportType.IceHockey]: {
		[SupportedSportLocales.de]: 'eishockey',
		[SupportedSportLocales.es]: 'hockey-sobre-hielo',
		[SupportedSportLocales.fr]: 'hockey-sur-glace',
		[SupportedSportLocales.it]: 'hockey-su-ghiaccio',
		[SupportedSportLocales.uk]: 'ice-hockey',
		[SupportedSportLocales.ca]: 'ice-hockey',
		[SupportedSportLocales.us]: 'ice-hockey',
		[SupportedSportLocales.tr]: 'buz-hokeyi',
		[SupportedSportLocales.qa]: 'ice-hockey',
		[SupportedSportLocales.ae]: 'ice-hockey',
		[SupportedSportLocales.kw]: 'ice-hockey',
		[SupportedSportLocales.sa]: 'ice-hockey',
		[SupportedSportLocales.bh]: 'ice-hockey',
		[SupportedSportLocales.om]: 'ice-hockey',
		[SupportedSportLocales.eg]: 'ice-hockey',
		[SupportedSportLocales.jo]: 'ice-hockey',
		[SupportedSportLocales.iq]: 'ice-hockey',
		[SupportedSportLocales.ar]: 'hockey-sobre-hielo',
		[SupportedSportLocales.mx]: 'hockey-sobre-hielo',
		[SupportedSportLocales.nl]: 'ice-hockey',
		[SupportedSportLocales.br]: 'hoquei-no-gelo',
		[SupportedSportLocales.jp]: 'ice-hockey',
		[SupportedSportLocales.kr]: 'ice-hockey',
		[SupportedSportLocales.pt]: 'hockey-no-gelo',
		[SupportedSportLocales.be]: 'hockey-sur-glace',
		[SupportedSportLocales.in]: 'ice-hockey',
	},
	[SportType.Rugby]: {
		[SupportedSportLocales.de]: 'rugby',
		[SupportedSportLocales.es]: 'rugby',
		[SupportedSportLocales.fr]: 'rugby',
		[SupportedSportLocales.it]: 'rugby',
		[SupportedSportLocales.uk]: 'rugby',
		[SupportedSportLocales.ca]: 'rugby',
		[SupportedSportLocales.us]: 'rugby',
		[SupportedSportLocales.tr]: 'ragbi',
		[SupportedSportLocales.qa]: 'rugby',
		[SupportedSportLocales.ae]: 'rugby',
		[SupportedSportLocales.kw]: 'rugby',
		[SupportedSportLocales.sa]: 'rugby',
		[SupportedSportLocales.bh]: 'rugby',
		[SupportedSportLocales.om]: 'rugby',
		[SupportedSportLocales.eg]: 'rugby',
		[SupportedSportLocales.jo]: 'rugby',
		[SupportedSportLocales.iq]: 'rugby',
		[SupportedSportLocales.ar]: 'rugby',
		[SupportedSportLocales.mx]: 'rugby',
		[SupportedSportLocales.nl]: 'rugby',
		[SupportedSportLocales.br]: 'rugby',
		[SupportedSportLocales.jp]: 'rugby',
		[SupportedSportLocales.kr]: 'rugby',
		[SupportedSportLocales.pt]: 'rugbi',
		[SupportedSportLocales.be]: 'rugby',
		[SupportedSportLocales.in]: 'rugby',
	},
	[SportType.Cricket]: {
		// [SupportedSportLocales.de]: 'cricket',
		// [SupportedSportLocales.es]: 'criquet',
		// [SupportedSportLocales.fr]: 'cricket',
		// [SupportedSportLocales.it]: 'cricket',
		[SupportedSportLocales.uk]: 'cricket',
		[SupportedSportLocales.ca]: 'cricket',
		[SupportedSportLocales.us]: 'cricket',
		// [SupportedSportLocales.tr]: 'kriket',
		[SupportedSportLocales.qa]: 'cricket',
		[SupportedSportLocales.ae]: 'cricket',
		[SupportedSportLocales.kw]: 'cricket',
		[SupportedSportLocales.sa]: 'cricket',
		[SupportedSportLocales.bh]: 'cricket',
		[SupportedSportLocales.om]: 'cricket',
		// [SupportedSportLocales.eg]: 'cricket',
		[SupportedSportLocales.jo]: 'cricket',
		[SupportedSportLocales.iq]: 'cricket',
		// [SupportedSportLocales.ar]: 'criquet',
		// [SupportedSportLocales.mx]: 'criquet',
		[SupportedSportLocales.nl]: 'cricket',
		// [SupportedSportLocales.br]: 'criquete',
		// [SupportedSportLocales.jp]: 'cricket',
		// [SupportedSportLocales.kr]: 'cricket',
		// [SupportedSportLocales.pt]: 'criquete',
		// [SupportedSportLocales.be]: 'cricket',
		[SupportedSportLocales.in]: 'cricket',
	},
} as const;

/** Sports organized into single-sports and category sports. */
export const SportRouteTypes: { sports: SportType[]; categories: Record<SportCategory, SportType[]> } = {
	sports: [
		SportType.Football,
		SportType.Tennis,
		SportType.AmericanFootball,
		SportType.Baseball,
		SportType.Basketball,
		SportType.IceHockey,
		SportType.Rugby,
		SportType.Cricket,
	],
	categories: {
		[SportCategory.Motorsport]: [SportType.FormulaRacing],
	},
};

/** Translation of "Sport" used in the URL slug, do not use as a UI translation. */
export const sportTranslated: Record<SportLocale, string> = {
	[SupportedSportLocales.de]: 'sport',
	[SupportedSportLocales.uk]: 'sports',
	[SupportedSportLocales.fr]: 'sports',
	[SupportedSportLocales.it]: 'sport',
	[SupportedSportLocales.es]: 'deportes',
	[SupportedSportLocales.ca]: 'sports',
	[SupportedSportLocales.us]: 'sports',
	[SupportedSportLocales.tr]: 'sporlar',
	[SupportedSportLocales.qa]: 'sports',
	[SupportedSportLocales.ae]: 'sports',
	[SupportedSportLocales.kw]: 'sports',
	[SupportedSportLocales.sa]: 'sports',
	[SupportedSportLocales.bh]: 'sports',
	[SupportedSportLocales.om]: 'sports',
	[SupportedSportLocales.eg]: 'sports',
	[SupportedSportLocales.jo]: 'sports',
	[SupportedSportLocales.iq]: 'sports',
	[SupportedSportLocales.ar]: 'deportes',
	[SupportedSportLocales.mx]: 'deportes',
	[SupportedSportLocales.nl]: 'sports',
	[SupportedSportLocales.br]: 'esporte',
	[SupportedSportLocales.jp]: 'sports',
	[SupportedSportLocales.kr]: 'sports',
	[SupportedSportLocales.pt]: 'desporto',
	[SupportedSportLocales.be]: 'sports',
	[SupportedSportLocales.in]: 'sports',
} as const;

export const SportsPageKey = {
	SPORTS_WEBAPP_MY_SPORTS: 'SPORTS_WEBAPP_MY_SPORTS',
	SPORTS_WEBAPP_ALL_SPORTS: 'SPORTS_WEBAPP_ALL_SPORTS',
} as const;

export type SportsPageKeyType = keyof typeof SportsPageKey;

export type ToggleMySportsNotificationPayload = {
	eventId: string;
	isNotificationActive: boolean;
};

/**
 * Get a mapping of each category sport and their corresponding category.
 * The reverse of `SportRouteTypes.category`.
 * */
export const getSportCategoryMap: () => Record<SportType, SportCategory> = () =>
	Object.fromEntries(
		Object.entries(SportRouteTypes.categories)
			.map(([category, sports]) => sports.map(sport => [sport, category]))
			.flat()
	);

export function isSportSupported(webLocale: WebLocale): webLocale is SportLocale {
	return webLocale in SupportedSportLocales;
}

export function isMultiStepSport(sportType: SportType | SportCategory) {
	return [SportCategory.Motorsport, SportType.FormulaRacing].includes(sportType);
}
