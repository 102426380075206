import { ActionTree, MutationTree, Module } from 'vuex';
import { ROLE_GROUP } from '@/enums/person';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

// STATE
const state = () => ({
	filter: ROLE_GROUP.ALL as ROLE_GROUP,
	listCounts: {} as { [key in ROLE_GROUP]: number },
});

type State = ReturnType<typeof state>;

// ACTIONS
const actions: ActionTree<State, any> = {
	reset: ({ commit }) => {
		commit('reset');
	},
};

// MUTATIONS
const mutations: MutationTree<State> = {
	reset: state => {
		state.filter = ROLE_GROUP.ALL;
	},
	setPersonGroupFilter: (state, filter: ROLE_GROUP) => {
		state.filter = filter;

		TrackingHelper.trackEvent('persons_detail_role', {
			action: filter,
		});
	},
	SET_LIST_COUNTS: (state, listCounts: { [key in ROLE_GROUP]: number }) => {
		state.listCounts = listCounts;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
} as Module<State, any>;
