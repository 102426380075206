import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetUserStatsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserStatsQuery = {
	__typename?: 'Query';
	stats: {
		__typename?: 'UserStats';
		hasJWProSubscription: boolean;
		maxCustomListsDefault: number;
		maxCustomListsPro: number;
		knowsAboutTitleLists: boolean;
		permanentAudiences: Array<string>;
	};
};

export const GetUserStatsDocument = gql`
	query GetUserStats {
		stats {
			hasJWProSubscription
			maxCustomListsDefault
			maxCustomListsPro
			knowsAboutTitleLists
			permanentAudiences
		}
	}
`;
