// based on https://github.com/ksheedlo/isomorphic-base64 to use in client and server envs interchangeably.
// due to lack of types, reimplemented and maintained here.

export const atob = (val: string) => {
	return Buffer.from(val, 'base64').toString();
};

export const btoa = (val: string) => {
	return Buffer.from(val).toString('base64');
};

/**
 * use this to make a Base64 encoded string URL friendly,
 * i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
 * characters are removed
 * source: https://jsfiddle.net/magikMaker/7bjaT
 * @param {String} str the encoded string
 * @returns {String} the URL friendly encoded String
 */
export const base64EncodeUrl = (str: string) => {
	return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
};
